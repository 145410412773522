import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExcelUpload } from './ExcelUpload';
import { ArrowLeft, Download, FileDown } from 'lucide-react';
import { InvoiceSteps } from '../InvoiceSteps';
import { BulkInvoiceDraft } from './BulkInvoiceDraft';
import { InvoiceSignature } from '../InvoiceSignature';
import { api } from '../../../../services/api';
import JSZip from 'jszip';
import { useEntity } from '../../../../context/EntityContext';

interface BulkInvoiceCreationProps {
    onBack: () => void;
}

interface UploadResponse {
    message: string;
    documents_created: number;
    documents: Array<{
        id: number;
        folio: string;
        date_issued: string;
        receiver_name: string;
        receiver_tax_id: string;
        amount_with_iva: string;
        status: string;
        pdf_file: string | null;
        pdf_base64: string | null;
        created_at: string;
        updated_at: string;
        references: Array<{
            reference_type: string;
            reference_folio: string;
            reference_date: string;
            reference_reason: string;
        }>;
        dte_type_code: number;
        dte_type_description: string;
    }>;
}

interface EmissionResult {
    id: number;
    message?: string;
    status?: string;
    folio?: string;
    pdf_data?: string;
    error?: string;
    success: boolean;
    receiver_name?: string;
    receiver_tax_id?: string;
}

export function BulkInvoiceCreation({ onBack }: BulkInvoiceCreationProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isEmissionComplete, setIsEmissionComplete] = useState(false);
    const [emissionResults, setEmissionResults] = useState<EmissionResult[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { state: { selectedEntity } } = useEntity();
    const [error, setError] = useState<string | null>(null);

    const handleFileComplete = async (data: UploadResponse) => {
        try {
            console.log("Datos recibidos de ExcelUpload:", data);

            // Guardar los documentos en localStorage para asegurar que estén disponibles
            let docsToStore: Array<{
                id: number;
                folio: string;
                date_issued: string;
                receiver_name: string;
                receiver_tax_id: string;
                amount_with_iva: string;
                status: string;
                pdf_file: string | null;
                pdf_base64: string | null;
                created_at?: string;
                updated_at?: string;
                references?: Array<{
                    reference_type: string;
                    reference_folio: string;
                    reference_date: string;
                    reference_reason: string;
                }>;
                dte_type_code?: number;
                dte_type_description?: string;
            }> = [];

            if (data.documents && Array.isArray(data.documents)) {
                docsToStore = data.documents;
            } else if (data.documents && typeof data.documents === 'object') {
                // En caso de que documents sea un objeto y no un array
                docsToStore = [data.documents];
            } else if (Array.isArray(data)) {
                // En caso de que data mismo sea el array de documentos
                docsToStore = data;
            }

            if (docsToStore.length > 0) {
                try {
                    console.log("Documentos a guardar:", docsToStore);
                    // Guardar el array completo para mantener toda la información
                    localStorage.setItem('bulk_invoice_documents', JSON.stringify(docsToStore));
                    console.log("Documentos guardados en localStorage:", docsToStore.length);
                } catch (storageError) {
                    console.error("Error al guardar documentos en localStorage:", storageError);
                }
            } else {
                console.warn("No hay documentos para guardar en localStorage:", data);
            }

            // Normalizar la navegación para asegurar que siempre pasamos un formato consistente
            navigate('/platform/bulk-invoice-preview', {
                state: {
                    documents: docsToStore,
                    documentsCreated: data.documents_created || docsToStore.length,
                    message: data.message || "Documentos cargados correctamente"
                }
            });
        } catch (error) {
            console.error('Error en handleFileComplete:', error);
        }
    };

    const handleBack = () => {
        if (location.pathname === '/platform/bulk-invoice-signature') {
            navigate('/platform/bulk-invoice-preview');
        } else if (location.pathname === '/platform/bulk-invoice-preview') {
            navigate('/platform/bulk-invoice-excel');
        } else {
            onBack();
        }
    };

    const handleSignAndIssue = async (certificatePassword: string) => {
        setIsLoading(true);
        try {
            if (!selectedEntity?.id) {
                throw new Error('No hay entidad seleccionada');
            }

            // Intentar obtener documentos de diferentes fuentes
            console.log("Estado de location:", location);

            let documentIds: number[] = [];

            // Opción 1: Intentar obtener documentos del location.state
            const locationDocuments = location.state?.documents;
            console.log("Documentos en location.state:", locationDocuments);

            // Opción 2: Intentar obtener documentos del localStorage
            let localStorageDocuments;
            try {
                const storedDocs = localStorage.getItem('bulk_invoice_documents');
                if (storedDocs) {
                    localStorageDocuments = JSON.parse(storedDocs);
                    console.log("Documentos en localStorage:", localStorageDocuments);
                }
            } catch (e) {
                console.error("Error al obtener documentos de localStorage:", e);
            }

            // Determinar qué fuente de documentos usar
            // Si locationDocuments es un número, lo ignoramos y usamos localStorageDocuments
            const documentsSource = typeof locationDocuments === 'number'
                ? localStorageDocuments
                : (locationDocuments || localStorageDocuments || []);

            console.log("Fuente de documentos a usar (tipo):", typeof documentsSource);
            console.log("Fuente de documentos a usar (valor):", documentsSource);

            // Manejar diferentes estructuras de datos
            if (Array.isArray(documentsSource)) {
                documentIds = documentsSource.map((doc: unknown) =>
                    typeof doc === 'object' && doc !== null ? (doc as { id: number }).id : (typeof doc === 'number' ? doc : null)
                ).filter(Boolean) as number[];
            } else if (documentsSource && typeof documentsSource === 'object') {
                // Es un objeto, verificar si tiene una propiedad 'documents'
                if (documentsSource.documents && Array.isArray(documentsSource.documents)) {
                    documentIds = documentsSource.documents.map((doc: unknown) =>
                        typeof doc === 'object' && doc !== null ? (doc as { id: number }).id : (typeof doc === 'number' ? doc : null)
                    ).filter(Boolean) as number[];
                } else if (documentsSource.id) {
                    // Es un documento único
                    documentIds = [documentsSource.id];
                }
            } else if (typeof documentsSource === 'number') {
                // Si el valor es un número, intentar usar directamente los documentos de localStorage
                try {
                    const storedDocs = localStorage.getItem('bulk_invoice_documents');
                    if (storedDocs) {
                        const parsedDocs = JSON.parse(storedDocs);
                        if (Array.isArray(parsedDocs)) {
                            documentIds = parsedDocs.map((doc: unknown) =>
                                typeof doc === 'object' && doc !== null ? (doc as { id: number }).id : null
                            ).filter(Boolean) as number[];
                        }
                    }
                } catch (e) {
                    console.error("Error al procesar documentos de localStorage:", e);
                }
            }

            console.log("IDs de documentos a emitir:", documentIds);

            // Intento adicional para recuperar los IDs si no se encontraron hasta ahora
            if (documentIds.length === 0) {
                try {
                    const storedDocs = localStorage.getItem('bulk_invoice_documents');
                    if (storedDocs) {
                        console.log("Intentando recuperar directamente los documentos del localStorage");
                        const parsedDocs = JSON.parse(storedDocs);
                        console.log("Documentos recuperados directamente:", parsedDocs);

                        if (Array.isArray(parsedDocs)) {
                            documentIds = parsedDocs.map((doc: unknown) =>
                                typeof doc === 'object' && doc !== null ? (doc as { id: number }).id : null
                            ).filter(Boolean) as number[];
                            console.log("IDs recuperados directamente:", documentIds);
                        }
                    }
                } catch (e) {
                    console.error("Error en el último intento de recuperación:", e);
                }
            }

            if (documentIds.length === 0) {
                throw new Error('No se encontraron documentos para emitir. Por favor intenta volver a cargar los documentos.');
            }

            // Preparar el cuerpo de la solicitud
            const requestBody = {
                document_ids: documentIds,
                digital_certificate_password: certificatePassword
            };

            console.log('Enviando solicitud de emisión batch:', requestBody);

            try {
                const response = await api.post(`/master-entities/${selectedEntity.id}/documents/batch-issue/`, requestBody);

                // Verificar y manejar la respuesta
                const data = await response.json();
                console.log("Respuesta de emisión batch:", data);

                if (!data || (!data.results && !Array.isArray(data.results))) {
                    throw new Error('La respuesta del servidor no tiene el formato esperado');
                }

                // Guardar los resultados para la pantalla de resumen
                setEmissionResults(data.results || []);
                setIsEmissionComplete(true);

                // Limpiar localStorage después de emisión exitosa
                localStorage.removeItem('bulk_invoice_documents');

                // Determinar éxito basado en cuántos documentos fallaron
                const failedCount = data.failed || 0;
                const successCount = data.success || 0;

                return {
                    success: true,
                    message: `Emisión completada. Documentos exitosos: ${successCount}, fallidos: ${failedCount}`
                };
            } catch (apiError: any) {
                // Manejar errores de la API
                console.error('Error en solicitud API:', apiError);

                let errorMessage = 'Error al comunicarse con el servidor';
                if (apiError.message) {
                    errorMessage = apiError.message;
                } else if (apiError.response) {
                    try {
                        const errorData = await apiError.response.json();
                        errorMessage = errorData.error || 'Error en la respuesta del servidor';
                    } catch (_) {
                        errorMessage = `Error del servidor: ${apiError.response.status}`;
                    }
                }

                throw new Error(errorMessage);
            }
        } catch (error: any) {
            console.error('Error al emitir documentos:', error);
            // Mostrar un mensaje de error al usuario
            setError(error.message || 'Error desconocido al emitir documentos');
            return {
                success: false,
                error: error.message || 'Error desconocido al emitir documentos'
            };
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadAllPdfs = async () => {
        setIsDownloading(true);
        try {
            const zip = new JSZip();

            // Formatear fecha y hora actual
            const now = new Date();
            const dateStr = now.toISOString()
                .replace(/[:]/g, '')
                .replace(/-/g, '')
                .split('.')[0];

            // Agregar cada PDF al archivo ZIP
            for (const result of emissionResults) {
                if (result.success && result.pdf_data && result.folio) {
                    // Convertir base64 a blob
                    const pdfContent = atob(result.pdf_data.split(',')[1]);
                    const pdfArray = new Uint8Array(pdfContent.length);
                    for (let i = 0; i < pdfContent.length; i++) {
                        pdfArray[i] = pdfContent.charCodeAt(i);
                    }

                    // Obtener razón social del cliente del documento actual
                    const clientName = result.receiver_name?.replace(/\s+/g, '_') || 'sin_nombre';

                    // Agregar al ZIP con nombre apropiado
                    zip.file(`factura_${result.folio}_${clientName}.pdf`, pdfArray);
                }
            }

            // Generar y descargar ZIP con el nuevo formato de nombre
            const content = await zip.generateAsync({ type: 'blob' });
            const url = URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${dateStr}_facturas.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error al generar ZIP:', error);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleDownloadPdf = (pdfData: string | undefined, folio: string | undefined, receiverName: string) => {
        if (!pdfData || !folio) return;

        const link = document.createElement('a');
        link.href = pdfData;
        link.download = `factura_${folio}_${receiverName.replace(/\s+/g, '_')}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderEmissionResults = () => {
        if (!isEmissionComplete) return null;

        const successfulDocs = emissionResults.filter(result => result.success);
        const failedDocs = emissionResults.filter(result => !result.success);

        return (
            <div className="space-y-6">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="text-xl font-medium">Resultado de la Emisión Masiva</h3>
                        <p className="text-sm text-gray-600 mt-1">
                            {successfulDocs.length} documentos emitidos exitosamente
                            {failedDocs.length > 0 && `, ${failedDocs.length} con errores`}
                        </p>
                    </div>
                    {successfulDocs.length > 0 && (
                        <button
                            onClick={handleDownloadAllPdfs}
                            disabled={isDownloading}
                            className="flex items-center space-x-2 px-4 py-2 text-green-600 border border-green-600 rounded-lg hover:bg-green-50"
                        >
                            <FileDown className="h-5 w-5" />
                            <span>{isDownloading ? 'Descargando...' : 'Descargar Todas'}</span>
                        </button>
                    )}
                </div>

                {failedDocs.length > 0 && (
                    <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
                        <h4 className="font-medium text-red-700 mb-2">Documentos con errores:</h4>
                        <ul className="space-y-2">
                            {failedDocs.map(doc => (
                                <li key={doc.id} className="text-red-600">
                                    <span className="font-medium">Documento {doc.id}</span>
                                    {doc.receiver_name && ` - ${doc.receiver_name}`}
                                    {doc.receiver_tax_id && ` (${doc.receiver_tax_id})`}
                                    <p className="text-sm mt-1">{doc.error || 'Error desconocido'}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="space-y-4">
                    {successfulDocs.map((result) => (
                        <div
                            key={result.id}
                            className="p-4 rounded-lg border border-green-200 bg-green-50"
                        >
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="font-medium text-green-700">
                                        {result.message || 'Documento emitido exitosamente'}
                                    </p>
                                    <div className="text-sm text-gray-600 mt-1">
                                        <p>Folio: {result.folio}</p>
                                        <p>Cliente: {result.receiver_name} {result.receiver_tax_id && `(${result.receiver_tax_id})`}</p>
                                    </div>
                                </div>
                                {result.pdf_data && (
                                    <button
                                        onClick={() => handleDownloadPdf(
                                            result.pdf_data,
                                            result.folio,
                                            result.receiver_name || 'sin_nombre'
                                        )}
                                        className="flex items-center space-x-2 px-4 py-2 text-green-600 border border-green-600 rounded-lg hover:bg-green-50"
                                    >
                                        <Download className="h-4 w-4" />
                                        <span>Descargar PDF</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center pt-6">
                    <button
                        onClick={() => navigate('/platform/invoices-issued')}
                        className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                    >
                        Volver al Listado
                    </button>
                </div>
            </div>
        );
    };

    const getCurrentStep = () => {
        switch (location.pathname) {
            case '/platform/bulk-invoice-excel':
                return 1;
            case '/platform/bulk-invoice-preview':
                return 2;
            case '/platform/bulk-invoice-signature':
                return 3;
            default:
                return 1;
        }
    };

    const renderStepContent = () => {
        switch (location.pathname) {
            case '/platform/bulk-invoice-excel':
                return (
                    <ExcelUpload
                        onComplete={handleFileComplete}
                    />
                );
            case '/platform/bulk-invoice-preview':
                return <BulkInvoiceDraft />;
            case '/platform/bulk-invoice-signature':
                return isEmissionComplete ? (
                    renderEmissionResults()
                ) : (
                    <InvoiceSignature
                        data={{
                            customer: {
                                receiver_rut: '',
                                receiver_business_name: (() => {
                                    const docs = location.state?.documents;
                                    let count = 0;

                                    if (Array.isArray(docs)) {
                                        count = docs.length;
                                    } else {
                                        try {
                                            const storedDocs = localStorage.getItem('bulk_invoice_documents');
                                            if (storedDocs) {
                                                const parsedDocs = JSON.parse(storedDocs);
                                                if (Array.isArray(parsedDocs)) count = parsedDocs.length;
                                            }
                                        } catch (e) {
                                            console.error("Error al obtener documentos para mostrar cantidad:", e);
                                        }
                                    }

                                    return `Emisión masiva de ${count || '?'} documento(s)`;
                                })(),
                                receiver_address: '',
                                receiver_district: '',
                                receiver_city: '',
                            },
                            date_issued: new Date().toISOString().split('T')[0],
                            details: {
                                issueDate: new Date().toISOString().split('T')[0],
                                documentType: '33'
                            },
                            issuer: {
                                name: '',
                                address: '',
                                district: '',
                                city: '',
                                saleType: '',
                                email: '',
                                phone: '',
                                businessLine: '',
                                economicActivity: ''
                            },
                            items: [],
                            references: []
                        }}
                        onSubmit={handleSignAndIssue}
                        isLoading={isLoading}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg">
            <div className="p-6 border-b border-gray-200">
                <div className="relative flex items-center">
                    <div className="absolute left-0">
                        <button
                            onClick={handleBack}
                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 transition-colors"
                        >
                            <ArrowLeft className="h-5 w-5" />
                            <span>Volver</span>
                        </button>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <InvoiceSteps
                            currentStep={getCurrentStep()}
                            mode="bulk"
                        />
                    </div>
                </div>
            </div>

            <div className="p-6">
                {renderStepContent()}
            </div>
        </div>
    );
}