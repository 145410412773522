import React, { useState } from 'react';
import { Phone, Loader2 } from 'lucide-react';

interface PhoneStepProps {
    onComplete: (phone: string) => void;
    initialValue: string;
}

export function PhoneStep({ onComplete, initialValue }: PhoneStepProps) {
    const [phone, setPhone] = useState(initialValue);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validatePhone = (phone: string) => {
        const re = /^(\+?56)?[9][0-9]{8}$/;
        return re.test(phone);
    };

    const formatPhone = (value: string) => {
        // Eliminar todo excepto números
        const numbers = value.replace(/\D/g, '');

        // Si comienza con 56, asegurarnos de agregar el +
        if (numbers.startsWith('56')) {
            return '+' + numbers;
        }

        // Si comienza con 9, agregar el código de país
        if (numbers.startsWith('9')) {
            return '+56' + numbers;
        }

        return numbers;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!phone) {
            setError('El teléfono es requerido');
            return;
        }
        if (!validatePhone(phone)) {
            setError('Por favor ingresa un teléfono válido (ej: +56912345678)');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${import.meta.env.VITE_API_URL}/signup/phone/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: phone,
                    onboarding_session_id: localStorage.getItem('onboarding_session_id')
                })
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Error al registrar el teléfono');
            }

            onComplete(phone);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Error al registrar el teléfono. Por favor, intenta nuevamente.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = formatPhone(e.target.value);
        setPhone(formattedPhone);
        setError('');
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Teléfono
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Phone className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                        type="tel"
                        id="phone"
                        autoComplete="tel"
                        value={phone}
                        onChange={handleChange}
                        className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'
                            } rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500`}
                        placeholder="+56912345678"
                    />
                </div>
                {error && (
                    <p className="mt-2 text-sm text-red-600">
                        {error}
                    </p>
                )}
                <p className="mt-2 text-sm text-gray-500">
                    Ingresa tu número de celular con código de país (+56)
                </p>
            </div>

            <div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
                >
                    {isLoading ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                        <span>Continuar</span>
                    )}
                </button>
            </div>
        </form>
    );
} 