interface SIILogoProps {
  country?: 'chile' | 'spain';
}

export function SIILogo({ country = 'chile' }: SIILogoProps) {
  return (
    <div className="flex items-center justify-center py-4">
      {country === 'chile' ? (
        <img
          src="/sii_logo.png"
          alt="Servicio de Impuestos Internos"
          className="h-12"
        />
      ) : (
        <div className="flex items-center justify-center">
          <svg width="120" height="40" viewBox="0 0 240 80" className="text-green-600">
            <text x="120" y="45" textAnchor="middle" className="text-lg font-semibold">
              Agencia Tributaria
            </text>
          </svg>
        </div>
      )}
    </div>
  );
}