import React from 'react';
import { Menu, X, HandshakeIcon, ChevronDown } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCountry } from '../context/CountryContext';
import { useState, useRef, useEffect } from 'react';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSolutions, setShowSolutions] = useState(false);
  const solutionsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { country, setCountry } = useCountry();

  // Handle click outside to close solutions dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (solutionsRef.current && !solutionsRef.current.contains(event.target as Node)) {
        setShowSolutions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const solutions = [
    {
      title: country === 'chile' ? 'Retail' : 'Retail',
      description: country === 'chile'
        ? 'Facturación masiva para supermercados'
        : 'Facturación masiva para supermercados',
      path: '/solutions/retail'
    },
    {
      title: country === 'chile' ? 'Tech' : 'Tech',
      description: country === 'chile'
        ? 'API de facturación para plataformas'
        : 'API de facturación para plataformas',
      path: '/solutions/tech'
    },
    {
      title: country === 'chile' ? 'Servicios' : 'Servicios',
      description: country === 'chile'
        ? 'Facturación recurrente automatizada'
        : 'Facturación recurrente automatizada',
      path: '/solutions/services'
    }
  ];

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    e.preventDefault();
    if (location.pathname === '/') {
      // Remove the # from the path to get the element id
      const elementId = path.startsWith('#') ? path.slice(1) : path;
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If path starts with #, navigate to home page with that anchor
      if (path.startsWith('#')) {
        navigate('/', { state: { scrollTo: path.slice(1) } });
      } else {
        navigate(`/${path}`);
      }
    }
  };

  return (
    <header className="fixed w-full bg-white shadow-sm z-50 h-14">
      <nav className="container mx-auto px-4 h-full">
        <div className="flex items-center justify-between relative h-full">
          <Link
            to="/"
            className="flex items-center space-x-2 h-14"
            onClick={() => window.scrollTo(0, 0)}
          >
            <HandshakeIcon className="h-8 w-8 text-green-600" />
            <span className="text-2xl font-bold">Tu Pana</span>
          </Link>

          <div className="hidden md:flex items-center flex-1">
            <div className="flex items-center ml-4">
              <div className="relative group" ref={solutionsRef}>
                <button
                  onClick={() => setShowSolutions(!showSolutions)}
                  onMouseEnter={() => setShowSolutions(true)}
                  onMouseLeave={() => setShowSolutions(false)}
                  className="flex items-center space-x-1 text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  <span>{country === 'chile' ? 'Soluciones' : 'Soluciones'}</span>
                  <ChevronDown className="h-4 w-4" />
                </button>

                {showSolutions && (
                  <div
                    onMouseEnter={() => setShowSolutions(true)}
                    onMouseLeave={() => setShowSolutions(false)}
                    className="absolute left-0 top-full mt-1 w-72 bg-white rounded-lg shadow-lg border border-gray-100 overflow-hidden py-4 z-50"
                    style={{ marginTop: "-8px", paddingTop: "20px" }}
                  >
                    <div className="px-4 py-2 mb-1 text-xs font-medium text-gray-500 uppercase">
                      {country === 'chile' ? 'Industrias' : 'Sectores'}
                    </div>
                    {solutions.map((solution) => (
                      <Link
                        key={solution.path}
                        to={solution.path}
                        className="block px-6 py-3 hover:bg-gray-50 transition-colors"
                      >
                        <div className="font-medium text-gray-900">{solution.title}</div>
                        <div className="text-sm text-gray-500">{solution.description}</div>
                      </Link>
                    ))}
                    <div className="px-4 py-2 mt-1 mb-1 text-xs font-medium text-gray-500 uppercase">
                      {country === 'chile' ? 'Casos de Éxito' : 'Casos de Éxito'}
                    </div>
                    <div className="space-y-1">
                      <Link
                        to="/success/solpiscina"
                        className="block px-6 py-3 hover:bg-gray-50 transition-colors"
                      >
                        <div className="font-medium text-gray-900">Solpiscina</div>
                        <div className="text-sm text-gray-500">
                          {country === 'chile'
                            ? 'Mantenimiento de piscinas en Chile'
                            : 'Mantenimiento de piscinas en España'}
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              <div className="ml-1">
                <a
                  href="#pricing"
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname === '/') {
                      const element = document.getElementById('pricing');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    } else {
                      navigate('/', { state: { scrollTo: 'pricing' } });
                    }
                  }}
                  className="text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  {country === 'chile' ? 'Precios' : 'Precios'}
                </a>
              </div>
              <div className="ml-1">
                <a
                  href="#comparacion"
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname === '/') {
                      const element = document.getElementById('comparacion');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    } else {
                      navigate('/', { state: { scrollTo: 'comparacion' } });
                    }
                  }}
                  className="text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  {country === 'chile' ? '¿Por qué Tu Pana?' : '¿Por qué Tu Pana?'}
                </a>
              </div>

              <div className="ml-1">
                <a
                  href="#success-stories"
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname === '/') {
                      const element = document.getElementById('success-stories');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    } else {
                      navigate('/', { state: { scrollTo: 'success-stories' } });
                    }
                  }}
                  className="text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  {country === 'chile' ? 'Casos de Éxito' : 'Casos de Éxito'}
                </a>
              </div>
              <div className="ml-1">
                <Link
                  to="/blog"
                  className="text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  Blog
                </Link>
              </div>
              <div className="ml-1">
                <a
                  href="#contacto"
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname === '/') {
                      const element = document.getElementById('contacto');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    } else {
                      navigate('/', { state: { scrollTo: 'contacto' } });
                    }
                  }}
                  className="text-gray-600 hover:text-green-600 px-3 py-2"
                >
                  {country === 'chile' ? 'Contacto' : 'Contacto'}
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-4 ml-auto">
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value as 'chile' | 'spain')}
                className="bg-white rounded-lg border border-gray-200 px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2"
              >
                <option value="chile">🇨🇱 Chile</option>
                <option value="spain">🇪🇸 España</option>
              </select>
              <Link
                to="/login"
                className="text-green-600 hover:text-green-700"
              >
                {country === 'chile' ? 'Iniciar Sesión' : 'Iniciar Sesión'}
              </Link>
              <Link
                to="/signup"
                className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition"
              >
                {country === 'chile' ? 'Crear Cuenta' : 'Crear Cuenta'}
              </Link>
            </div>
          </div>

          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden"
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {isMenuOpen && (
          <div className="md:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-4">
              {solutions.map((solution) => (
                <Link key={solution.path} to={solution.path} className="text-gray-600 hover:text-green-600">
                  {solution.title}
                </Link>
              ))}
              <div className="border-t border-gray-200 my-2"></div>
              <a
                href="#"
                onClick={(e) => handleNavigation(e, 'pricing')}
                className="text-gray-600 hover:text-green-600"
              >
                {country === 'chile' ? 'Precios' : 'Precios'}
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/', { state: { scrollTo: 'comparacion' } });
                }}
                className="text-gray-600 hover:text-green-600"
              >
                {country === 'chile' ? '¿Por qué Tu Pana?' : '¿Por qué Tu Pana?'}
              </a>
              <a
                href="#"
                onClick={(e) => handleNavigation(e, 'success-stories')}
                className="text-gray-600 hover:text-green-600"
              >
                {country === 'chile' ? 'Casos de Éxito' : 'Casos de Éxito'}
              </a>
              <Link
                to="/blog"
                className="text-gray-600 hover:text-green-600"
              >
                Blog
              </Link>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/', { state: { scrollTo: 'contacto' } });
                }}
                className="text-gray-600 hover:text-green-600"
              >
                {country === 'chile' ? 'Contacto' : 'Contacto'}
              </a>
              <Link
                to="/login"
                className="text-green-600 hover:text-green-700"
              >
                {country === 'chile' ? 'Iniciar Sesión' : 'Iniciar Sesión'}
              </Link>
              <Link
                to="/signup"
                className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition text-center"
              >
                {country === 'chile' ? 'Crear Cuenta' : 'Crear Cuenta'}
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}