import { BlogPost, BlogListItem } from '../types/blog';
import frontMatter from 'front-matter';
import { marked } from 'marked';

export async function getAllPosts(): Promise<BlogListItem[]> {
    const posts = import.meta.glob('../content/blog/**/*.md', { as: 'raw', eager: true });
    const allPosts: BlogListItem[] = [];

    for (const path in posts) {
        const content = posts[path];
        const { attributes: frontmatter } = frontMatter(content);
        const slug = path.split('/').slice(-2)[0];

        allPosts.push({
            title: frontmatter.title,
            description: frontmatter.description,
            date: frontmatter.date,
            image: frontmatter.image,
            readingTime: frontmatter.readingTime,
            tags: frontmatter.tags,
            slug
        });
    }

    return allPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}

export async function getPostBySlug(slug: string): Promise<BlogPost | null> {
    try {
        const modules = import.meta.glob('../content/blog/**/*.md', { as: 'raw', eager: true });
        const key = Object.keys(modules).find(path => path.includes(`/${slug}/`));

        if (!key) {
            throw new Error('Post not found');
        }

        const content = modules[key];
        const { attributes: frontmatter, body } = frontMatter(content);
        const htmlContent = marked(body);

        return {
            title: frontmatter.title,
            description: frontmatter.description,
            date: frontmatter.date,
            author: frontmatter.author,
            image: frontmatter.image,
            readingTime: frontmatter.readingTime,
            tags: frontmatter.tags,
            slug,
            content: htmlContent
        };
    } catch (error) {
        console.error(`Error loading post ${slug}:`, error);
        return null;
    }
}