import React, { useState, useEffect, useRef } from 'react';
import { X, Save, Plus, Trash2, Search } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { SpinningRing } from '../../../../../components/SpinningRing';
import { api } from '../../../../../services/api';

interface Activity {
    id: number;
    code: string;
    name: string;
}

interface Address {
    address: string;
    district: {
        name: string;
        city: {
            name: string;
        }
    }
}

interface DocumentData {
    id: number;
    folio: string;
    document_receiver: {
        rut: string;
        business_name: string;
        business_activity: string;
        activity_code: string;
        contact: string;
        address: string;
        district: string;
        city: string;
    };
    details: Array<{
        line_number: number;
        item_name: string;
        item_description?: string;
        quantity: string | number;
        unit_price: string | number;
        discount_percent: string | number;
        item_total: string | number;
        item_code?: string;
        unit?: string;
        other_tax: unknown;
    }>;
    references?: Array<{
        reference_type: string;
        reference_folio: string;
        reference_date: string;
        reference_reason: string;
    }>;
    receiver?: {
        addresses?: Array<Address>;
        activities?: Array<Activity>;
    };
}

interface EditRequestData {
    folio: string;
    receiver_rut: string;
    document_receiver: {
        rut: string;
        business_name: string;
        business_activity: string;
        activity_code: string;
        contact: string;
        address: string;
        district: string;
        city: string;
    };
    details: Array<{
        line_number: number;
        item_name: string;
        item_description: string;
        quantity: string | number;
        unit_price: string | number;
        discount_percent: string | number;
        item_total: string | number;
        item_code: string;
        unit: string;
        other_tax: unknown;
    }>;
    references: Array<{
        reference_type: string;
        reference_folio: string;
        reference_date: string;
        reference_reason: string;
    }>;
}

interface EditInvoiceModalProps {
    isOpen: boolean;
    onClose: () => void;
    invoice: DocumentData;
    onSave: (id: number, data: EditRequestData) => Promise<void>;
}

export function EditInvoiceModal({ isOpen, onClose, invoice, onSave }: EditInvoiceModalProps) {
    const [formData, setFormData] = useState({
        document_receiver: invoice.document_receiver,
        details: invoice.details,
        references: invoice.references || []
    });
    const [isSaving, setIsSaving] = useState(false);
    const [addressSearch, setAddressSearch] = useState('');
    const [activitySearch, setActivitySearch] = useState('');
    const [showAddressDropdown, setShowAddressDropdown] = useState(false);
    const [showActivityDropdown, setShowActivityDropdown] = useState(false);
    const addressRef = useRef<HTMLDivElement>(null);
    const activityRef = useRef<HTMLDivElement>(null);
    const [savingStatus, setSavingStatus] = useState<'idle' | 'saving' | 'refreshing'>('idle');
    const [selectedTab, setSelectedTab] = useState('customer');
    const [addresses] = useState(invoice.receiver?.addresses || []);
    const [activities, setActivities] = useState<Activity[]>([]);
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
        invoice.receiver?.activities?.[0] || null
    );

    useEffect(() => {
        const fetchActivities = async () => {
            if (!activities.length) {
                setLoadingActivities(true);
                try {
                    // Usar la ruta correcta para obtener las actividades económicas
                    const response = await api.get('/activities/');
                    if (!response.ok) throw new Error('Error al cargar actividades');
                    const data = await response.json();
                    console.log('Actividades económicas cargadas:', data);
                    setActivities(data);

                    // Si el documento tiene un código de actividad pero no tenemos la actividad completa,
                    // intentamos buscarla en los datos recibidos
                    if (formData.document_receiver.activity_code && !selectedActivity) {
                        const matchingActivity = data.find((act: Activity) =>
                            act.code === formData.document_receiver.activity_code
                        );
                        if (matchingActivity) {
                            setSelectedActivity(matchingActivity);
                            // Actualizar también el formData para mantener la consistencia
                            setFormData(prev => ({
                                ...prev,
                                document_receiver: {
                                    ...prev.document_receiver,
                                    business_activity: matchingActivity.name,
                                    activity_code: matchingActivity.code
                                }
                            }));
                        }
                    }
                } catch (error) {
                    console.error('Error fetching activities:', error);
                } finally {
                    setLoadingActivities(false);
                }
            }
        };
        fetchActivities();
    }, [formData.document_receiver.activity_code, selectedActivity]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (addressRef.current && !addressRef.current.contains(event.target as Node)) {
                setShowAddressDropdown(false);
            }
            if (activityRef.current && !activityRef.current.contains(event.target as Node)) {
                setShowActivityDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredAddresses = addresses.filter((addr: Address) =>
        addr.address.toLowerCase().includes(addressSearch.toLowerCase()) ||
        addr.district.name.toLowerCase().includes(addressSearch.toLowerCase()) ||
        addr.district.city.name.toLowerCase().includes(addressSearch.toLowerCase())
    );

    const filteredActivities = activities.filter(act =>
        act.name.toLowerCase().includes(activitySearch.toLowerCase()) ||
        act.code.toLowerCase().includes(activitySearch.toLowerCase())
    );

    useEffect(() => {
        // Determinar la actividad inicial (si existe)
        const initialActivity = invoice.receiver?.activities?.[0] || null;

        setSelectedActivity(initialActivity);

        // Asegurar que el formData tenga la actividad correcta desde el inicio
        setFormData({
            document_receiver: {
                ...invoice.document_receiver,
                // Si tenemos una actividad seleccionada, usar esos valores
                business_activity: initialActivity?.name || invoice.document_receiver.business_activity,
                activity_code: initialActivity?.code || invoice.document_receiver.activity_code
            },
            details: invoice.details,
            references: invoice.references || []
        });

        setAddressSearch('');
        setActivitySearch('');
    }, [invoice]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        setSavingStatus('saving');

        try {
            // Verificar que selectedActivity está sincronizado con formData
            console.log('Estado actual de actividad:', {
                selectedActivity,
                formData_activity: formData.document_receiver.business_activity,
                formData_code: formData.document_receiver.activity_code
            });

            const requestData: EditRequestData = {
                folio: invoice.folio,
                receiver_rut: formData.document_receiver.rut,
                document_receiver: {
                    rut: formData.document_receiver.rut,
                    business_name: formData.document_receiver.business_name,
                    // Usar los valores de formData que ya fueron actualizados con selectedActivity
                    business_activity: formData.document_receiver.business_activity,
                    activity_code: formData.document_receiver.activity_code,
                    contact: formData.document_receiver.contact,
                    address: formData.document_receiver.address,
                    district: formData.document_receiver.district,
                    city: formData.document_receiver.city
                },
                details: formData.details.map(detail => ({
                    line_number: detail.line_number,
                    item_name: detail.item_name,
                    item_description: detail.item_description || '',
                    quantity: detail.quantity,
                    unit_price: detail.unit_price,
                    discount_percent: detail.discount_percent,
                    item_total: detail.item_total,
                    item_code: detail.item_code || '',
                    unit: detail.unit || 'UN',
                    other_tax: detail.other_tax
                })),
                references: formData.references
            };

            console.log('Guardando documento con datos finales:', requestData);
            await onSave(invoice.id, requestData);

            // Esperar un momento antes de cerrar para asegurar que se ha completado la actualización
            setSavingStatus('refreshing');
            await new Promise(resolve => setTimeout(resolve, 1000));

            onClose();
        } catch (error) {
            console.error('Error saving changes:', error);
        } finally {
            setIsSaving(false);
            setSavingStatus('idle');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
                <div className="p-6 border-b border-gray-200 flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Editar Factura</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-600 transition-colors"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                <div className="p-6 overflow-y-auto">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Tabs */}
                        <div className="flex space-x-4 border-b">
                            <button
                                type="button"
                                className={`px-4 py-2 font-medium transition-colors ${selectedTab === 'customer'
                                    ? 'text-green-600 border-b-2 border-green-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setSelectedTab('customer')}
                            >
                                Datos del Cliente
                            </button>
                            <button
                                type="button"
                                className={`px-4 py-2 font-medium transition-colors ${selectedTab === 'products'
                                    ? 'text-green-600 border-b-2 border-green-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => setSelectedTab('products')}
                            >
                                Productos
                            </button>
                        </div>

                        <AnimatePresence mode="wait">
                            {selectedTab === 'customer' ? (
                                <motion.div
                                    key="customer"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="space-y-6"
                                >
                                    {/* Customer Data */}
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Razón Social
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.document_receiver.business_name}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    document_receiver: {
                                                        ...prev.document_receiver,
                                                        business_name: e.target.value
                                                    }
                                                }))}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                RUT
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.document_receiver.rut}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    document_receiver: {
                                                        ...prev.document_receiver,
                                                        rut: e.target.value
                                                    }
                                                }))}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Dirección
                                            </label>
                                            <div className="relative" ref={addressRef}>
                                                <div className="flex items-center relative">
                                                    <input
                                                        type="text"
                                                        value={addressSearch}
                                                        onChange={(e) => {
                                                            setAddressSearch(e.target.value);
                                                        }}
                                                        onFocus={() => {
                                                            setShowAddressDropdown(true);
                                                            setAddressSearch('');
                                                        }}
                                                        className="w-full px-3 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                        placeholder={formData.document_receiver.address || "Buscar dirección..."}
                                                    />
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                        <Search className="h-5 w-5 text-gray-400" />
                                                    </div>
                                                </div>
                                                {showAddressDropdown && (
                                                    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                                                        {(addressSearch ? filteredAddresses : addresses).map((addr: Address, idx: number) => (
                                                            <button
                                                                key={idx}
                                                                type="button"
                                                                onClick={() => {
                                                                    setFormData(prev => ({
                                                                        ...prev,
                                                                        document_receiver: {
                                                                            ...prev.document_receiver,
                                                                            address: addr.address || prev.document_receiver.address,
                                                                            district: addr.district.name || prev.document_receiver.district,
                                                                            city: addr.district.city.name || prev.document_receiver.city
                                                                        }
                                                                    }));
                                                                    setAddressSearch(addr.address);
                                                                    setShowAddressDropdown(false);
                                                                }}
                                                                className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${addr.address === formData.document_receiver.address ? 'font-bold' : ''
                                                                    }`}
                                                            >
                                                                <div className="font-medium">{addr.address}</div>
                                                                <div className="text-sm text-gray-500">
                                                                    {addr.district.name}, {addr.district.city.name}
                                                                </div>
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Actividad Económica
                                            </label>
                                            <div className="relative" ref={activityRef}>
                                                <div className="flex items-center relative">
                                                    <input
                                                        type="text"
                                                        value={activitySearch}
                                                        onChange={(e) => {
                                                            setActivitySearch(e.target.value);
                                                            setShowActivityDropdown(true);
                                                        }}
                                                        onFocus={() => {
                                                            setShowActivityDropdown(true);
                                                            setActivitySearch('');
                                                        }}
                                                        className="w-full px-3 py-2 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                        placeholder={selectedActivity?.name || "Buscar actividad..."}
                                                    />
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                        {loadingActivities ? (
                                                            <SpinningRing size="sm" />
                                                        ) : (
                                                            <Search className="h-5 w-5 text-gray-400" />
                                                        )}
                                                    </div>
                                                </div>
                                                {showActivityDropdown && (
                                                    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                                                        {loadingActivities ? (
                                                            <div className="p-4 text-center text-gray-500">
                                                                Cargando actividades...
                                                            </div>
                                                        ) : (activitySearch ? filteredActivities : activities).map((act: Activity) => (
                                                            <button
                                                                key={act.id}
                                                                type="button"
                                                                onClick={() => {
                                                                    setSelectedActivity(act);
                                                                    setFormData(prev => ({
                                                                        ...prev,
                                                                        document_receiver: {
                                                                            ...prev.document_receiver,
                                                                            business_activity: act.name,
                                                                            activity_code: act.code
                                                                        }
                                                                    }));
                                                                    setActivitySearch(act.name);
                                                                    setShowActivityDropdown(false);
                                                                }}
                                                                className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${selectedActivity?.id === act.id ? 'font-bold' : ''
                                                                    }`}
                                                            >
                                                                <div className="font-medium">{act.name}</div>
                                                                <div className="text-sm text-gray-500">
                                                                    Código: {act.code}
                                                                </div>
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Comuna
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.document_receiver.district}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    document_receiver: {
                                                        ...prev.document_receiver,
                                                        district: e.target.value
                                                    }
                                                }))}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Ciudad
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.document_receiver.city}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    document_receiver: {
                                                        ...prev.document_receiver,
                                                        city: e.target.value
                                                    }
                                                }))}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>
                                    </div>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="products"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="space-y-4"
                                >
                                    {/* Products Table */}
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    details: [
                                                        ...prev.details,
                                                        {
                                                            line_number: prev.details.length + 1,
                                                            item_name: '',
                                                            item_description: '',
                                                            quantity: '1.00',
                                                            unit_price: '0.00',
                                                            discount_percent: '0.00',
                                                            item_total: '0.00',
                                                            item_code: '',
                                                            unit: 'UN',
                                                            other_tax: null
                                                        }
                                                    ]
                                                }));
                                            }}
                                            className="flex items-center space-x-2 px-4 py-2 text-green-600 hover:bg-green-50 rounded-md transition-colors"
                                        >
                                            <Plus className="h-5 w-5" />
                                            <span>Agregar Producto</span>
                                        </button>
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Código</th>
                                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
                                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Descripción</th>
                                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Cantidad</th>
                                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Precio Unit.</th>
                                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Descuento %</th>
                                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                                                    <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {formData.details.map((detail, index: number) => (
                                                    <tr key={index}>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="text"
                                                                value={detail.item_code}
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        item_code: e.target.value
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="text"
                                                                value={detail.item_name}
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        item_name: e.target.value
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="text"
                                                                value={detail.item_description || ''}
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        item_description: e.target.value
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                                                placeholder="Descripción adicional..."
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="number"
                                                                value={detail.quantity}
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        quantity: e.target.value,
                                                                        item_total: (parseFloat(e.target.value) * parseFloat(detail.unit_price.toString()) * (1 - parseFloat(detail.discount_percent.toString()) / 100)).toString()
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                                min="0"
                                                                step="1"
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="number"
                                                                value={detail.unit_price}
                                                                placeholder="$0"
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        unit_price: e.target.value,
                                                                        item_total: (parseFloat(detail.quantity.toString()) * parseFloat(e.target.value) * (1 - parseFloat(detail.discount_percent.toString()) / 100)).toString()
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                                min="0"
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <input
                                                                type="number"
                                                                value={detail.discount_percent}
                                                                placeholder="0%"
                                                                onChange={(e) => {
                                                                    const newDetails = [...formData.details];
                                                                    newDetails[index] = {
                                                                        ...detail,
                                                                        discount_percent: e.target.value,
                                                                        item_total: (parseFloat(detail.quantity.toString()) * parseFloat(detail.unit_price.toString()) * (1 - parseFloat(e.target.value) / 100)).toString()
                                                                    };
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                                min="0"
                                                                max="100"
                                                                step="0.01"
                                                            />
                                                        </td>
                                                        <td className="px-4 py-3 text-right">
                                                            ${Math.round(parseFloat(detail.item_total.toString())).toLocaleString('es-CL')}
                                                        </td>
                                                        <td className="px-4 py-3">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    const newDetails = formData.details.filter((_: unknown, i: number) => i !== index);
                                                                    setFormData(prev => ({ ...prev, details: newDetails }));
                                                                }}
                                                                className="text-red-600 hover:text-red-700 transition-colors"
                                                            >
                                                                <Trash2 className="h-5 w-5" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <div className="flex justify-end space-x-3 pt-4">
                            {savingStatus === 'saving' && (
                                <div className="flex items-center text-gray-600">
                                    <SpinningRing size="sm" className="mr-2" />
                                    <span>Guardando cambios...</span>
                                </div>
                            )}
                            {savingStatus === 'refreshing' && (
                                <div className="flex items-center text-gray-600">
                                    <SpinningRing size="sm" className="mr-2" />
                                    <span>Actualizando PDF...</span>
                                </div>
                            )}
                            <button
                                type="submit"
                                disabled={isSaving}
                                className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors disabled:bg-green-400"
                            >
                                <Save className="h-5 w-5" />
                                <span>Guardar Cambios</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}