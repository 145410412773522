import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { ArrowRight, CheckCircle, Clock, FileText } from 'lucide-react';
import { CountryProvider } from '../../context/CountryContext';
import { ChatWidget } from '../../components/chat/ChatWidget';
import { Link } from 'react-router-dom';

export default function SolpiscinaCase() {
    return (
        <CountryProvider>
            <div className="min-h-screen bg-white">
                <Header />
                <main>
                    {/* Hero Section */}
                    <section className="pt-24 pb-16 bg-gradient-to-br from-green-50 to-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-6xl mx-auto">
                                <div className="grid md:grid-cols-2 gap-12 items-center">
                                    <div>
                                        <h1 className="text-5xl font-bold mb-6">
                                            Caso de Éxito: Solpiscina
                                        </h1>
                                        <p className="text-xl text-gray-600 mb-8">
                                            De horas facturando a minutos con TuPana.ai
                                        </p>
                                        <div className="flex flex-col sm:flex-row gap-4">
                                            <Link
                                                to="/signup"
                                                className="inline-flex items-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                            >
                                                <span>Prueba Pana Gratis</span>
                                                <ArrowRight className="h-5 w-5" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1200&q=80"
                                            alt="Piscina mantenida por Solpiscina"
                                            className="rounded-2xl shadow-xl"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Challenge Section */}
                    <section className="py-16 bg-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-4xl mx-auto">
                                <h2 className="text-3xl font-bold mb-8">El Desafío</h2>
                                <div className="bg-white rounded-2xl shadow-lg p-8 mb-12">
                                    <div className="flex items-start space-x-6">
                                        <img
                                            src="https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=400&q=80"
                                            alt="Javier Oyanedel"
                                            className="w-24 h-24 rounded-full object-cover"
                                        />
                                        <div>
                                            <p className="text-lg text-gray-600 mb-6">
                                                Solpiscina, una empresa dedicada al mantenimiento y construcción de piscinas,
                                                enfrentaba un cuello de botella cada mes: emitir cerca de 90 facturas manualmente
                                                en el portal del SII tomaba horas de trabajo administrativo.
                                            </p>
                                            <div className="grid md:grid-cols-3 gap-6">
                                                <div className="p-4 bg-red-50 rounded-lg">
                                                    <p className="font-medium text-red-800">4+ horas</p>
                                                    <p className="text-sm text-red-600">en facturación manual</p>
                                                </div>
                                                <div className="p-4 bg-red-50 rounded-lg">
                                                    <p className="font-medium text-red-800">90</p>
                                                    <p className="text-sm text-red-600">facturas mensuales</p>
                                                </div>
                                                <div className="p-4 bg-red-50 rounded-lg">
                                                    <p className="font-medium text-red-800">5-7 días</p>
                                                    <p className="text-sm text-red-600">retraso en cobros</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2 className="text-3xl font-bold mb-8">La Solución</h2>
                                <div className="grid md:grid-cols-3 gap-6 mb-12">
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <FileText className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">Excel Simple</h3>
                                        <p className="text-gray-600 text-sm">
                                            Subir un Excel con todas sus facturas mensuales
                                        </p>
                                    </div>
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <Clock className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">Emisión Rápida</h3>
                                        <p className="text-gray-600 text-sm">
                                            Sistema automatizado de emisión de facturas
                                        </p>
                                    </div>
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <CheckCircle className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">Sin Cambios</h3>
                                        <p className="text-gray-600 text-sm">
                                            Mantuvieron su facturador del SII
                                        </p>
                                    </div>
                                </div>

                                <h2 className="text-3xl font-bold mb-8">Los Resultados</h2>
                                <div className="bg-green-50 rounded-2xl p-8 mb-12">
                                    <div className="grid md:grid-cols-3 gap-6 mb-8">
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">10</p>
                                            <p className="text-sm text-green-800">minutos facturando</p>
                                        </div>
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">0</p>
                                            <p className="text-sm text-green-800">errores manuales</p>
                                        </div>
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">2-3</p>
                                            <p className="text-sm text-green-800">días menos en cobros</p>
                                        </div>
                                    </div>
                                    <blockquote className="text-lg text-green-800 italic text-center">
                                        "Antes perdíamos al menos medio día en facturar. Hoy en 10 minutos tenemos todo listo.
                                        Eso nos ha permitido dedicar más tiempo a vender y crecer."
                                        <footer className="text-sm text-green-600 mt-2">
                                            — Javier Oyanedel, Fundador de Solpiscina
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* CTA Section */}
                    <section className="py-16 bg-gradient-to-br from-green-50 to-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-4xl mx-auto text-center">
                                <h2 className="text-3xl font-bold mb-6">
                                    ¿Quieres resultados similares?
                                </h2>
                                <p className="text-xl text-gray-600 mb-8">
                                    Prueba Pana gratis y descubre cómo puedes ahorrar horas en tu proceso de facturación.
                                </p>
                                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                    <Link
                                        to="/signup"
                                        className="inline-flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                    >
                                        <span>Empezar prueba gratis</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
                <ChatWidget />
            </div>
        </CountryProvider>
    );
}