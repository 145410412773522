import React, { useRef, useEffect, useState } from 'react';
import { Send, Upload, Plus } from 'lucide-react';
import { motion } from 'framer-motion';

interface ChatInputProps {
    value: string;
    onChange: (value: string) => void;
    onSend: () => void;
    onFileUpload: (file: File) => void;
    isProcessing: boolean;
    inputRef?: React.RefObject<HTMLTextAreaElement>;
}

export function ChatInput({ value, onChange, onSend, onFileUpload, isProcessing, inputRef }: ChatInputProps) {
    const defaultRef = useRef<HTMLTextAreaElement>(null);
    const textareaRef = inputRef || defaultRef;

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend();
        }
    };

    const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            onFileUpload(file);
        }
    };

    return (
        <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="relative bg-white"
        >

            <div className="max-w-4xl mx-auto">
                <div className="relative border border-black/10 rounded-2xl shadow-[0_0_15px_rgba(0,0,0,0.1)] bg-white">
                    <textarea
                        ref={textareaRef}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Pregúntame sobre facturación..."
                        className="w-full resize-none bg-transparent border-0 focus:ring-0 py-4 pr-12 pl-4 min-h-[60px] max-h-48 text-gray-800 placeholder-gray-400 outline-none text-base"
                        rows={1}
                        disabled={isProcessing}
                    />

                    <div className="absolute right-2 bottom-2.5 flex items-center">
                        <label className="p-1.5 text-gray-500 hover:text-gray-700 cursor-pointer transition-colors rounded-lg hover:bg-gray-100">
                            <Plus className="h-5 w-5" />
                            <input
                                type="file"
                                className="hidden"
                                onChange={handleInputFileChange}
                                accept=".pdf,.xlsx,.jpg,.png"
                                disabled={isProcessing}
                            />
                        </label>
                        <button
                            onClick={onSend}
                            disabled={!value.trim() || isProcessing}
                            className={`p-1.5 rounded-lg transition-colors ${value.trim() && !isProcessing
                                    ? 'text-green-600 hover:bg-green-50'
                                    : 'text-gray-400 cursor-not-allowed'
                                }`}
                        >
                            <Send className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="text-center text-xs text-gray-500 mt-2">
                Tu Pana puede cometer errores. Por favor, verifica la información importante.
            </div>
        </motion.div>
    );
}