import React, { forwardRef } from 'react';
import { Activity } from '../types';

interface ActivitySelectorProps {
    selectedActivity: Activity | null;
    activities: Activity[] | undefined;
    onActivitySelect: (activity: Activity) => void;
    showDropdown: boolean;
    setShowDropdown: (show: boolean) => void;
    isManualActivity: boolean;
    setIsManualActivity: (isManual: boolean) => void;
    manualActivity: {
        name: string;
        code: string;
    };
    onManualActivityChange: (activity: typeof manualActivity) => void;
}

export const ActivitySelector = forwardRef<HTMLDivElement, ActivitySelectorProps>(({
    selectedActivity,
    activities,
    onActivitySelect,
    showDropdown,
    setShowDropdown,
    isManualActivity,
    setIsManualActivity,
    manualActivity,
    onManualActivityChange
}, ref) => {
    return (
        <div className="relative" ref={ref}>
            <button
                type="button"
                onClick={() => {
                    setShowDropdown(!showDropdown);
                }}
                className="w-full px-3 py-2 text-left border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 bg-white"
            >
                {selectedActivity ? (
                    <div>
                        <div className="font-medium">{selectedActivity.name}</div>
                        <div className="text-sm text-gray-500">Código: {selectedActivity.code}</div>
                    </div>
                ) : isManualActivity ? (
                    <div>
                        <div className="font-medium">{manualActivity.name || 'Ingrese actividad'}</div>
                        <div className="text-sm text-gray-500">
                            {manualActivity.code ? `Código: ${manualActivity.code}` : 'Ingrese código'}
                        </div>
                    </div>
                ) : (
                    <span className="text-gray-500">
                        Seleccionar actividad
                    </span>
                )}
            </button>

            {showDropdown && (
                <div className="absolute z-20 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                    <button
                        type="button"
                        onClick={() => {
                            setIsManualActivity(true);
                            setShowDropdown(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-50 border-b border-gray-200"
                    >
                        <div className="font-medium text-green-600">Ingresar manualmente</div>
                        <div className="text-sm text-gray-500">Agregar nueva actividad</div>
                    </button>
                    {activities?.map((activity) => (
                        <button
                            key={activity.id}
                            type="button"
                            onClick={() => {
                                onActivitySelect(activity);
                                setShowDropdown(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50"
                        >
                            <div className="font-medium">{activity.name}</div>
                            <div className="text-sm text-gray-500">
                                Código: {activity.code}
                            </div>
                        </button>
                    ))}
                </div>
            )}

            {isManualActivity && (
                <div className="mt-2 space-y-2">
                    <input
                        type="text"
                        value={manualActivity.name}
                        onChange={(e) => onManualActivityChange({
                            ...manualActivity,
                            name: e.target.value
                        })}
                        placeholder="Nombre de la actividad"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    />
                    <input
                        type="text"
                        value={manualActivity.code}
                        onChange={(e) => onManualActivityChange({
                            ...manualActivity,
                            code: e.target.value
                        })}
                        placeholder="Código de actividad"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    />
                </div>
            )}
        </div>
    );
});

ActivitySelector.displayName = 'ActivitySelector';