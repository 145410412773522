import React, { useState, useEffect, useRef } from 'react';
import { HandshakeIcon, X, Send } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useChat } from '../../hooks/useChat';
import { useCountry } from '../../context/CountryContext';
import { useLocation } from 'react-router-dom';

interface Message {
    type: 'user' | 'agent';
    content: string;
}

export function ChatWidget() {
    const { isOpen, openChat, closeChat } = useChat();
    const { country } = useCountry();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [showNotification, setShowNotification] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const isInPlatform = location.pathname.startsWith('/platform');

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        // Show initial message after a delay
        if (isOpen && messages.length === 0) {
            const timer = setTimeout(() => {
                setMessages([
                    {
                        type: 'agent',
                        content: country === 'chile'
                            ? '¡Hola! 👋 Soy tu Pana. Para ayudarte mejor, ¿me podrías dar tu número de celular? 📱'
                            : '¡Hola! 👋 Soy tu Pana. Para ayudarte mejor, ¿me podrías dar tu número de móvil? 📱'
                    }
                ]);
            }, 500);
            return () => clearTimeout(timer);
        }

        // Focus input when chat opens
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpen]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!message.trim()) return;

        const userMessage = { type: 'user', content: message };
        setMessages(prev => [...prev, userMessage]);
        setMessage('');
        scrollToBottom();

        // Simulate agent response
        const timer = setTimeout(() => {
            setMessages(prev => [...prev, {
                type: 'agent',
                content: 'Gracias por tu mensaje. Te responderemos en los próximos 10 minutos 🚀'
            }]);
        }, 1000);

        return () => clearTimeout(timer);
    };

    return (
        <>
            {/* Notification Badge */}
            {showNotification && !isOpen && !isInPlatform && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="fixed bottom-24 right-8 bg-white rounded-lg shadow-lg p-4 max-w-xs z-50"
                >
                    <div className="flex items-start space-x-3">
                        <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                            <HandshakeIcon className="h-5 w-5 text-green-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-gray-900">¡Tu Pana está aquí! 👋</h4>
                            <p className="text-sm text-gray-500 mt-1">
                                ¿Necesitas ayuda con la facturación? Respondo en 10 minutos.
                            </p>
                        </div>
                        <button
                            onClick={() => setShowNotification(false)}
                            className="text-gray-400 hover:text-gray-600"
                        >
                            <X className="h-4 w-4" />
                        </button>
                    </div>
                </motion.div>
            )}

            {/* Chat Button */}
            <button
                onClick={() => isOpen ? closeChat() : openChat()}
                className="fixed bottom-8 right-8 bg-green-600 text-white p-4 rounded-full shadow-lg hover:bg-green-700 transition-colors z-50"
            >
                <HandshakeIcon className="h-6 w-6" />
            </button>

            {/* Chat Window */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: 20, scale: 0.95 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, y: 20, scale: 0.95 }}
                        className="fixed bottom-24 right-8 bg-white rounded-lg shadow-xl w-96 z-50 overflow-hidden"
                    >
                        {/* Header */}
                        <div className="bg-green-600 p-4 text-white">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-3">
                                    <div className="w-10 h-10 rounded-full overflow-hidden">
                                        <img
                                            src="/prueba.png"
                                            alt="Tu Pana"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <div>
                                        <h3 className="font-medium">Tu Pana</h3>
                                        <p className="text-sm text-white/80">
                                            {country === 'chile'
                                                ? 'Respuesta en ~10 min'
                                                : 'Respuesta en ~10 min'}
                                        </p>
                                    </div>
                                </div>
                                <button
                                    onClick={closeChat}
                                    className="text-white/80 hover:text-white"
                                >
                                    <X className="h-5 w-5" />
                                </button>
                            </div>
                        </div>

                        {/* Messages */}
                        <div className="h-96 overflow-y-auto p-4 space-y-4">
                            {messages.map((msg, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 10, scale: 0.95 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    transition={{ duration: 0.2 }}
                                    className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`max-w-[80%] rounded-lg p-3 ${msg.type === 'user'
                                            ? 'bg-green-600 text-white'
                                            : 'bg-gray-100 text-gray-900'
                                            }`}
                                    >
                                        {msg.content}
                                    </div>
                                </motion.div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>

                        {/* Input */}
                        <form onSubmit={handleSubmit} className="border-t p-4">
                            <div className="relative">
                                <input
                                    type="text"
                                    ref={inputRef}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Escribe tu mensaje..."
                                    className="w-full pl-4 pr-12 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                                <button
                                    type="submit"
                                    className="absolute right-2 top-1/2 -translate-y-1/2 text-green-600 hover:text-green-700 p-1"
                                >
                                    <Send className="h-5 w-5" />
                                </button>
                            </div>
                        </form>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}