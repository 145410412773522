import React from 'react';

export function SimpleExample() {
    return (
        <div>
            <h3 className="text-lg font-medium mb-3">Ejemplo Simple</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Campo</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Valor</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">RUT Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">12.345.678-9</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Contacto Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Juan Pérez</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Fecha Emisión</td>
                            <td className="px-4 py-2 text-sm text-gray-500">2025-01-01</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Tipo Documento</td>
                            <td className="px-4 py-2 text-sm text-gray-500">33 (Factura Electrónica)</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Detalle Producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">
                                Nombre: Televisor LED 55"<br />
                                Código: TV-055<br />
                                Precio Unitario: $299.990<br />
                                Cantidad: 2<br />
                                Descuento: $0<br />
                                Total: $599.980
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Referencia</td>
                            <td className="px-4 py-2 text-sm text-gray-500">
                                Tipo: 33<br />
                                Folio: 1001<br />
                                Fecha: 2024-12-31<br />
                                Razón: Nota de crédito
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}