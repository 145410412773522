import React from 'react';
import { FileSpreadsheet } from 'lucide-react';

interface FileUploaderProps {
    onFileSelect: (file: File) => void;
    isProcessing: boolean;
    isDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
}

export function FileUploader({ onFileSelect, isProcessing, isDragging, setIsDragging }: FileUploaderProps) {
    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        onFileSelect(file);
    };

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            onFileSelect(file);
        }
    };

    return (
        <div
            onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                setIsDragging(false);
            }}
            onDrop={handleDrop}
            className={`
        border-2 border-dashed rounded-lg p-8 text-center transition-colors
        ${isDragging
                    ? 'border-green-500 bg-green-50'
                    : 'border-gray-300 hover:border-green-500 hover:bg-green-50'
                }
        ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}
        >
            <div className="flex flex-col items-center space-y-4">
                {isProcessing ? (
                    <>
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-600" />
                        <p className="text-gray-600">Procesando archivo...</p>
                    </>
                ) : (
                    <>
                        <FileSpreadsheet className="h-12 w-12 text-gray-400" />
                        <div>
                            <p className="text-gray-600 mb-2">
                                Arrastra tu archivo Excel aquí o
                            </p>
                            <label className="text-green-600 hover:text-green-700 cursor-pointer">
                                selecciona un archivo
                                <input
                                    type="file"
                                    accept=".xlsx,.xlsm"
                                    className="hidden"
                                    onChange={handleFileInput}
                                    disabled={isProcessing}
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}