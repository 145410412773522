import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Code, Shield, Zap, Puzzle, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function TechLanding() {
    return (
        <div className="min-h-screen bg-white">
            <Header />
            <main>
                {/* Hero Section */}
                <section className="pt-24 pb-16 bg-gradient-to-br from-green-50 to-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-6xl mx-auto">
                            <div className="grid md:grid-cols-2 gap-12 items-center">
                                <div>
                                    <h1 className="text-5xl font-bold mb-6">
                                        Integra tu plataforma con nuestra API de facturación en minutos
                                    </h1>
                                    <p className="text-xl text-gray-600 mb-8">
                                        Conecta tu sistema a Pana y genera documentos electrónicos sin pasar por el SII.
                                        API simple y documentada.
                                    </p>
                                    <Link
                                        to="/docs"
                                        className="inline-flex items-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                    >
                                        <span>Revisa la documentación y solicita tu token</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </Link>
                                </div>
                                <div className="relative">
                                    <img
                                        src="/developer-iron-maiden.webp"
                                        alt="Developer wearing Iron Maiden t-shirt"
                                        className="rounded-lg shadow-xl"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-tr from-black/20 to-transparent rounded-lg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Features Grid */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Code className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    API simple y documentada
                                </h3>
                                <p className="text-gray-600">
                                    Endpoints RESTful con ejemplos en múltiples lenguajes.
                                    Documentación interactiva con Swagger.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Shield className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Seguridad y control de emisión
                                </h3>
                                <p className="text-gray-600">
                                    Autenticación con tokens JWT. Control granular de permisos
                                    y límites de emisión.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Zap className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Emisión instantánea desde tu sistema
                                </h3>
                                <p className="text-gray-600">
                                    Integración directa con el SII. Respuestas en tiempo real
                                    y webhooks para notificaciones.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Puzzle className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Compatible con cualquier lenguaje
                                </h3>
                                <p className="text-gray-600">
                                    SDKs oficiales para Node.js, Python, PHP y más.
                                    Ejemplos de integración listos para usar.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Code Example Section */}
                <section className="py-16 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="max-w-5xl mx-auto">
                            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                                <div className="p-8">
                                    <h3 className="text-2xl font-bold mb-8">
                                        Integración simple y rápida
                                    </h3>
                                    <div className="grid md:grid-cols-2 gap-8">
                                        <div>
                                            <h4 className="font-medium mb-4">Emisión de facturas</h4>
                                            <pre className="bg-gray-900 text-gray-100 p-6 rounded-lg overflow-x-auto font-mono text-sm">
                                                <code>{`const pana = new PanaClient({
  apiKey: 'tu_api_key'
});

const invoice = await pana.invoices.create({
  customer: {
    rut: '76.123.456-7',
    name: 'Empresa ABC'
  },
  items: [{
    name: 'Producto 1',
    quantity: 2,
    price: 10000
  }]
});

console.log(invoice.id);`.trim()}</code>
                                            </pre>
                                        </div>
                                        <div>
                                            <h4 className="font-medium mb-4">Webhooks</h4>
                                            <pre className="bg-gray-900 text-gray-100 p-6 rounded-lg overflow-x-auto font-mono text-sm">
                                                <code>{`app.post('/webhooks/pana', (req, res) => {
  const event = req.body;

  switch (event.type) {
    case 'invoice.emitted':
      console.log('Nueva factura:', event.data.id);
      break;
    case 'invoice.rejected':
      console.log('Factura rechazada:', event.data.id);
      break;
  }

  res.sendStatus(200);
});`.trim()}</code>
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl mx-auto text-center">
                            <h2 className="text-3xl font-bold mb-6">
                                ¿Listo para integrar facturación en tu plataforma?
                            </h2>
                            <p className="text-xl text-gray-600 mb-8">
                                Empieza gratis y escala según tus necesidades. Sin costos ocultos.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                <Link
                                    to="/signup"
                                    className="inline-flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                >
                                    <span>Obtener API Key</span>
                                    <ArrowRight className="h-5 w-5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}