import React from 'react';

export function ExcelTable() {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">N° Fila</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">rut_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">nombre_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">giro_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">actividad_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">direccion_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">comuna_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">ciudad_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">tipo_venta</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">correo_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">telefono_emisor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">rut_receptor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">contacto_receptor</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">fecha_emision</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">tipo_documento</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_nombre</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_codigo</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_precio_unitario</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_cantidad</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_descuento</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">detalle_total</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">referencia_tipo</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">referencia_folio</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">referencia_fecha</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">referencia_razon</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    <tr className="hover:bg-gray-50">
                        <td className="px-4 py-2 text-sm font-medium text-gray-900">1</td>
                        <td className="px-4 py-2 text-sm text-gray-500">76.123.456-7</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Empresa Ejemplo S.A.</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Venta al por menor</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Comercio</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Av. Principal 123</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Santiago</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Santiago</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Contado</td>
                        <td className="px-4 py-2 text-sm text-gray-500">contacto@empresa.cl</td>
                        <td className="px-4 py-2 text-sm text-gray-500">+56912345678</td>
                        <td className="px-4 py-2 text-sm text-gray-500">12345678-9</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Juan Pérez</td>
                        <td className="px-4 py-2 text-sm text-gray-500">2025-03-01</td>
                        <td className="px-4 py-2 text-sm text-gray-500">33</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Producto A</td>
                        <td className="px-4 py-2 text-sm text-gray-500">PA001</td>
                        <td className="px-4 py-2 text-sm text-gray-500">10000</td>
                        <td className="px-4 py-2 text-sm text-gray-500">2</td>
                        <td className="px-4 py-2 text-sm text-gray-500">1000</td>
                        <td className="px-4 py-2 text-sm text-gray-500">19000</td>
                        <td className="px-4 py-2 text-sm text-gray-500">801</td>
                        <td className="px-4 py-2 text-sm text-gray-500">OC-2025-001</td>
                        <td className="px-4 py-2 text-sm text-gray-500">2025-02-28</td>
                        <td className="px-4 py-2 text-sm text-gray-500">Orden de Compra</td>
                    </tr>
                    <tr className="hover:bg-gray-50 bg-gray-50">
                        <td className="px-4 py-2 text-sm font-medium text-gray-900">2</td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500">Producto B</td>
                        <td className="px-4 py-2 text-sm text-gray-500">PB001</td>
                        <td className="px-4 py-2 text-sm text-gray-500">5000</td>
                        <td className="px-4 py-2 text-sm text-gray-500">1</td>
                        <td className="px-4 py-2 text-sm text-gray-500">0</td>
                        <td className="px-4 py-2 text-sm text-gray-500">5000</td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                        <td className="px-4 py-2 text-sm text-gray-500"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}