import React, { useState, useEffect } from 'react';
import { Link, Plus, Trash2 } from 'lucide-react';

interface Reference {
    id: string;
    reference_type: keyof typeof DOCUMENT_TYPES;
    reference_folio: string;
    reference_date: string;
    reference_reason: string;
}

interface ReferencesProps {
    references: Reference[];
    onChange: (references: Reference[]) => void;
}

const DOCUMENT_TYPES = {
    "30": "Factura",
    "32": "Factura no Afecta",
    "33": "Factura elec.",
    "34": "Factura no Afecta elec.",
    "35": "Boleta",
    "38": "Boleta exenta",
    "39": "Boleta elec.",
    "40": "Liq. Factura",
    "41": "Boleta exenta elec.",
    "43": "Liq. Factura elec.",
    "45": "Factura Compra",
    "46": "Factura Compra elec.",
    "48": "Comprobante pago elec.",
    "50": "Guia despacho",
    "52": "Guia despacho elec.",
    "55": "Nota Débito",
    "56": "Nota Débito elec.",
    "60": "Nota Crédito",
    "61": "Nota Crédito elec.",
    "103": "Liq. com. dis.",
    "801": "Orden de Compra",
    "802": "Nota de pedido",
    "803": "Contrato",
    "804": "Resolución",
    "805": "Proceso ChileCompra",
    "806": "Ficha ChileCompra",
    "813": "Pasaporte",
    "820": "Código registro de Economía",
    "821": "Geo Referencia",
    "822": "ROL Avaluo Predio",
    "823": "Plan de Manejo - Conaf",
    "HES": "Hoja Entrada Servicio"
} as const;

export function References({ references, onChange }: ReferencesProps) {
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [customerName, setCustomerName] = useState<string>('');

    useEffect(() => {
        const handleRequirementsChange = (event: CustomEvent) => {
            const { requiresOC, requiresHES, customerName } = event.detail;
            const newSuggestions = [];

            if (requiresOC) newSuggestions.push('Orden de Compra');
            if (requiresHES) newSuggestions.push('HES');

            setSuggestions(newSuggestions);
            setCustomerName(customerName);
        };

        window.addEventListener('customerRequirementsChanged', handleRequirementsChange as EventListener);

        return () => {
            window.removeEventListener('customerRequirementsChanged', handleRequirementsChange as EventListener);
        };
    }, []);

    const addReference = () => {
        const newReference = {
            id: Date.now().toString(),
            reference_type: "33",
            reference_folio: '',
            reference_date: new Date().toISOString().split('T')[0],
            reference_reason: ''
        };
        onChange([...references, newReference]);
    };

    const removeReference = (id: string) => {
        onChange(references.filter(ref => ref.id !== id));
    };

    const updateReference = (id: string, field: keyof Reference, value: string) => {
        if (field === 'reference_type') {
            const validValue = value as keyof typeof DOCUMENT_TYPES;
            const updatedReferences = references.map(ref =>
                ref.id === id ? { ...ref, [field]: validValue } : ref
            );
            console.log('Referencias actualizadas después de cambiar tipo:', updatedReferences);
            onChange(updatedReferences);
        } else {
            const updatedReferences = references.map(ref =>
                ref.id === id ? { ...ref, [field]: value } : ref
            );
            console.log('Referencias actualizadas después de cambiar ' + field + ':', updatedReferences);
            onChange(updatedReferences);
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <Link className="h-5 w-5 text-green-600" />
                    <h3 className="text-lg font-medium">Referencias</h3>
                </div>
                <button
                    type="button"
                    onClick={addReference}
                    className="flex items-center space-x-1 text-green-600 hover:text-green-700"
                >
                    <Plus className="h-4 w-4" />
                    <span>Agregar Referencia</span>
                </button>
                {suggestions.length > 0 && (
                    <p className="text-sm text-gray-500">
                        {`${customerName} requiere: ${suggestions.join(' y ')}`}
                    </p>
                )}
            </div>

            {references.map((ref) => (
                <div key={ref.id} className="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 border rounded-md">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Tipo Documento
                        </label>
                        <select
                            value={ref.reference_type}
                            onChange={(e) => updateReference(ref.id, 'reference_type', e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                        >
                            <optgroup label="Seleccione una opción">
                                <option value=""></option>
                                {Object.entries(DOCUMENT_TYPES).map(([code, label]) => (
                                    <option key={code} value={code}>
                                        {`${label} (${code})`}
                                    </option>
                                ))}
                            </optgroup>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Folio
                        </label>
                        <input
                            type="text"
                            value={ref.reference_folio}
                            onChange={(e) => updateReference(ref.id, 'reference_folio', e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Fecha
                        </label>
                        <input
                            type="date"
                            value={ref.reference_date}
                            onChange={(e) => updateReference(ref.id, 'reference_date', e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="relative">
                        <button
                            type="button"
                            onClick={() => removeReference(ref.id)}
                            className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                        >
                            <Trash2 className="h-5 w-5" />
                        </button>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Razón
                        </label>
                        <input
                            type="text"
                            value={ref.reference_reason}
                            onChange={(e) => updateReference(ref.id, 'reference_reason', e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}