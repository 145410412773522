interface SpinningRingProps {
    size?: 'sm' | 'md' | 'lg';
    thickness?: 'thin' | 'normal' | 'thick';
    className?: string;
}

export function SpinningRing({
    size = 'md',
    thickness = 'normal',
    className = ''
}: SpinningRingProps) {
    const sizeClasses = {
        sm: 'w-6 h-6',
        md: 'w-10 h-10',
        lg: 'w-16 h-16'
    };

    const thicknessClasses = {
        thin: 'border-2',
        normal: 'border-3',
        thick: 'border-4'
    };

    return (
        <div
            className={`
        ${sizeClasses[size]}
        ${thicknessClasses[thickness]}
        rounded-full
        border-green-600
        border-t-transparent
        animate-spin
        ${className}
      `}
            role="status"
            aria-label="Loading"
        />
    );
} 