import React, { useState, useEffect, useCallback } from 'react';
import { Layout } from '../../components/platform/layout/Layout';
import { TableLoader } from '../../components/TableLoader';
import { SpinningRing } from '../../components/SpinningRing';
import { api } from '../../services/api';

interface APICustomer {
    name: string;
    tax_id: string;
    document_count: number;
    business_name: string;
    business_activity: string;
    contact: string;
    address: string;
    district: string;
    city: string;
}

export function Customers() {
    const [state, setState] = useState({
        customers: [] as APICustomer[],
        loading: true,
        error: null as string | null,
    });

    const fetchCustomers = useCallback(async () => {
        try {
            const entityId = localStorage.getItem('selectedEntityId');
            if (!entityId) {
                throw new Error('No hay entidad seleccionada');
            }

            const url = new URL(`${import.meta.env.VITE_API_URL}/api/master-entities/${entityId}/customers/`);
            const response = await api.fetch(url.toString());

            if (!response.ok) {
                throw new Error('Error al obtener los clientes');
            }

            const data = await response.json();
            console.log('Clientes obtenidos:', data);

            setState(prev => ({
                ...prev,
                customers: data || [],
                loading: false,
            }));
        } catch (error) {
            console.error('Error fetching customers:', error);
            setState(prev => ({
                ...prev,
                error: 'Error al cargar los clientes',
                loading: false,
            }));
        }
    }, []);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    if (state.loading) {
        return (
            <Layout>
                <div className="flex justify-center p-6">
                    <SpinningRing size="md" thickness="normal" />
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="bg-white rounded-lg shadow-lg">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-6">
                        Clientes
                    </h2>

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        RUT
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Razón Social
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Giro
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Contacto
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Dirección
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {state.customers.map((customer) => (
                                    <tr key={customer.tax_id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {customer.tax_id}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {customer.business_name || customer.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {customer.business_activity}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {customer.contact}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {customer.address && customer.district && customer.city
                                                ? `${customer.address}, ${customer.district}, ${customer.city}`
                                                : '-'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
} 