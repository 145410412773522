import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { QuickEditor } from './QuickEditor';
import { SpinningRing } from '../../../../../components/SpinningRing';

interface InvoiceCardProps {
    invoice: {
        id: number;
        document_receiver: {
            business_name: string;
            rut: string;
            business_activity?: string;
            address?: string;
            district?: string;
            city?: string;
        };
        receiver?: {
            activities?: Array<{ code: string; name: string; }>;
            addresses?: Array<{ address: string; district: { name: string; city: { name: string; }; }; }>;
        };
        date_issued: string;
        details: any[];
        amount_with_iva: string;
        has_business_activity: boolean;
        has_address: boolean;
    };
    isSelected: boolean;
    onSelect: () => void;
    onSave: (id: number, data: any) => Promise<void>;
    onRefreshPdf: () => Promise<void>;
    isRefreshing: boolean;
    isSearching: boolean;
    pdfState: {
        data: string | null;
        isLoading: boolean;
        error: string | null;
    };
}

export function InvoiceCard({
    invoice,
    isSelected,
    onSelect,
    onSave,
    onRefreshPdf,
    isRefreshing,
    isSearching,
    pdfState
}: InvoiceCardProps) {
    const [shouldBlur, setShouldBlur] = useState(false);
    const canPreview = invoice.has_business_activity && invoice.has_address;

    useEffect(() => {
        if (isSearching) {
            setShouldBlur(true);
        } else {
            const timer = setTimeout(() => setShouldBlur(false), 300);
            return () => clearTimeout(timer);
        }
    }, [isSearching]);

    const formatRut = (rut: string) => {
        if (!rut) return '';
        const clean = rut.replace(/\./g, '').replace(/-/g, '');
        const body = clean.slice(0, -1);
        const dv = clean.slice(-1);
        const formatted = body.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return `${formatted}-${dv}`;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`group transition-all duration-300 ${isSearching ? 'opacity-50' : ''}`}
        >
            <div className="p-6 hover:bg-gray-50 transition-colors">
                <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={onSelect}
                >
                    <div className="flex-1">
                        <div className="flex items-center space-x-3">
                            <h4 className="text-lg font-medium text-gray-900">
                                {invoice.document_receiver.business_name}
                            </h4>
                            {(!invoice.has_business_activity || !invoice.has_address) && (
                                <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-yellow-100 text-yellow-800">
                                    {!invoice.has_business_activity && !invoice.has_address
                                        ? 'Falta actividad y dirección'
                                        : !invoice.has_business_activity
                                            ? 'Falta actividad'
                                            : 'Falta dirección'}
                                </span>
                            )}
                        </div>
                        <div className="mt-1 flex items-center text-sm text-gray-500 space-x-4">
                            <span>RUT: {formatRut(invoice.document_receiver.rut)}</span>
                            <span>•</span>
                            <span>{invoice.receiver?.addresses?.[0]?.address || 'Sin dirección'}</span>
                            <span>•</span>
                            <span>{invoice.receiver?.activities?.[0]?.name || 'Sin actividad económica'}</span>
                            <span>•</span>
                            <span>Fecha: {new Date(invoice.date_issued).toLocaleDateString('es-CL')}</span>
                            <span>•</span>
                            <span>{invoice.details.length} {invoice.details.length === 1 ? 'ítem' : 'ítems'}</span>
                        </div>
                    </div>
                    <div className="flex items-center space-x-6">
                        <div className="text-right">
                            <div className="text-lg font-medium text-gray-900">
                                ${parseFloat(invoice.amount_with_iva).toLocaleString('es-CL')}
                            </div>
                            <div className="text-sm text-gray-500">Total con IVA</div>
                        </div>
                        <div className="transform transition-transform group-hover:translate-x-1">
                            {isSelected ? (
                                <ChevronUp className="h-5 w-5 text-gray-400" />
                            ) : (
                                <ChevronDown className="h-5 w-5 text-gray-400" />
                            )}
                        </div>
                    </div>
                </div>

                <AnimatePresence>
                    {isSelected && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="mt-6 overflow-hidden"
                        >
                            <div className="border-t pt-6">
                                <QuickEditor
                                    invoice={invoice}
                                    onSave={onSave}
                                    onRefreshPdf={onRefreshPdf}
                                    isRefreshing={isRefreshing}
                                />

                                {!canPreview ? (
                                    <div className="p-4 bg-yellow-50 rounded-lg mt-4">
                                        <div className="flex items-start space-x-3">
                                            <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                                            <div>
                                                <p className="font-medium text-yellow-800">
                                                    No se puede previsualizar
                                                </p>
                                                <p className="text-sm text-yellow-700 mt-1">
                                                    {!invoice.has_business_activity && !invoice.has_address
                                                        ? 'Falta actividad económica y dirección'
                                                        : !invoice.has_business_activity
                                                            ? 'Falta actividad económica'
                                                            : 'Falta dirección'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : pdfState?.isLoading ? (
                                    <div className="flex justify-center items-center py-12">
                                        <SpinningRing size="lg" />
                                    </div>
                                ) : pdfState?.error ? (
                                    <div className="p-4 bg-red-50 rounded-lg text-red-600 mt-4">
                                        {pdfState.error}
                                    </div>
                                ) : pdfState?.data && (
                                    <iframe
                                        src={`data:application/pdf;base64,${pdfState.data}`}
                                        className="w-full h-[600px] border-0 rounded-lg mt-4"
                                        title="Vista previa de factura"
                                    />
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.div>
    );
}