import React, { createContext, useContext, useReducer, useEffect } from "react";

interface Entity {
    id: string;
    name: string;
}

interface EntityState {
    selectedEntity: Entity | null;
    masterEntities: Entity[];
}

type EntityAction =
    | { type: "SET_ENTITY"; payload: Entity }
    | { type: "SET_MASTER_ENTITIES"; payload: Entity[] }
    | { type: "CLEAR_ENTITY" };

const initialState: EntityState = {
    selectedEntity: null,
    masterEntities: [],
};

function entityReducer(state: EntityState, action: EntityAction): EntityState {
    switch (action.type) {
        case "SET_ENTITY":
            // Guardar en localStorage cuando se selecciona una entidad
            localStorage.setItem('selectedEntityId', action.payload.id);
            localStorage.setItem('selectedEntityName', action.payload.name);
            return {
                ...state,
                selectedEntity: action.payload,
            };
        case "SET_MASTER_ENTITIES":
            return {
                ...state,
                masterEntities: action.payload,
            };
        case "CLEAR_ENTITY":
            localStorage.removeItem('selectedEntityId');
            localStorage.removeItem('selectedEntityName');
            return initialState;
        default:
            return state;
    }
}

const EntityContext = createContext<{
    state: EntityState;
    dispatch: React.Dispatch<EntityAction>;
}>({
    state: initialState,
    dispatch: () => null,
});

export function EntityProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(entityReducer, initialState);

    useEffect(() => {
        const initializeState = async () => {
            try {
                // Obtener las entidades maestras
                const storedMasterEntities = localStorage.getItem('masterEntities');
                let masterEntities = [];

                if (storedMasterEntities) {
                    masterEntities = JSON.parse(storedMasterEntities);
                    dispatch({
                        type: 'SET_MASTER_ENTITIES',
                        payload: masterEntities
                    });
                }

                // Obtener la entidad seleccionada
                const selectedEntityId = localStorage.getItem('selectedEntityId');
                const selectedEntityName = localStorage.getItem('selectedEntityName');

                if (selectedEntityId && selectedEntityName) {
                    // Si hay una entidad seleccionada en localStorage, usarla
                    dispatch({
                        type: 'SET_ENTITY',
                        payload: {
                            id: selectedEntityId,
                            name: selectedEntityName,
                        },
                    });
                } else if (masterEntities.length > 0) {
                    // Solo si no hay entidad seleccionada, usar la primera
                    const firstEntity = masterEntities[0];
                    dispatch({
                        type: 'SET_ENTITY',
                        payload: {
                            id: firstEntity.id,
                            name: firstEntity.name,
                        },
                    });
                }
            } catch (error) {
                console.error('Error initializing entity state:', error);
            }
        };

        initializeState();
    }, []);

    // Escuchar cambios en localStorage para sincronizar el estado
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'accessToken' && event.newValue === null) {
                // Si se elimina el accessToken (logout), limpiar el estado
                dispatch({ type: 'CLEAR_ENTITY' });
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    return (
        <EntityContext.Provider value={{ state, dispatch }}>
            {children}
        </EntityContext.Provider>
    );
}

export function useEntity() {
    const context = useContext(EntityContext);
    if (!context) {
        throw new Error('useEntity must be used within an EntityProvider');
    }
    return context;
}
