import React from 'react';
import { HandshakeIcon } from 'lucide-react';
import { OnboardingStepper } from './OnboardingStepper';

interface OnboardingLayoutProps {
    children: React.ReactNode;
    currentStep: number;
    title: string;
    subtitle?: string;
    step: 'name' | 'email' | 'phone' | 'company' | 'password';
    verificationSent?: boolean;
}

export function OnboardingLayout({ children, currentStep, title, subtitle }: OnboardingLayoutProps) {
    return (
        <div className="min-h-screen bg-gradient-to-br from-green-50 to-white relative overflow-x-hidden">
            {/* Header */}
            <div className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b border-gray-200">
                <div className="container mx-auto px-4 h-16 flex items-center">
                    <a href="/" className="flex items-center space-x-2">
                        <HandshakeIcon className="h-8 w-8 text-green-600" />
                        <span className="text-2xl font-bold">Pana<span className="text-green-600">.cl</span></span>
                    </a>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 pt-24 pb-12">
                <div className="max-w-md mx-auto">
                    {/* Progress */}
                    <OnboardingStepper currentStep={currentStep} />

                    {/* Content */}
                    <div className="bg-white rounded-2xl shadow-xl p-8">
                        <div className="text-center mb-8">
                            <h1 className="text-2xl font-bold text-gray-900 mb-2">
                                {title}
                            </h1>
                            {subtitle && (
                                <p className="text-sm text-gray-600">
                                    {subtitle}
                                </p>
                            )}
                        </div>

                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}