import React from 'react';
import { ChatWidget } from '../chat/ChatWidget';
import { CountryProvider } from '../../context/CountryContext';

interface AppWrapperProps {
    children: React.ReactNode;
}

export function AppWrapper({ children }: AppWrapperProps) {
    return (
        <CountryProvider>
            {children}
            <ChatWidget />
        </CountryProvider>
    );
}