import React from 'react';
import { Search, Plus, RefreshCw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface DocumentsHeaderProps {
    searchTerm: string;
    onSearch: (term: string) => void;
    isConfigured: boolean;
    currentEntity: string;
    onBulkCreation?: () => void;
    onCreateInvoice?: () => void;
    onRefresh?: () => void;
    isRefreshing?: boolean;
}

export function DocumentsHeader({
    searchTerm,
    onSearch,
    isConfigured,
    currentEntity,
    onBulkCreation,
    onCreateInvoice,
    onRefresh,
    isRefreshing
}: DocumentsHeaderProps) {
    const [showEmissionOptions, setShowEmissionOptions] = React.useState(false);
    const navigate = useNavigate();

    const handleEmitInvoice = () => {
        setShowEmissionOptions(false);
        navigate('/platform/invoice-form');
    };

    return (
        <div className="p-6 border-b border-gray-200">
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-3">
                    <h2 className="text-xl font-semibold text-gray-900">
                        Facturas de {currentEntity}
                    </h2>
                    {onRefresh && (
                        <button
                            onClick={onRefresh}
                            disabled={isRefreshing}
                            className="p-2 text-gray-500 hover:text-gray-700 disabled:text-gray-300 transition-colors rounded-full hover:bg-gray-100"
                            title="Actualizar documentos desde SII"
                        >
                            <RefreshCw className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
                        </button>
                    )}
                </div>

                {isConfigured && (
                    <div className="flex items-center space-x-4">
                        <div className="relative">
                            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
                                <Search className="h-5 w-5 text-gray-400" />
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => onSearch(e.target.value)}
                                    placeholder="Buscar facturas..."
                                    className="ml-2 outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        <div className="relative">
                            <button
                                onClick={() => setShowEmissionOptions(!showEmissionOptions)}
                                className="flex items-center space-x-2 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
                            >
                                <Plus className="h-5 w-5" />
                                <span>Emitir Factura</span>
                            </button>

                            {showEmissionOptions && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                                    <div className="py-1">
                                        <button
                                            onClick={handleEmitInvoice}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Emitir una Factura
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowEmissionOptions(false);
                                                navigate('/platform/bulk-invoice-excel');
                                            }}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Emisión Masiva
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}