import React from 'react';
import { FileDown, Upload, Search, Send } from 'lucide-react';
import { useCountry } from '../context/CountryContext';

export function HowItWorks() {
  const { country } = useCountry();

  const steps = [
    {
      icon: FileDown,
      title: country === 'chile' ? 'Descarga nuestra plantilla Excel' : 'Descarga nuestra plantilla Excel',
      description: country === 'chile' ? 'Está lista para ti.' : 'Está lista para ti.'
    },
    {
      icon: Upload,
      title: country === 'chile' ? 'Llénala con tus facturas' : 'Rellénala con tus facturas',
      description: country === 'chile' ? 'Como siempre, nada raro.' : 'Como siempre, nada raro.'
    },
    {
      icon: Upload,
      title: country === 'chile' ? 'Súbela a Tu Pana' : 'Súbela a Tu Pana',
      description: country === 'chile' ? 'Es tan simple como suena.' : 'Es tan sencillo como suena.'
    },
    {
      icon: Send,
      title: country === 'chile' ? 'Nosotros facturamos todo por ti' : 'Nosotros facturamos todo por ti',
      description: country === 'chile' ? 'Así de simple.' : 'Así de simple.'
    }
  ];

  return (
    <section id="como-funciona" className="py-16 bg-gradient-to-br from-green-50 to-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          {country === 'chile' ? '¿Cómo Funciona?' : '¿Cómo Funciona?'}
        </h2>
        <div className="max-w-6xl mx-auto">
          {steps.map((step, index) => {
            const Icon = step.icon;
            return (
              <div key={index} className="flex flex-col md:flex-row items-center mb-12 last:mb-0">
                <div className="md:w-1/3 mb-6 md:mb-0 flex justify-center">
                  <div className="w-20 h-20 bg-green-600 rounded-full flex items-center justify-center">
                    <Icon className="h-10 w-10 text-white" />
                  </div>
                </div>
                <div className="md:w-2/3 text-center md:text-left">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}