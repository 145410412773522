import React, { createContext, useContext, useState } from 'react';

type Country = 'chile' | 'spain';

interface CountryContextType {
    country: Country;
    setCountry: (country: Country) => void;
    translations: {
        currency: string;
        taxAuthority: string;
        taxId: string;
        taxIdExample: string;
        district: string;
        city: string;
    };
}

const translations = {
    chile: {
        currency: 'CLP',
        taxAuthority: 'SII',
        taxId: 'RUT',
        taxIdExample: '12.345.678-9',
        district: 'Comuna',
        city: 'Ciudad'
    },
    spain: {
        currency: 'EUR',
        taxAuthority: 'AEAT',
        taxId: 'NIF',
        taxIdExample: 'B12345678',
        district: 'Municipio',
        city: 'Ciudad'
    }
};

const CountryContext = createContext<CountryContextType>({
    country: 'chile',
    setCountry: () => { },
    translations: translations.chile
});

export function CountryProvider({ children }: { children: React.ReactNode }) {
    const [country, setCountry] = useState<Country>(() => {
        // Try to get the country from localStorage
        const savedCountry = localStorage.getItem('country');
        return (savedCountry === 'chile' || savedCountry === 'spain') ? savedCountry : 'chile';
    });

    const handleSetCountry = (newCountry: Country) => {
        setCountry(newCountry);
        // Save to localStorage
        localStorage.setItem('country', newCountry);
    };

    return (
        <CountryContext.Provider
            value={{
                country,
                setCountry: handleSetCountry,
                translations: translations[country]
            }}
        >
            {children}
        </CountryContext.Provider>
    );
}

export function useCountry() {
    return useContext(CountryContext);
}