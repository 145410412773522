import React from 'react';
import { BulkInvoiceCreation } from '../components/platform/invoice/bulk/BulkInvoiceCreation';
import { Layout } from '../components/platform/layout/Layout';

export function BulkInvoiceEmissionPage() {
    return (
        <Layout>
            <div className="p-6">
                <BulkInvoiceCreation
                    onBack={() => window.history.back()}
                    onNext={() => { }}
                />
            </div>
        </Layout>
    );
} 