import React, { useState } from 'react';
import { X, Mail, Phone, User, Loader2 } from 'lucide-react';
import { useCountry } from '../../context/CountryContext';

interface DemoRequestModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface FormData {
    name: string;
    email: string;
    phone: string;
}

export function DemoRequestModal({ isOpen, onClose }: DemoRequestModalProps) {
    const { country } = useCountry();
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    if (!isOpen) return null;

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            // TODO: Replace with actual API endpoint
            await new Promise(resolve => setTimeout(resolve, 1000));

            // const response = await fetch('/api/demo-requests', {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify(formData),
            // });

            // if (!response.ok) {
            //   throw new Error('Error al enviar la solicitud');
            // }

            setIsSuccess(true);
            setTimeout(() => {
                onClose();
                setIsSuccess(false);
                setFormData({ name: '', email: '', phone: '' });
            }, 3000);
        } catch (err) {
            setError('Error al enviar la solicitud. Por favor, intenta nuevamente.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setError('');
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                    onClick={onClose}
                />

                <div className="relative bg-white rounded-xl shadow-2xl w-full max-w-md transform transition-all">
                    <div className="absolute top-4 right-4">
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-600 transition-colors"
                        >
                            <X className="h-6 w-6" />
                        </button>
                    </div>

                    <div className="p-8">
                        {isSuccess ? (
                            <div className="text-center py-8">
                                <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                    <Mail className="h-8 w-8 text-green-600" />
                                </div>
                                <h3 className="text-2xl font-bold text-gray-900 mb-2">
                                    {country === 'chile' ? '¡Solicita una Demo!' : '¡Solicita una Demo!'}
                                </h3>
                                <p className="text-gray-600">
                                    {country === 'chile'
                                        ? 'Nos pondremos en contacto contigo muy pronto.'
                                        : 'Nos pondremos en contacto contigo muy pronto.'}
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className="text-center mb-8">
                                    <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                        <Mail className="h-8 w-8 text-green-600" />
                                    </div>
                                    <h3 className="text-2xl font-bold text-gray-900 mb-2">
                                        ¡Solicita una Demo!
                                    </h3>
                                    <p className="text-gray-600">
                                        {country === 'chile'
                                            ? 'Déjanos tus datos y te contactaremos para mostrarte cómo Tu Pana puede ayudarte a facturar más rápido.'
                                            : 'Déjanos tus datos y te contactaremos para mostrarte cómo Tu Pana puede ayudarte a facturar más rápido.'}
                                    </p>
                                </div>

                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <div className="relative">
                                            <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder={country === 'chile' ? 'Tu nombre' : 'Tu nombre'}
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="relative">
                                            <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder={country === 'chile' ? 'Tu email' : 'Tu email'}
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="relative">
                                            <Phone className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                            <input
                                                type="tel"
                                                name="phone"
                                                placeholder={country === 'chile' ? 'Tu celular' : 'Tu móvil'}
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                                className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                            />
                                        </div>
                                    </div>

                                    {error && (
                                        <p className="text-sm text-red-600">
                                            {error}
                                        </p>
                                    )}

                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="w-full flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-3 rounded-lg hover:bg-green-700 transition disabled:bg-green-400"
                                    >
                                        {isLoading ? (
                                            <Loader2 className="h-5 w-5 animate-spin" />
                                        ) : (
                                            <>
                                                <Mail className="h-5 w-5" />
                                                <span>{country === 'chile' ? 'Solicitar Demo' : 'Solicitar Demo'}</span>
                                            </>
                                        )}
                                    </button>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}