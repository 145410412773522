import React, { useState, useEffect } from 'react';
import { NameStep } from '../components/onboarding/NameStep';
import { EmailStep } from '../components/onboarding/EmailStep';
import { PhoneStep } from '../components/onboarding/PhoneStep';
import { CompanyRutStep } from '../components/onboarding/CompanyRutStep';
import { PasswordStep } from '../components/onboarding/PasswordStep';
import { Link } from 'react-router-dom';
import { HandshakeIcon } from 'lucide-react';
import { OnboardingLayout } from '../components/onboarding/OnboardingLayout';
import { OnboardingStepper } from '../components/onboarding/OnboardingStepper';
import { PanaAssistant } from '../components/onboarding/PanaAssistant';

const panaMessages = {
    name: "¡Hola! Me alegro de conocerte. Juntos vamos a hacer que facturar sea pan comido 🍞",
    email: "Con tu correo te mando el código para validar tu cuenta y avisarte cuando tus facturas estén listas 😉",
    phone: "¿Prefieres WhatsApp? También te puedo avisar por ahí cuando todo esté listo 📱",
    company: "Tranqui, seguimos usando tu facturador actual del SII. ¡Vamos bien! Ya casi estás listo para facturar en minutos 👍",
    password: "¡Último paso! Con esto ya automatizai todo el proceso. Después me encargo yo de la parte fome 🚀"
};

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyRut: string;
    companyName: string;
    password: string;
    company_rut: string;
}

export default function Signup() {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyRut: '',
        companyName: '',
        password: '',
        company_rut: ''
    });
    const [verificationSent, setVerificationSent] = useState(false);
    const [companyFound, setCompanyFound] = useState(false);

    // Efecto para asegurar que el diseño se aplique correctamente después de que el componente se monte
    useEffect(() => {
        // Forzar un rerender para asegurar que el diseño se aplique correctamente
        const timer = setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    const handleNameComplete = (data: { firstName: string; lastName: string }) => {
        setFormData(prev => ({
            ...prev,
            firstName: data.firstName,
            lastName: data.lastName
        }));
        localStorage.setItem('userName', `${data.firstName} ${data.lastName}`);
        setCurrentStep(prev => prev + 1);
    };

    const handleEmailComplete = (email: string) => {
        setFormData(prev => ({ ...prev, email }));
        setCurrentStep(prev => prev + 1);
        setVerificationSent(true);
    };

    const handlePhoneComplete = (phone: string) => {
        setFormData(prev => ({ ...prev, phone }));
        setCurrentStep(prev => prev + 1);
    };

    const handleCompanyComplete = (data: { rut: string; name: string }) => {
        setFormData(prev => ({
            ...prev,
            companyRut: data.rut,
            companyName: data.name,
            company_rut: data.rut
        }));
        setCurrentStep(prev => prev + 1);
        setCompanyFound(true);
    };

    const handlePasswordComplete = (password: string) => {
        setFormData(prev => ({ ...prev, password }));
        // Final step completed - handle signup completion
    };

    const getStepTitle = () => {
        switch (currentStep) {
            case 1:
                return '¿Cómo te llamas?';
            case 2:
                return '¿Cuál es tu email?';
            case 3:
                return '¿Cuál es tu teléfono?';
            case 4:
                return '¿Cuál es el RUT de tu empresa?';
            case 5:
                return 'Crea tu contraseña';
            default:
                return '';
        }
    };

    const getStepSubtitle = () => {
        switch (currentStep) {
            case 1:
                return 'Para personalizar tu experiencia';
            case 2:
                return 'Lo usarás para iniciar sesión';
            case 3:
                return 'Para mantenerte informado sobre tus facturas';
            case 4:
                return 'Para configurar tu facturación';
            case 5:
                return 'Asegura tu cuenta';
            default:
                return '';
        }
    };

    const getCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return 'name';
            case 2:
                return 'email';
            case 3:
                return 'phone';
            case 4:
                return 'company';
            case 5:
                return 'password';
            default:
                return 'name';
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <NameStep
                        onComplete={handleNameComplete}
                        initialValue={{ firstName: formData.firstName, lastName: formData.lastName }}
                    />
                );
            case 2:
                return (
                    <EmailStep
                        onComplete={handleEmailComplete}
                        initialValue={formData.email}
                    />
                );
            case 3:
                return (
                    <PhoneStep
                        onComplete={handlePhoneComplete}
                        initialValue={formData.phone}
                    />
                );
            case 4:
                return (
                    <CompanyRutStep
                        onComplete={handleCompanyComplete}
                        initialValue={formData.companyRut}
                    />
                );
            case 5:
                return (
                    <PasswordStep
                        onComplete={handlePasswordComplete}
                        formData={formData}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-green-50 to-white relative overflow-hidden">
            <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-50">
                <div className="container mx-auto px-4 py-3">
                    <Link
                        to="/"
                        className="flex items-center space-x-2 hover:opacity-80 transition-opacity"
                        onClick={() => {
                            localStorage.removeItem('onboarding_session_id');
                            localStorage.removeItem('userName');
                        }}
                    >
                        <HandshakeIcon className="h-8 w-8 text-green-600" />
                        <span className="text-2xl font-bold">Pana<span className="text-green-600">.cl</span></span>
                    </Link>
                </div>
            </header>

            <div className="container mx-auto px-4 py-8 pt-24">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Left Column - Onboarding Form */}
                    <div className="md:pr-6">

                        <OnboardingLayout
                            currentStep={currentStep}
                            title={getStepTitle()}
                            subtitle={getStepSubtitle()}
                            step={getCurrentStep()}
                        >
                            {renderStep()}
                        </OnboardingLayout>
                    </div>

                    {/* Right Column - Pana Assistant */}
                    <div className="md:pl-6 relative">
                        <div className="sticky top-32">
                            <PanaAssistant
                                message={panaMessages[getCurrentStep() as keyof typeof panaMessages]}
                                step={getCurrentStep()}
                                verificationSent={verificationSent}
                                companyFound={companyFound}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}