import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvoiceDraft } from './InvoiceDraft';
import { FormData } from './types';
import { api } from '../../../services/api';
import { ArrowLeft } from 'lucide-react';
import { InvoiceSteps } from './InvoiceSteps';
import { InvoiceSignature } from './InvoiceSignature';
import { Link } from 'react-router-dom';
import { useEntity } from '../../../context/EntityContext';
import { createDraftDocument } from '../../../requests/draft';

// Tipos necesarios
interface CustomerData {
    receiver_rut: string;
    receiver_business_name: string;
    receiver_address: string;
    receiver_district: string;
    receiver_city: string;
    receiver_business_activity: string;
    receiver_activity_code: string;
    receiver_contact: string;
    master_entity_id: string;
}

interface CustomFormData extends Omit<FormData, 'customer'> {
    customer: Omit<CustomerData, 'master_entity_id'> & {
        master_entity_id?: string;
    };
}

interface RequestData {
    date_issued: string;
    purchase_transaction_type: string;
    sale_transaction_type: string;
    payment_method: string;
    due_date: string;
    dte_type: number;
    receiver_rut: string;
    receiver_business_name: string;
    receiver_business_activity: string;
    receiver_contact: string;
    receiver_address: string;
    receiver_district: string;
    receiver_city: string;
    total: number;
    details: Array<{
        item_name: string;
        item_description: string;
        quantity: number;
        unit_price: number;
        item_total: number;
        item_code: string;
        item_type_code: string;
        unit: string;
        discount_percent: number;
        other_tax: string;
    }>;
    references: Array<{
        id: string;
        reference_type: string;
        reference_folio: string;
        reference_date: string;
        reference_reason: string;
    }>;
}

interface InvoiceCreationProps {
    onBack: () => void;
}

interface InvoiceDraftPropsData {
    onSubmit: (data: RequestData) => void;
    isLoading?: boolean;
    initialData?: CustomFormData;
}

export function InvoiceCreation({ onBack }: InvoiceCreationProps) {
    const [pdfData, setPdfData] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { state: entityState } = useEntity();

    // Efecto para limpiar localStorage si hay datos malformados
    useEffect(() => {
        try {
            const savedFormData = localStorage.getItem('invoiceFormData');
            if (savedFormData) {
                const parsedData = JSON.parse(savedFormData);

                // Verificar si los datos tienen la estructura básica necesaria
                if (!parsedData.customer && !parsedData.issuer && !parsedData.details &&
                    !parsedData.items && !parsedData.references &&
                    (parsedData.selectedAddress || parsedData.selectedActivity)) {
                    // Datos con estructura antigua/incorrecta pero que pueden ser migrados
                    console.log('Datos con estructura antigua detectados, se migrarán al nuevo formato');
                    // No limpiamos el localStorage, permitimos que la migración ocurra
                } else if (!parsedData.customer && !parsedData.issuer && !parsedData.details) {
                    // Datos inválidos o incompatibles, limpiamos localStorage
                    console.warn('Datos en formato incorrecto detectados en localStorage, limpiando...');
                    localStorage.removeItem('invoiceFormData');
                }
            }
        } catch (error) {
            console.error('Error al verificar localStorage, limpiando datos:', error);
            localStorage.removeItem('invoiceFormData');
        }
    }, []);

    const [formData, setFormData] = useState<CustomFormData>(() => {
        const savedFormData = localStorage.getItem('invoiceFormData');
        console.log('Datos recuperados del localStorage en InvoiceCreation:', savedFormData);

        // Estructura base por defecto
        const defaultFormData: CustomFormData = {
            issuer: {
                name: '',
                address: '',
                district: '',
                city: '',
                saleType: '1',
                email: '',
                phone: '',
                businessLine: '',
                economicActivity: ''
            },
            customer: {
                receiver_rut: '',
                receiver_business_name: '',
                receiver_address: '',
                receiver_district: '',
                receiver_city: '',
                receiver_business_activity: '',
                receiver_activity_code: '',
                receiver_contact: ''
            },
            details: {
                issueDate: new Date().toISOString().split('T')[0],
                documentType: '33'
            },
            items: [],
            references: []
        };

        if (savedFormData) {
            try {
                const parsedData = JSON.parse(savedFormData);
                console.log('Datos parseados en InvoiceCreation:', parsedData);

                // Verificar si los datos tienen la estructura esperada
                if (parsedData.customer && typeof parsedData.customer === 'object') {
                    // Los datos tienen la estructura correcta
                    return parsedData;
                } else if (parsedData.selectedAddress || parsedData.selectedActivity) {
                    // Los datos tienen una estructura diferente, convertirlos al formato esperado
                    console.log('Datos con estructura diferente, adaptando al formato esperado');

                    // Si hay datos de dirección, mapearlos a la estructura esperada
                    if (parsedData.selectedAddress) {
                        defaultFormData.customer.receiver_address = parsedData.selectedAddress.address || '';

                        if (parsedData.selectedAddress.district) {
                            defaultFormData.customer.receiver_district = parsedData.selectedAddress.district.name || '';

                            if (parsedData.selectedAddress.district.city) {
                                defaultFormData.customer.receiver_city = parsedData.selectedAddress.district.city.name || '';
                            }
                        }
                    }

                    // Si hay datos de actividad económica, mapearlos
                    if (parsedData.selectedActivity) {
                        defaultFormData.customer.receiver_business_activity = parsedData.selectedActivity.name || '';
                        defaultFormData.customer.receiver_activity_code = parsedData.selectedActivity.code || '';
                    }

                    return defaultFormData;
                }

                return defaultFormData;
            } catch (error) {
                console.error('Error al parsear datos del localStorage:', error);
                return defaultFormData;
            }
        }

        return defaultFormData;
    });

    useEffect(() => {
        if (formData) {
            console.log('Guardando datos en localStorage:', formData);
            localStorage.setItem('invoiceFormData', JSON.stringify(formData));
        }
    }, [formData]);

    const getCurrentStep = () => {
        switch (location.pathname) {
            case '/platform/invoice-form':
                return 1;
            case '/platform/invoice-preview':
                return 2;
            case '/platform/invoice-signature':
                return 3;
            default:
                return 1;
        }
    };

    const renderPdfPreview = (pdfData: unknown) => {
        console.log('Intentando renderizar PDF con datos:', pdfData);

        if (typeof pdfData !== 'string') {
            console.error('PDF data no es un string:', pdfData);
            return (
                <div className="text-red-600 p-4 bg-red-50 rounded-md">
                    Error: El PDF recibido no tiene el formato correcto
                </div>
            );
        }

        const pdfPrefix = 'data:application/pdf;base64,';
        const pdfSource = pdfData.startsWith(pdfPrefix)
            ? pdfData
            : `${pdfPrefix}${pdfData}`;

        console.log('URL del PDF generada:', pdfSource.substring(0, 100) + '...');

        return (
            <div className="flex flex-col space-y-4">
                <iframe
                    src={pdfSource}
                    className="w-full h-[800px] border-0"
                    title="Preview PDF"
                />
                <div className="flex justify-end">
                    <div className="flex justify-end pt-6 border-t">
                        <Link
                            to="/platform/invoice-signature"
                            className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors disabled:bg-green-400 flex items-center space-x-2"
                        >
                            {isLoading ? 'Cargando Último Paso...' : 'Último Paso: Firmar y Emitir'}
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    const renderStepContent = () => {
        switch (location.pathname) {
            case '/platform/invoice-form':
                return (
                    <InvoiceDraft
                        onSubmit={handleDraftSubmit}
                        isLoading={isLoading}
                        initialData={formData as FormData}
                    />
                );
            case '/platform/invoice-preview':
                return (
                    <div className="space-y-6">
                        <h3 className="text-lg font-medium">Previsualización de Factura</h3>
                        {isLoading ? (
                            <div className="flex justify-center py-4">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                            </div>
                        ) : error ? (
                            <div className="text-red-600 p-4 bg-red-50 rounded-md">
                                {error}
                            </div>
                        ) : pdfData ? (
                            renderPdfPreview(pdfData)
                        ) : (
                            <div className="space-y-4">
                                <p className="text-red-600">No hay previsualización disponible</p>
                            </div>
                        )}
                    </div>
                );
            case '/platform/invoice-signature':
                return (
                    <InvoiceSignature
                        data={{
                            customer: {
                                receiver_rut: formData.customer?.receiver_rut || '',
                                receiver_business_name: formData.customer?.receiver_business_name || '',
                                receiver_address: formData.customer?.receiver_address || '',
                                receiver_district: formData.customer?.receiver_district || '',
                                receiver_city: formData.customer?.receiver_city || ''
                            },
                            date_issued: formData.date_issued || '',
                            details: formData.details || {},
                            issuer: formData.issuer || {},
                            items: (formData.items || []).map(item => ({
                                item_name: item.item_name || '',
                                quantity: item.quantity || 0,
                                unit_price: item.unit_price || 0,
                                item_total: item.item_total || 0
                            })),
                            references: (formData.references || []).map(ref => ({
                                reference_type: ref.reference_type || '',
                                reference_folio: ref.reference_folio || '',
                                reference_date: ref.reference_date || '',
                                reference_reason: ref.reference_reason || ''
                            }))
                        }}
                        onSubmit={handleSignAndIssue}
                        isLoading={isLoading}
                    />
                );
            default:
                return null;
        }
    };
    const handleDraftSubmit = async (draftData: RequestData) => {
        console.log('Formulario al enviar para previsualización:', draftData);
        console.log('Actividad económica al enviar:', draftData.receiver_business_activity);
        console.log('Items recibidos de draftData:', draftData.details);

        setIsLoading(true);
        setError(null);
        try {
            // Siempre usar la entidad del contexto
            if (!entityState.selectedEntity) {
                throw new Error('No hay entidad seleccionada');
            }

            // Asegúrate de que formData tenga la estructura correcta
            const currentFormData = { ...formData };
            if (!currentFormData.customer) {
                currentFormData.customer = {
                    receiver_rut: '',
                    receiver_business_name: '',
                    receiver_address: '',
                    receiver_district: '',
                    receiver_city: '',
                    receiver_business_activity: '',
                    receiver_activity_code: '',
                    receiver_contact: ''
                };
            }

            // Convertimos los datos del formulario al formato esperado por CustomFormData
            const formDataToSave: CustomFormData = {
                ...currentFormData,
                customer: {
                    ...currentFormData.customer,
                    receiver_rut: draftData.receiver_rut,
                    receiver_business_name: draftData.receiver_business_name,
                    // Asegurarnos de preservar la actividad económica
                    receiver_business_activity: draftData.receiver_business_activity || currentFormData.customer.receiver_business_activity || '',
                    // Manejar receiver_activity_code solo si existe en los tipos
                    receiver_activity_code: currentFormData.customer.receiver_activity_code || '',
                    receiver_address: draftData.receiver_address,
                    receiver_district: draftData.receiver_district,
                    receiver_city: draftData.receiver_city,
                    receiver_contact: draftData.receiver_contact
                },
                details: {
                    ...currentFormData.details,
                    issueDate: draftData.date_issued
                },
                items: draftData.details || [],
                references: draftData.references || [],
                date_issued: draftData.date_issued
            };

            console.log('Datos guardados con actividad económica:', formDataToSave.customer.receiver_business_activity);
            console.log('Items guardados en formDataToSave:', formDataToSave.items);

            setFormData(formDataToSave);
            localStorage.setItem('invoiceFormData', JSON.stringify(formDataToSave));

            // Para la llamada a la API, adaptamos los datos al formato esperado
            const adaptedData = {
                details: formDataToSave.details,
                customer: formDataToSave.customer,
                items: formDataToSave.items,
                references: formDataToSave.references,
                issuer: formDataToSave.issuer
            };

            console.log('Datos adaptados para API con actividad económica:', adaptedData.customer.receiver_business_activity);
            console.log('Items enviados a createDraftDocument:', adaptedData.items);

            const data = await createDraftDocument(adaptedData as any, entityState.selectedEntity.id);

            if (data.pdf_data) {
                console.log('PDF recibido, longitud:', data.pdf_data.length);
                setPdfData(data.pdf_data);
                navigate('/platform/invoice-preview');
            } else {
                console.error('No se recibió PDF en la respuesta:', data);
                throw new Error('No se recibió el PDF');
            }
        } catch (error) {
            console.error('Error al crear borrador:', error);
            setError('Error al generar la vista previa');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        console.log('Volviendo al paso anterior, formData:', formData);
        // No eliminamos los datos del localStorage al volver
        onBack();
    };

    const handleSignAndIssue = async (certificatePassword: string) => {
        setIsLoading(true);
        setError(null);
        try {
            if (!entityState.selectedEntity?.id) {
                throw new Error('No hay entidad seleccionada');
            }
            const draftDocumentId = localStorage.getItem('draftDocumentId');

            if (!draftDocumentId) {
                throw new Error('No se encontró la información necesaria');
            }

            // Preparamos el cuerpo de la solicitud para el endpoint batch-issue
            const requestBody = {
                document_ids: [draftDocumentId], // Enviamos el draft_document_id como parte de document_ids
                digital_certificate_password: certificatePassword
            };

            console.log('Enviando solicitud de emisión a batch-issue:', requestBody);

            try {
                // Utilizamos el endpoint batch-issue
                const response = await api.post(`/master-entities/${entityState.selectedEntity.id}/documents/batch-issue/`, requestBody);

                // Verificamos la respuesta
                const data = await response.json();
                console.log('Respuesta de emisión batch-issue:', data);

                // Verificamos si el proceso fue exitoso
                if (data && (data.success || (data.results && data.results.length > 0))) {
                    // Obtenemos el resultado del primer documento (ya que solo enviamos uno)
                    const documentResult = data.results?.[0] || {};

                    if (documentResult.error) {
                        throw new Error(documentResult.error);
                    }

                    // Limpiar el localStorage
                    localStorage.removeItem('draftDocumentId');
                    localStorage.removeItem('invoiceFormData');

                    return {
                        success: true,
                        pdfUrl: documentResult.pdf_url,
                        pdfContent: documentResult.pdf_data,
                        folio: documentResult.folio,
                        receiverName: formData.customer.receiver_business_name
                    };
                } else {
                    // Mostrar mensaje de error específico
                    const errorMessage = data.error || 'El documento no se pudo emitir correctamente';
                    throw new Error(errorMessage);
                }
            } catch (apiError: any) {
                // Capturar errores específicos de la API
                console.error('Error al realizar la solicitud API:', apiError);

                let errorMessage = 'Error al comunicarse con el servidor';
                if (apiError.message) {
                    errorMessage = apiError.message;
                } else if (apiError.response) {
                    try {
                        const errorData = await apiError.response.json();
                        errorMessage = errorData.error || 'Error en la respuesta del servidor';
                    } catch (e) {
                        errorMessage = `Error del servidor: ${apiError.response.status}`;
                    }
                }

                throw new Error(errorMessage);
            }
        } catch (error: any) {
            console.error('Error al emitir documento:', error);
            setError(error.message || 'Error al emitir el documento');
            return {
                success: false,
                error: error.message || 'Error desconocido'
            };
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg">
            <div className="p-6 border-b border-gray-200">
                <div className="relative flex items-center">
                    <div className="absolute left-0">
                        <button
                            onClick={handleBack}
                            className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 transition-colors"
                        >
                            <ArrowLeft className="h-5 w-5" />
                            <span>Volver</span>
                        </button>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <InvoiceSteps currentStep={getCurrentStep()} mode="single" />
                    </div>
                </div>
            </div>

            <div className="p-6">
                {renderStepContent()}
            </div>
        </div>
    );
}