import React from 'react';

export function FormatExplanation() {
    return (
        <div className="space-y-4">
            <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-md">
                <h5 className="font-medium text-yellow-800 mb-2">Notas Importantes:</h5>
                <ul className="list-disc list-inside space-y-2 text-sm text-yellow-700">
                    <li>La primera fila de cada factura debe contener todos los datos del emisor y receptor</li>
                    <li>Las filas adicionales de la misma factura deben dejar en blanco los datos del emisor y receptor</li>
                    <li>El número de filas por factura será igual al máximo entre la cantidad de detalles y referencias</li>
                    <li>Si un detalle no tiene referencia asociada, las columnas de referencia deben quedar vacías</li>
                    <li>El RUT del receptor debe ir sin puntos y con guion (ej: 12345678-9)</li>
                    <li>La fecha debe ir en formato AAAA-MM-DD</li>
                    <li>Los precios y totales deben ir sin puntos ni símbolos</li>
                </ul>
            </div>

            <div className="p-4 bg-blue-50 border border-blue-200 rounded-md">
                <h5 className="font-medium text-blue-800 mb-2">Ejemplos de Distribución:</h5>
                <ul className="list-disc list-inside space-y-2 text-sm text-blue-700">
                    <li>Si una factura tiene 2 detalles, usará 2 filas:
                        <ul className="list-circle list-inside ml-4 mt-1">
                            <li>Fila 1: Datos emisor/receptor + Detalle 1 + Referencia (si existe)</li>
                            <li>Fila 2: Datos en blanco + Detalle 2 + Sin referencia</li>
                        </ul>
                    </li>
                    <li>Si una factura tiene 1 detalle y 1 referencia, usará 1 fila:
                        <ul className="list-circle list-inside ml-4 mt-1">
                            <li>Fila 1: Datos emisor/receptor + Detalle 1 + Referencia 1</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}