import React, { useState, useRef, useEffect } from 'react';
import { Package, Plus, Search, Check, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { useEntity } from '../../../../context/EntityContext';
import { getProductList, Product, APIProduct } from '../../../../requests/product-list';

interface ProductListProps {
    products: Product[];
    onChange: (products: Product[]) => void;
    customer?: {
        receiver_rut: string;
        master_entity_id?: string;
    };
}

export function ProductList({ products, onChange, customer }: ProductListProps) {
    const [productHistory, setProductHistory] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const { state: entityState } = useEntity();

    const mapAPIProductToProduct = (apiProduct: APIProduct, index: number): Product => ({
        id: `product-${Date.now()}-${index}-${Math.random().toString(36).substr(2, 9)}`,
        code: apiProduct.item_code || '',
        item_name: apiProduct.item_name,
        item_description: apiProduct.item_description,
        price: apiProduct.last_price ? parseFloat(apiProduct.last_price) : 0,
        quantity: apiProduct.last_quantity ? parseFloat(apiProduct.last_quantity) : 1,
        unit_price: apiProduct.last_price ? parseFloat(apiProduct.last_price) : 0,
        item_total: apiProduct.last_price && apiProduct.last_quantity
            ? parseFloat(apiProduct.last_price) * parseFloat(apiProduct.last_quantity)
            : 0,
        count: apiProduct.count,
        last_price: apiProduct.last_price,
        last_quantity: apiProduct.last_quantity
    });

    const toggleDescription = (id: string) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    useEffect(() => {
        const fetchProducts = async () => {
            if (!entityState.selectedEntity?.id) {
                setError('No hay entidad seleccionada');
                return;
            }

            setIsLoading(true);
            setError(null);

            try {
                const apiProducts = await getProductList(
                    entityState.selectedEntity.id,
                    customer?.master_entity_id
                );
                const mappedProducts = apiProducts.map(mapAPIProductToProduct);
                setProductHistory(mappedProducts);
            } catch (err) {
                console.error('Error fetching products:', err);
                setError('Error al cargar los productos');
                setProductHistory([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, [entityState.selectedEntity?.id, customer?.master_entity_id]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredProducts = productHistory.filter(product => {
        const searchLower = searchTerm.toLowerCase();
        return (
            product.item_name.toLowerCase().includes(searchLower) ||
            product.code.toLowerCase().includes(searchLower)
        );
    }).sort((a, b) => (b.count || 0) - (a.count || 0));

    const handleAddBlankProduct = () => {
        const newProduct: Product = {
            id: `blank-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
            code: '',
            item_name: 'Nuevo Producto',
            price: 0,
            quantity: 1,
            discount: 0,
            unit_price: 0,
            item_total: 0,
            item_description: ''
        };
        console.log('Agregando producto en blanco:', newProduct);
        onChange([...products, newProduct]);
    };

    const handleProductSelect = (product: Product) => {
        const uniqueId = `selected-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        setSelectedProduct(uniqueId);

        // Asegurarnos de que los valores numéricos sean siempre números válidos
        const price = product.last_price ? parseFloat(product.last_price) : 0;
        const quantity = product.last_quantity ? parseFloat(product.last_quantity) : 1;
        const total = price * quantity;

        // Validar que los valores son números válidos
        const validPrice = isNaN(price) ? 0 : price;
        const validQuantity = isNaN(quantity) || quantity <= 0 ? 1 : quantity;
        const validTotal = isNaN(total) ? 0 : total;

        const newProduct = {
            ...product,
            id: uniqueId,
            price: validPrice,
            unit_price: validPrice,
            quantity: validQuantity,
            item_total: validTotal,
            // Asegurarse de que item_name siempre existe y es un string
            item_name: product.item_name || "Producto sin nombre",
            discount: 0
        };

        console.log('Nuevo producto seleccionado:', newProduct);
        console.log('Valores numéricos del producto:', {
            price: validPrice,
            quantity: validQuantity,
            unit_price: validPrice,
            total: validTotal
        });

        onChange([...products, newProduct]);

        // Si tiene descripción, expandir automáticamente la fila
        if (product.item_description) {
            setExpandedRows(prev => new Set([...prev, uniqueId]));
        }

        setTimeout(() => {
            setShowDropdown(false);
            setSearchTerm('');
            setSelectedProduct(null);
        }, 500);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setShowDropdown(true);
    };

    const calculateSubtotal = (product: Product) => {
        // El total del ítem es simplemente cantidad * precio unitario
        return Math.round(product.quantity * product.unit_price);
    };

    const calculateDiscountAmount = (product: Product) => {
        const subtotal = calculateSubtotal(product);
        return product.discount ? Math.round((subtotal * product.discount) / 100) : 0;
    };

    const calculateTotal = (product: Product) => {
        // El total final es el subtotal menos el descuento
        const subtotal = calculateSubtotal(product);
        const discountAmount = calculateDiscountAmount(product);
        return subtotal - discountAmount;
    };

    const getTotalDiscounts = () => {
        return products.reduce((sum, product) => {
            return sum + calculateDiscountAmount(product);
        }, 0);
    };

    const getTotalNeto = () => {
        return products.reduce((sum, product) => sum + calculateTotal(product), 0);
    };

    const getIVA = () => {
        const totalNeto = getTotalNeto();
        return Math.round(totalNeto * 0.19);
    };

    const getTotalConIVA = () => {
        const totalNeto = getTotalNeto();
        const iva = Math.round(totalNeto * 0.19);
        return totalNeto + iva;
    };

    const updateProduct = (id: string, field: keyof Product, value: string | number) => {
        console.log('Actualizando producto:', { id, field, value });
        const updatedProducts = products.map(product => {
            if (product.id === id) {
                // Convertir valores a números válidos para los campos numéricos
                let numericValue: number | string = value;

                if (field === 'quantity' || field === 'price' || field === 'discount') {
                    // Asegurar que los valores numéricos son válidos
                    if (typeof value === 'string') {
                        const parsed = parseFloat(value);
                        numericValue = isNaN(parsed) ? 0 : parsed;
                    } else {
                        numericValue = isNaN(value) ? 0 : value;
                    }
                }

                // Para campos string, asegurar que nunca sean undefined
                const stringValue = field === 'code' || field === 'item_name' || field === 'item_description'
                    ? String(value || '')
                    : numericValue;

                const updatedProduct = {
                    ...product,
                    [field]: stringValue,
                };

                // Actualizar campos calculados
                if (field === 'quantity' || field === 'price' || field === 'discount') {
                    const quantity = field === 'quantity'
                        ? (typeof numericValue === 'number' ? numericValue : 0)
                        : product.quantity || 0;

                    const price = field === 'price'
                        ? (typeof numericValue === 'number' ? numericValue : 0)
                        : product.price || 0;

                    const discount = field === 'discount'
                        ? (typeof numericValue === 'number' ? numericValue : 0)
                        : (product.discount || 0);

                    // Asegurar que los valores son siempre números válidos
                    updatedProduct.quantity = isNaN(quantity) ? 0 : quantity;
                    updatedProduct.price = isNaN(price) ? 0 : price;
                    updatedProduct.unit_price = isNaN(price) ? 0 : price;
                    updatedProduct.discount = isNaN(discount) ? 0 : discount;

                    // El total del ítem es cantidad * precio unitario
                    const total = Math.round(updatedProduct.quantity * updatedProduct.price);
                    updatedProduct.item_total = isNaN(total) ? 0 : total;

                    // Validación extra - asegurar que item_name existe
                    if (!updatedProduct.item_name) {
                        updatedProduct.item_name = 'Producto sin nombre';
                    }
                }

                console.log('Producto actualizado:', updatedProduct);
                return updatedProduct;
            }
            return product;
        });
        onChange(updatedProducts);
    };

    const removeProduct = (id: string) => {
        const updatedProducts = products.filter(product => product.id !== id);
        onChange(updatedProducts);
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <Package className="h-5 w-5 text-green-600" />
                    <h3 className="text-lg font-medium">Productos y Servicios</h3>
                </div>
                <button
                    type="button"
                    onClick={handleAddBlankProduct}
                    className="flex items-center space-x-1 text-green-600 hover:text-green-700"
                >
                    <Plus className="h-4 w-4" />
                    <span>Agregar Línea</span>
                </button>
            </div>

            {error && (
                <div className="text-red-600 text-sm bg-red-50 p-2 rounded">
                    {error}
                </div>
            )}

            <div className="relative" ref={dropdownRef}>
                <div className="relative">
                    <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Buscar por código o nombre..."
                        value={searchTerm}
                        onChange={handleSearch}
                        onFocus={() => setShowDropdown(true)}
                        className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        disabled={isLoading}
                    />
                </div>

                {showDropdown && filteredProducts.length > 0 && !isLoading && (
                    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                        {filteredProducts.map((product) => (
                            <button
                                key={product.id}
                                type="button"
                                onClick={() => handleProductSelect(product)}
                                className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none transition-colors duration-200"
                            >
                                <div className="flex justify-between items-center">
                                    <div>
                                        <span className="font-medium">{product.item_name}</span>
                                        <div className="flex items-center space-x-2">
                                            <p className="text-sm text-gray-500">Código: {product.code || 'Sin código'}</p>
                                            {product.count !== undefined && product.count > 0 && (
                                                <p className="text-sm italic text-gray-500">
                                                    - has facturado {product.count} {product.count === 1 ? 'vez' : 'veces'}
                                                </p>
                                            )}
                                        </div>
                                        {(product.last_price || product.last_quantity || product.item_description) && (
                                            <div className="text-sm text-gray-600 mt-1">
                                                {product.last_price && (
                                                    <span className="mr-3">Último precio: ${parseFloat(product.last_price).toLocaleString('es-CL')}</span>
                                                )}
                                                {product.last_quantity && (
                                                    <span>Última cantidad: {product.last_quantity}</span>
                                                )}
                                                {product.item_description && (
                                                    <p className="text-sm text-gray-500 mt-1 italic">
                                                        {product.item_description}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {selectedProduct === product.id && (
                                        <Check className="h-5 w-5 text-green-500 animate-fadeIn" />
                                    )}
                                </div>
                            </button>
                        ))}
                    </div>
                )}

                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-green-600"></div>
                    </div>
                )}
            </div>

            {/* Lista de productos seleccionados */}
            {products.length > 0 && (
                <div className="mt-4">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Código</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Cantidad</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Precio</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total Ítem</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Descuento %</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Monto Desc.</th>
                                <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total Final</th>
                                <th className="px-4 py-3"></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {products.map((product) => (
                                <React.Fragment key={product.id}>
                                    <tr>
                                        <td className="px-4 py-3">
                                            <textarea
                                                value={product.code || ''}
                                                onChange={(e) => updateProduct(product.id, 'code', e.target.value)}
                                                className="w-20 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
                                                placeholder="Código"
                                                rows={1}
                                                style={{ minHeight: '31px' }}
                                                onInput={(e) => {
                                                    e.currentTarget.style.height = 'auto';
                                                    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-3 w-full">
                                            <div className="space-y-2">
                                                <div className="flex items-center space-x-2">
                                                    <textarea
                                                        value={product.item_name || ''}
                                                        onChange={(e) => updateProduct(product.id, 'item_name', e.target.value)}
                                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
                                                        placeholder="Nombre"
                                                        rows={1}
                                                        style={{ minHeight: '31px' }}
                                                        onInput={(e) => {
                                                            e.currentTarget.style.height = 'auto';
                                                            e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
                                                        }}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => toggleDescription(product.id)}
                                                        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                                    >
                                                        {expandedRows.has(product.id) ? (
                                                            <ChevronUp className="h-4 w-4 text-gray-500" />
                                                        ) : (
                                                            <ChevronDown className="h-4 w-4 text-gray-500" />
                                                        )}
                                                    </button>
                                                </div>
                                                {expandedRows.has(product.id) && (
                                                    <div className="pl-0">
                                                        <textarea
                                                            value={product.item_description || ''}
                                                            onChange={(e) => updateProduct(product.id, 'item_description', e.target.value)}
                                                            className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                                                            placeholder="Agregar descripción adicional..."
                                                            rows={2}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="number"
                                                value={product.quantity || 0}
                                                onChange={(e) => updateProduct(product.id, 'quantity', e.target.value)}
                                                className="w-16 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                min="1"
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="number"
                                                value={product.price || 0}
                                                onChange={(e) => updateProduct(product.id, 'price', e.target.value)}
                                                className="w-24 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                min="0"
                                            />
                                        </td>
                                        <td className="px-4 py-3 text-right">
                                            ${calculateSubtotal(product).toLocaleString('es-CL')}
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="number"
                                                value={product.discount || 0}
                                                onChange={(e) => updateProduct(product.id, 'discount', e.target.value)}
                                                className="w-16 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-right"
                                                min="0"
                                                max="100"
                                            />
                                        </td>
                                        <td className="px-4 py-3 text-right">
                                            ${calculateDiscountAmount(product).toLocaleString('es-CL')}
                                        </td>
                                        <td className="px-4 py-3 text-right">
                                            ${calculateTotal(product).toLocaleString('es-CL')}
                                        </td>
                                        <td className="px-4 py-3">
                                            <button
                                                type="button"
                                                onClick={() => removeProduct(product.id)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <Trash2 className="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                        {products.length > 0 && (
                            <tfoot>
                                <tr className="bg-gray-50">
                                    <td colSpan={5} className="px-4 py-3 text-right font-medium">
                                        Total Descuentos:
                                    </td>
                                    <td className="px-4 py-3 text-right font-medium">
                                        ${getTotalDiscounts().toLocaleString('es-CL')}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="bg-gray-50">
                                    <td colSpan={5} className="px-4 py-3 text-right font-medium">
                                        Total Neto:
                                    </td>
                                    <td className="px-4 py-3 text-right font-medium">
                                        ${getTotalNeto().toLocaleString('es-CL')}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="bg-gray-50">
                                    <td colSpan={5} className="px-4 py-3 text-right font-medium">
                                        IVA (19%):
                                    </td>
                                    <td className="px-4 py-3 text-right font-medium">
                                        ${getIVA().toLocaleString('es-CL')}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="bg-gray-50 border-t border-gray-200">
                                    <td colSpan={5} className="px-4 py-3 text-right font-medium">
                                        Total con IVA:
                                    </td>
                                    <td className="px-4 py-3 text-right font-medium">
                                        ${getTotalConIVA().toLocaleString('es-CL')}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            )}
        </div>
    );
}