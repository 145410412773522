import { useState, useEffect, useRef, useCallback } from 'react';
import { Building2, ChevronDown } from 'lucide-react';
import { useEntity } from '../../context/EntityContext';

interface MasterEntity {
    id: string;
    name: string;
    tax_id: string;
}

export function EntitySelector() {
    const [isOpen, setIsOpen] = useState(false);
    const [entities, setEntities] = useState<MasterEntity[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { state, dispatch } = useEntity();
    const initializationRef = useRef(false);

    console.log('[EntitySelector] Rendering with state:', state);

    // Efecto para cargar entidades y sincronizar estado inicial
    useEffect(() => {
        console.log('[EntitySelector] Initial load effect running');
        const storedEntities = localStorage.getItem('masterEntities');
        if (storedEntities && !initializationRef.current) {
            console.log('[EntitySelector] Found stored entities:', storedEntities);
            const parsedEntities = JSON.parse(storedEntities);
            setEntities(parsedEntities);

            // Si no hay entidad seleccionada, seleccionamos la primera
            const selectedEntityId = localStorage.getItem('selectedEntityId');
            if (!selectedEntityId && parsedEntities.length > 0) {
                console.log('[EntitySelector] No entity selected, selecting first one:', parsedEntities[0]);
                const firstEntity = parsedEntities[0];
                localStorage.setItem('selectedEntityId', firstEntity.id);
                localStorage.setItem('selectedEntityName', firstEntity.name);
                dispatch({
                    type: "SET_ENTITY",
                    payload: {
                        id: firstEntity.id,
                        name: firstEntity.name
                    }
                });
            }
            initializationRef.current = true;
        }
    }, [dispatch]); // Solo se ejecuta una vez al montar el componente

    // Efecto separado para sincronizar con localStorage
    useEffect(() => {
        const selectedEntityId = localStorage.getItem('selectedEntityId');
        const selectedEntityName = localStorage.getItem('selectedEntityName');
        console.log('[EntitySelector] Checking localStorage sync:', { selectedEntityId, selectedEntityName, currentState: state.selectedEntity });

        if (selectedEntityId && selectedEntityName && !state.selectedEntity) {
            console.log('[EntitySelector] Syncing from localStorage to context');
            dispatch({
                type: "SET_ENTITY",
                payload: {
                    id: selectedEntityId,
                    name: selectedEntityName
                }
            });
        }
    }, [state.selectedEntity, dispatch]); // Solo se ejecuta una vez al montar el componente

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleEntitySelect = useCallback((entity: MasterEntity) => {
        console.log('[EntitySelector] Selecting entity:', entity);
        dispatch({
            type: "SET_ENTITY",
            payload: {
                id: entity.id,
                name: entity.name
            }
        });
        console.log('[EntitySelector] Entity selected, new state should be:', { id: entity.id, name: entity.name });
        setIsOpen(false);
    }, [dispatch]);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 px-3 py-2 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
            >
                <Building2 className="h-5 w-5 text-gray-400" />
                <span className="text-sm text-gray-700">
                    {state.selectedEntity ? state.selectedEntity.name : 'Seleccionar empresa'}
                </span>
                <ChevronDown className={`h-4 w-4 text-gray-400 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="absolute top-full right-0 mt-1 w-72 bg-white rounded-md shadow-lg border border-gray-200 z-50">
                    <div className="py-1">
                        {entities.map((entity) => (
                            <button
                                key={entity.id}
                                onClick={() => handleEntitySelect(entity)}
                                className="w-full text-left px-4 py-2 hover:bg-gray-50 flex flex-col"
                            >
                                <span className="font-medium text-gray-900">{entity.name}</span>
                                <span className="text-sm text-gray-500">{entity.tax_id}</span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}