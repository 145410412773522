import React from 'react';
import { Mail, Phone, Building2, Lock, Check, User } from 'lucide-react';

interface OnboardingStepperProps {
    currentStep: number;
}

export function OnboardingStepper({ currentStep }: OnboardingStepperProps) {
    const steps = [
        { icon: User, label: 'Nombre' },
        { icon: Mail, label: 'Email' },
        { icon: Phone, label: 'Teléfono' },
        { icon: Building2, label: 'Empresa' },
        { icon: Lock, label: 'Contraseña' }
    ];

    return (
        <div className="mb-8">
            <div className="relative">
                {/* Progress bar */}
                <div className="absolute top-1/2 left-0 w-full h-1 bg-gray-100 -translate-y-1/2">
                    <div
                        className="h-full bg-green-600 transition-all duration-500 rounded-full"
                        style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
                    />
                </div>

                {/* Steps */}
                <div className="relative flex justify-between">
                    {steps.map((step, index) => {
                        const Icon = step.icon;
                        const isCompleted = index < currentStep - 1;
                        const isCurrent = index === currentStep - 1;

                        return (
                            <div key={index} className="flex flex-col items-center">
                                <div
                                    className={`
                    w-12 h-12 rounded-full flex items-center justify-center 
                    transition-all duration-300 relative z-10
                    ${isCompleted
                                            ? 'bg-green-600 text-white ring-4 ring-green-100'
                                            : isCurrent
                                                ? 'bg-white text-green-600 ring-4 ring-green-100 border-2 border-green-600'
                                                : 'bg-white text-gray-400 border-2 border-gray-200'
                                        }
                  `}
                                >
                                    {isCompleted ? (
                                        <Check className="h-6 w-6 stroke-[3]" />
                                    ) : (
                                        <Icon className="h-6 w-6" />
                                    )}
                                </div>
                                <span
                                    className={`
                    mt-2 text-sm font-medium transition-colors duration-200
                    ${isCompleted
                                            ? 'text-green-600'
                                            : isCurrent
                                                ? 'text-gray-900'
                                                : 'text-gray-400'
                                        }
                  `}
                                >
                                    {step.label}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}