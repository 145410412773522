// src/components/layout/Sidebar.tsx
import React from 'react';
import { FileText, Users, Building2, Key, Bot } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

export function Sidebar() {
    const location = useLocation();
    const isTuPana = location.pathname === '/platform/your-pana';
    const isInvoicesSection = location.pathname.includes('/platform/invoices');
    const showCustomers = location.pathname.includes('/platform/customers');
    const isSettings = location.pathname.includes('/settings');

    return (
        <aside className="fixed left-0 top-0 h-screen w-64 bg-white shadow-lg pt-20 z-40">
            <nav className="p-4 space-y-2">
                {isTuPana && (
                    <Link
                        to="/platform/your-pana"
                        className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/platform/your-pana'
                            ? 'bg-green-50 text-green-600'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                            }`}
                    >
                        <Bot className="h-5 w-5" />
                        <span>Tu Pana de Facturación</span>
                    </Link>
                )}


                {isSettings && (
                    <>
                        <Link
                            to="/settings/sii-credentials"
                            className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/settings/sii-credentials'
                                ? 'bg-green-50 text-green-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                        >
                            <Key className="h-5 w-5" />
                            <span>Credenciales SII</span>
                        </Link>
                        <Link
                            to="/settings/company-users"
                            className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/settings/company-users'
                                ? 'bg-green-50 text-green-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                        >
                            <Users className="h-5 w-5" />
                            <span>Usuarios</span>
                        </Link>
                        <Link
                            to="/settings/company-details"
                            className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/settings/company-details'
                                ? 'bg-green-50 text-green-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                        >
                            <Building2 className="h-5 w-5" />
                            <span>Datos de mi Empresa</span>
                        </Link>
                    </>
                )}

                {isInvoicesSection && (
                    <>
                        <Link
                            to="/platform/invoices-issued"
                            className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/platform/invoices-issued'
                                ? 'bg-green-50 text-green-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                        >
                            <FileText className="h-5 w-5" />
                            <span>Facturas Emitidas</span>
                        </Link>
                        <Link
                            to="/platform/invoices-in-process"
                            className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/platform/invoices-in-process'
                                ? 'bg-green-50 text-green-600'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                        >
                            <FileText className="h-5 w-5" />
                            <span>Facturas en Proceso</span>
                        </Link>
                    </>
                )}

                {showCustomers && (
                    <Link
                        to="/platform/customers"
                        className={`flex items-center space-x-2 p-2 rounded-lg ${location.pathname === '/platform/customers'
                            ? 'bg-green-50 text-green-600'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                            }`}
                    >
                        <Users className="h-5 w-5" />
                        <span>Clientes</span>
                    </Link>
                )}
            </nav>
        </aside>
    );
}
