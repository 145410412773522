import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { FileSpreadsheet, RefreshCw, Clock, Download, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function ServicesLanding() {
    return (
        <div className="min-h-screen bg-white">
            <Header />
            <main>
                {/* Hero Section */}
                <section className="pt-24 pb-16 bg-gradient-to-br from-green-50 to-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-6xl mx-auto">
                            <div className="grid md:grid-cols-2 gap-12 items-center">
                                <div>
                                    <h1 className="text-5xl font-bold mb-6">
                                        Transforma tu planilla de clientes en facturas automáticas
                                    </h1>
                                    <p className="text-xl text-gray-600 mb-8">
                                        Con una simple plantilla de Excel, puedes almacenar tus clientes y facturarles en segundos.
                                        Ideal para servicios recurrentes.
                                    </p>
                                    <Link
                                        to="/signup"
                                        className="inline-flex items-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                    >
                                        <span>Descarga la plantilla y empieza a facturar</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </Link>
                                </div>

                                <div className="relative">
                                    {/* Client List Example */}
                                    <div className="absolute left-0 top-0 w-[85%] bg-white rounded-lg shadow-lg p-4 transform -translate-y-4 hover:translate-y-0 transition-transform duration-300">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="text-sm font-medium text-gray-600">Clientes Activos</div>
                                            <div className="text-sm text-gray-500">Marzo 2024</div>
                                        </div>
                                        <div className="space-y-3">
                                            <div className="p-2 bg-gray-50 rounded">
                                                <div className="text-sm font-medium">Empresa ABC SpA</div>
                                                <div className="text-xs text-gray-500">Servicio Mensual: $150.000</div>
                                            </div>
                                            <div className="p-2 bg-gray-50 rounded">
                                                <div className="text-sm font-medium">Consultora XYZ Ltda</div>
                                                <div className="text-xs text-gray-500">Mantención: $200.000</div>
                                            </div>
                                            <div className="p-2 bg-gray-50 rounded">
                                                <div className="text-sm font-medium">Servicios 123 SpA</div>
                                                <div className="text-xs text-gray-500">Arriendo: $300.000</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Generated Invoices */}
                                    <div className="absolute right-0 top-0 w-[85%] bg-white rounded-lg shadow-lg p-4 transform translate-y-4 hover:translate-y-0 transition-transform duration-300">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="text-sm font-medium text-green-600">Facturas Generadas</div>
                                            <div className="text-sm text-gray-500">15/03/2024</div>
                                        </div>
                                        <div className="space-y-3">
                                            <div className="p-2 bg-green-50 rounded">
                                                <div className="text-sm font-medium">Factura #1234</div>
                                                <div className="text-xs text-gray-600">Empresa ABC SpA</div>
                                                <div className="text-xs text-green-600">$150.000</div>
                                            </div>
                                            <div className="p-2 bg-green-50 rounded">
                                                <div className="text-sm font-medium">Factura #1235</div>
                                                <div className="text-xs text-gray-600">Consultora XYZ Ltda</div>
                                                <div className="text-xs text-green-600">$200.000</div>
                                            </div>
                                            <div className="p-2 bg-green-50 rounded">
                                                <div className="text-sm font-medium">Factura #1236</div>
                                                <div className="text-xs text-gray-600">Servicios 123 SpA</div>
                                                <div className="text-xs text-green-600">$300.000</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Features Grid */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <FileSpreadsheet className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Sube tu lista de clientes 1 sola vez
                                </h3>
                                <p className="text-gray-600">
                                    Carga los datos de tus clientes una vez y reutilízalos cada mes.
                                    Sin volver a escribir la misma información.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <RefreshCw className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Ideal para cobros mensuales
                                </h3>
                                <p className="text-gray-600">
                                    Perfecto para servicios recurrentes, mantenimientos o arriendos.
                                    Factura a todos tus clientes en un solo paso.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Clock className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Menos tiempo en facturación
                                </h3>
                                <p className="text-gray-600">
                                    Dedica más tiempo a tus servicios y menos a la administración.
                                    Automatiza lo repetitivo.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Download className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Compatible con cualquier sistema
                                </h3>
                                <p className="text-gray-600">
                                    Exporta tus datos desde cualquier software de gestión.
                                    Si puedes crear un Excel, puedes usar Pana.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Template Example Section */}
                <section className="py-16 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="max-w-5xl mx-auto">
                            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src="https://images.unsplash.com/photo-1554224155-6726b3ff858f?auto=format&fit=crop&w=1200&q=80"
                                    alt="Plantilla de Excel"
                                    className="w-full h-96 object-cover"
                                />
                                <div className="p-8">
                                    <h3 className="text-2xl font-bold mb-4">
                                        Plantilla simple y efectiva
                                    </h3>
                                    <div className="space-y-4">
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">1</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Datos del cliente</h4>
                                                <p className="text-gray-600">
                                                    RUT, razón social, dirección y giro. Los guardamos para que no tengas que
                                                    volver a escribirlos.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">2</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Servicios recurrentes</h4>
                                                <p className="text-gray-600">
                                                    Define tus servicios una vez y reutilízalos cada mes.
                                                    Actualiza solo los montos cuando sea necesario.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">3</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Facturación masiva</h4>
                                                <p className="text-gray-600">
                                                    Selecciona los clientes a facturar y emite todas las facturas de una vez.
                                                    Sin errores, sin demoras.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl mx-auto text-center">
                            <h2 className="text-3xl font-bold mb-6">
                                ¿Listo para automatizar tu facturación?
                            </h2>
                            <p className="text-xl text-gray-600 mb-8">
                                Descarga la plantilla y empieza a facturar más rápido hoy mismo.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                <Link
                                    to="/signup"
                                    className="inline-flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                >
                                    <span>Empezar prueba gratis</span>
                                    <ArrowRight className="h-5 w-5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}