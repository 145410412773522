// src/routes/index.tsx
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Landing from '../pages/Landing';
import { Login } from '../pages/Login';
import Signup from '../pages/Signup';
import { Platform } from '../pages/Platform';
import { InvoiceEmissionPage } from '../pages/InvoiceEmissionPage';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { Customers } from '../pages/customers/Customers';
import { BulkInvoiceEmissionPage } from '../pages/BulkInvoiceEmissionPage';
import { YourPanaInvoicer } from '../pages/YourPanaInvoicer';
import { SIICredentials } from '../pages/settings/SIICredentials';
import { CompanyDetails } from '../pages/settings/CompanyDetails';
import { CompanyUsers } from '../pages/settings/CompanyUsers';
import { SettingsLayout } from '../components/settings/SettingsLayout';
import { Key, Building2, Users } from 'lucide-react';
import RetailLanding from '../pages/solutions/RetailLanding';
import TechLanding from '../pages/solutions/TechLanding';
import ServicesLanding from '../pages/solutions/ServicesLanding';
import BlogIndex from '../pages/blog';
import BlogPostPage from '../pages/blog/[slug]';
import { ScrollRestoration } from 'react-router-dom';
import SolpiscinaCase from '../pages/success/Solpiscina';
import MontuCase from '../pages/success/Montu';
import { AppWrapper } from '../components/layout/AppWrapper';


export const router = createBrowserRouter([
  {
    path: '/blog/:slug',
    element: <AppWrapper><BlogPostPage /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/blog',
    element: <AppWrapper><BlogIndex /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/success/solpiscina',
    element: <AppWrapper><SolpiscinaCase /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/success/montu',
    element: <AppWrapper><MontuCase /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/login',
    element: <AppWrapper><Login /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/signup',
    element: <AppWrapper><Signup /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform',
    element: <AppWrapper><Navigate to="/platform/invoices-issued" replace /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/:section',
    element: <AppWrapper><ProtectedRoute><Platform /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/your-pana',
    element: <AppWrapper><ProtectedRoute><YourPanaInvoicer /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/invoice-form',
    element: <AppWrapper><ProtectedRoute><InvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/invoice-preview',
    element: <AppWrapper><ProtectedRoute><InvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/bulk-invoice-excel',
    element: <AppWrapper><ProtectedRoute><BulkInvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/bulk-invoice-preview',
    element: <AppWrapper><ProtectedRoute><BulkInvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/bulk-invoice-signature',
    element: <AppWrapper><ProtectedRoute><BulkInvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/settings',
    element: <AppWrapper><ProtectedRoute><Navigate to="/settings/sii-credentials" replace /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/settings/sii-credentials',
    element: <AppWrapper>
      <ProtectedRoute>
        <SettingsLayout
          title="Credenciales SII"
          description="Configura tus credenciales para conectar con el Servicio de Impuestos Internos"
          icon={Key}
        >
          <SIICredentials />
        </SettingsLayout>
      </ProtectedRoute>
      <ScrollRestoration />
    </AppWrapper>,
  },
  {
    path: '/settings/company-details',
    element: <AppWrapper>
      <ProtectedRoute>
        <SettingsLayout
          title="Datos de la Empresa"
          description="Administra la información de tu empresa"
          icon={Building2}
        >
          <CompanyDetails />
        </SettingsLayout>
      </ProtectedRoute>
      <ScrollRestoration />
    </AppWrapper>,
  },
  {
    path: '/settings/company-users',
    element: <AppWrapper>
      <ProtectedRoute>
        <SettingsLayout
          title="Usuarios de la Empresa"
          description="Gestiona los usuarios que tienen acceso a tu empresa"
          icon={Users}
        >
          <CompanyUsers />
        </SettingsLayout>
      </ProtectedRoute>
      <ScrollRestoration />
    </AppWrapper>,
  },
  {
    path: '/platform/customers',
    element: <AppWrapper><ProtectedRoute><Customers /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/platform/invoice-signature',
    element: <AppWrapper><ProtectedRoute><InvoiceEmissionPage /></ProtectedRoute><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/solutions/retail',
    element: <AppWrapper><RetailLanding /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/solutions/tech',
    element: <AppWrapper><TechLanding /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/solutions/services',
    element: <AppWrapper><ServicesLanding /><ScrollRestoration /></AppWrapper>,
  },
  {
    path: '/',
    element: <AppWrapper><Landing /><ScrollRestoration /></AppWrapper>,
  },
]);