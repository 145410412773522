import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { ArrowRight, CheckCircle, Clock, FileText, Users, Zap } from 'lucide-react';
import { CountryProvider } from '../../context/CountryContext';
import { ChatWidget } from '../../components/chat/ChatWidget';
import { Link } from 'react-router-dom';

export default function MontuCase() {
    return (
        <CountryProvider>
            <div className="min-h-screen bg-white">
                <Header />
                <main>
                    {/* Hero Section */}
                    <section className="pt-24 pb-16 bg-gradient-to-br from-green-50 to-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-6xl mx-auto">
                                <div className="grid md:grid-cols-2 gap-12 items-center">
                                    <div>
                                        <h1 className="text-5xl font-bold mb-6">
                                            Caso de Éxito:
                                            <img
                                                src="/montu-logo.png"
                                                alt="Montu Logo"
                                                className="h-16 mt-4"
                                            />
                                        </h1>
                                        <p className="text-xl text-gray-600 mb-8">
                                            La startup chilena que revoluciona el marketing de influencers con facturación automática
                                        </p>
                                        <div className="flex flex-col sm:flex-row gap-4">
                                            <Link
                                                to="/signup"
                                                className="inline-flex items-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                            >
                                                <span>Prueba Pana Gratis</span>
                                                <ArrowRight className="h-5 w-5" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&w=1200&q=80"
                                            alt="Marketing de influencers"
                                            className="rounded-2xl shadow-xl"
                                        />
                                        <div className="absolute -bottom-4 -right-4 bg-white rounded-xl shadow-lg p-4">
                                            <p className="text-sm font-medium text-green-600">Startup del año 2023</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* About Montu Section */}
                    <section className="py-16 bg-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-4xl mx-auto">
                                <div className="bg-gradient-to-br from-purple-50 to-white rounded-2xl p-8 mb-12">
                                    <h2 className="text-2xl font-bold mb-4">¿Qué es Montu?</h2>
                                    <p className="text-lg text-gray-600 mb-6">
                                        Montu es una startup chilena que está revolucionando el marketing de influencers
                                        con tecnología. Fundada en 2022, ha crecido exponencialmente automatizando la
                                        gestión de campañas y la facturación para marcas y creadores de contenido.
                                    </p>
                                    <div className="grid md:grid-cols-3 gap-6">
                                        <div className="text-center">
                                            <div className="text-3xl font-bold text-purple-600">500+</div>
                                            <div className="text-sm text-gray-600">Creadores mensuales</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-3xl font-bold text-purple-600">$500M</div>
                                            <div className="text-sm text-gray-600">Facturación mensual</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-3xl font-bold text-purple-600">1000+</div>
                                            <div className="text-sm text-gray-600">Facturas por mes</div>
                                        </div>
                                    </div>
                                </div>

                                <h2 className="text-3xl font-bold mb-8">El Desafío</h2>
                                <div className="bg-white rounded-2xl shadow-lg p-8 mb-12">
                                    <div className="space-y-6">
                                        <p className="text-lg text-gray-600">
                                            Montu enfrentaba varios desafíos críticos en su operación:
                                        </p>
                                        <ul className="space-y-4">
                                            <li className="flex items-start space-x-3">
                                                <Clock className="h-6 w-6 text-red-500 mt-1" />
                                                <div>
                                                    <h4 className="font-medium">Facturación Manual Intensiva</h4>
                                                    <p className="text-gray-600">
                                                        Más de 1000 facturas mensuales que debían procesarse manualmente.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="flex items-start space-x-3">
                                                <Users className="h-6 w-6 text-red-500 mt-1" />
                                                <div>
                                                    <h4 className="font-medium">Gestión de Creadores</h4>
                                                    <p className="text-gray-600">
                                                        Dificultad para manejar los pagos a cientos de creadores de contenido.
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="flex items-start space-x-3">
                                                <FileText className="h-6 w-6 text-red-500 mt-1" />
                                                <div>
                                                    <h4 className="font-medium">Complejidad Tributaria</h4>
                                                    <p className="text-gray-600">
                                                        Necesidad de gestionar diferentes tipos de documentos tributarios.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h2 className="text-3xl font-bold mb-8">La Solución</h2>
                                <div className="grid md:grid-cols-3 gap-6 mb-12">
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <Zap className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">API de Facturación</h3>
                                        <p className="text-gray-600 text-sm">
                                            Integración directa con el sistema de Montu para emisión automática.
                                        </p>
                                    </div>
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <Users className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">Gestión Masiva</h3>
                                        <p className="text-gray-600 text-sm">
                                            Proceso automatizado para manejar múltiples creadores.
                                        </p>
                                    </div>
                                    <div className="bg-white rounded-xl shadow-lg p-6">
                                        <CheckCircle className="h-8 w-8 text-green-600 mb-4" />
                                        <h3 className="font-medium mb-2">Validación Automática</h3>
                                        <p className="text-gray-600 text-sm">
                                            Verificación de RUTs y datos tributarios en tiempo real.
                                        </p>
                                    </div>
                                </div>

                                <h2 className="text-3xl font-bold mb-8">Los Resultados</h2>
                                <div className="bg-green-50 rounded-2xl p-8 mb-12">
                                    <div className="grid md:grid-cols-3 gap-6 mb-8">
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">95%</p>
                                            <p className="text-sm text-green-800">menos tiempo en facturación</p>
                                        </div>
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">0</p>
                                            <p className="text-sm text-green-800">errores en emisión</p>
                                        </div>
                                        <div className="text-center">
                                            <p className="text-4xl font-bold text-green-600">2x</p>
                                            <p className="text-sm text-green-800">crecimiento en creadores</p>
                                        </div>
                                    </div>
                                    <blockquote className="text-lg text-green-800 italic text-center">
                                        "La facturación era uno de nuestros mayores dolores de cabeza. Con más de 1000 facturas
                                        mensuales, perdíamos días enteros en el proceso. Gracias a Pana, ahora todo es automático
                                        y podemos enfocarnos en hacer crecer la plataforma."
                                        <footer className="text-sm text-green-600 mt-2">
                                            — Gonzalo Sanhueza, CEO de Montu
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* CTA Section */}
                    <section className="py-16 bg-gradient-to-br from-green-50 to-white">
                        <div className="container mx-auto px-4">
                            <div className="max-w-4xl mx-auto text-center">
                                <h2 className="text-3xl font-bold mb-6">
                                    ¿Quieres automatizar tu facturación?
                                </h2>
                                <p className="text-xl text-gray-600 mb-8">
                                    Prueba Pana gratis y descubre cómo puedes ahorrar horas en tu proceso de facturación.
                                </p>
                                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                    <Link
                                        to="/signup"
                                        className="inline-flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                    >
                                        <span>Empezar prueba gratis</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
                <ChatWidget />
            </div>
        </CountryProvider>
    );
}