import React, { useState, useRef } from 'react';
import { Mail, ArrowRight, Loader2 } from 'lucide-react';

interface EmailStepProps {
    onComplete: (email: string) => void;
    initialValue: string;
}

export function EmailStep({ onComplete, initialValue }: EmailStepProps) {
    const [email, setEmail] = useState(initialValue);
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const codeInputs = useRef<(HTMLInputElement | null)[]>([]);

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim();
        if (/^\d{6}$/.test(pastedData)) {
            setVerificationCode(pastedData);
            for (let i = 0; i < 6; i++) {
                if (codeInputs.current[i]) {
                    codeInputs.current[i]!.value = pastedData[i];
                }
            }
        }
    };

    const handleCodeChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 1);
        e.target.value = value;

        const newCode = [...verificationCode];
        newCode[index] = value;
        const fullCode = newCode.join('');
        setError('');
        setVerificationCode(fullCode);

        if (value && index < 5) {
            codeInputs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
            codeInputs.current[index - 1]?.focus();
        }
    };

    const handleSendCode = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!email) {
            setError('El email es requerido');
            return;
        }

        setIsLoading(true);
        try {
            const sessionId = localStorage.getItem('onboarding_session_id');
            const response = await fetch(`${import.meta.env.VITE_API_URL}/signup/email/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    onboarding_session_id: sessionId
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Error al enviar el código');
            }

            setCodeSent(true);
            setRemainingTime(300); // 5 minutos
            const timer = setInterval(() => {
                setRemainingTime(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Error al enviar el código. Por favor, intenta nuevamente.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode.length !== 6) {
            setError('El código debe tener 6 dígitos');
            return;
        }

        setIsLoading(true);
        try {
            const sessionId = localStorage.getItem('onboarding_session_id');
            const response = await fetch(`${import.meta.env.VITE_API_URL}/signup/email/verify/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    verification_code: verificationCode,
                    onboarding_session_id: sessionId
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Código inválido');
            }

            onComplete(email);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Error al verificar el código. Por favor, intenta nuevamente.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError('');
                        }}
                        disabled={codeSent}
                        className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'
                            } rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-gray-100`}
                        placeholder="tu@email.com"
                    />
                </div>
            </div>

            {codeSent && (
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Código de verificación
                    </label>
                    <p className="text-sm text-gray-500 mb-3">
                        Hemos enviado un código de 6 dígitos a tu email
                    </p>
                    <div className="flex justify-center gap-2" onPaste={handlePaste}>
                        {Array(6).fill(0).map((_, index) => (
                            <input
                                key={index}
                                ref={el => codeInputs.current[index] = el}
                                type="text"
                                inputMode="numeric"
                                maxLength={1}
                                onChange={(e) => handleCodeChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                className={`w-12 h-12 text-center text-xl border ${error ? 'border-red-300' : verificationCode.length === 6 ? 'border-green-500' : 'border-gray-300'
                                    } rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500`}
                            />
                        ))}
                    </div>
                    {remainingTime > 0 && (
                        <p className="mt-2 text-sm text-gray-500">
                            Tiempo restante: {formatTime(remainingTime)}
                        </p>
                    )}
                </div>
            )}

            {error && (
                <p className="text-sm text-red-600">
                    {error}
                </p>
            )}

            {!codeSent && (
                <button
                    type="button"
                    onClick={handleSendCode}
                    disabled={isLoading}
                    className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
                >
                    {isLoading ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                        <span>Enviar Código</span>
                    )}
                </button>
            )}

            {codeSent && (
                <div className="flex items-center justify-between">
                    <button
                        type="button"
                        onClick={handleSendCode}
                        disabled={remainingTime > 0 || isLoading}
                        className="text-sm text-green-600 hover:text-green-700 disabled:text-gray-400"
                    >
                        {remainingTime > 0 ? (
                            `Reenviar código en ${formatTime(remainingTime)}`
                        ) : (
                            'Reenviar código'
                        )}
                    </button>

                    <button
                        type="button"
                        onClick={handleVerifyCode}
                        disabled={isLoading || verificationCode.length !== 6}
                        className="flex items-center space-x-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
                    >
                        {isLoading ? (
                            <Loader2 className="h-5 w-5 animate-spin" />
                        ) : (
                            <>
                                <span>Verificar</span>
                                <ArrowRight className="h-4 w-4" />
                            </>
                        )}
                    </button>
                </div>
            )}
        </form>
    );
}