import React, { useState, useEffect } from 'react';
import { Building2, Save, Loader2 } from 'lucide-react';
import { api } from '../../services/api';
import { useEntity } from '../../context/EntityContext';

interface CompanyData {
    name: string;
    businessName: string;
    rut: string;
    address: string;
    email: string;
    phone: string;
    economicActivity: string;
}

export function CompanyDetails() {
    const [formData, setFormData] = useState<CompanyData>({
        name: '',
        businessName: '',
        rut: '',
        address: '',
        email: '',
        phone: '',
        economicActivity: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { state: { selectedEntity } } = useEntity();

    useEffect(() => {
        if (selectedEntity?.id) {
            loadCompanyData();
        }
    }, [selectedEntity]);

    const loadCompanyData = async () => {
        if (!selectedEntity?.id) return;

        setIsLoadingData(true);
        setError(null);

        try {
            const response = await api.get(`/master-entities/${selectedEntity.id}/`);

            if (!response.ok) {
                throw new Error('Error al cargar los datos de la empresa');
            }

            const data = await response.json();
            setFormData({
                name: data.name || '',
                businessName: data.business_name || '',
                rut: data.rut || '',
                address: data.address || '',
                email: data.email || '',
                phone: data.phone || '',
                economicActivity: data.economic_activity || ''
            });
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Error al cargar los datos de la empresa');
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectedEntity?.id) {
            setError('No hay entidad seleccionada');
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await api.post(`/master-entities/${selectedEntity.id}/`, {
                name: formData.name,
                business_name: formData.businessName,
                rut: formData.rut,
                address: formData.address,
                email: formData.email,
                phone: formData.phone,
                economic_activity: formData.economicActivity
            }, { method: 'PUT' });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al guardar los datos de la empresa');
            }

            setSuccess(true);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Error al guardar los datos de la empresa');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const formatRut = (value: string) => {
        // Eliminar puntos y guiones
        const rutClean = value.replace(/\./g, '').replace(/-/g, '');

        // Si está vacío, devolver vacío
        if (rutClean.length === 0) return '';

        // Separar cuerpo y dígito verificador
        let cuerpoRut = rutClean.slice(0, -1);
        const dv = rutClean.slice(-1).toUpperCase();

        // Formatear cuerpo con puntos
        let rutFormateado = '';
        while (cuerpoRut.length > 3) {
            rutFormateado = '.' + cuerpoRut.slice(-3) + rutFormateado;
            cuerpoRut = cuerpoRut.slice(0, -3);
        }
        rutFormateado = cuerpoRut + rutFormateado;

        // Agregar guión y dígito verificador
        return rutFormateado + '-' + dv;
    };

    const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Permitir solo números, k/K y algunos caracteres especiales
        if (/^[0-9kK.\\-]*$/.test(value)) {
            setFormData(prev => ({ ...prev, rut: value }));
        }
    };

    if (isLoadingData) {
        return (
            <div className="flex justify-center items-center h-64">
                <Loader2 className="h-8 w-8 animate-spin text-green-600" />
                <span className="ml-2 text-gray-600">Cargando datos de la empresa...</span>
            </div>
        );
    }

    return (
        <div className="max-w-2xl mx-auto">
            {error && (
                <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded-md">
                    {error}
                </div>
            )}

            {success && (
                <div className="mb-4 p-3 bg-green-50 border border-green-200 text-green-700 rounded-md">
                    Datos de la empresa guardados correctamente
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                            Nombre de la Empresa
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">
                            Razón Social
                        </label>
                        <input
                            id="businessName"
                            name="businessName"
                            type="text"
                            value={formData.businessName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            required
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="rut" className="block text-sm font-medium text-gray-700 mb-1">
                        RUT Empresa
                    </label>
                    <input
                        id="rut"
                        name="rut"
                        type="text"
                        value={formData.rut}
                        onChange={handleRutChange}
                        onBlur={() => setFormData(prev => ({ ...prev, rut: formatRut(formData.rut) }))}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                        placeholder="12.345.678-9"
                        required
                    />
                </div>

                <div>
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                        Dirección
                    </label>
                    <input
                        id="address"
                        name="address"
                        type="text"
                        value={formData.address}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                        required
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                            Correo Electrónico
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                            Teléfono
                        </label>
                        <input
                            id="phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="economicActivity" className="block text-sm font-medium text-gray-700 mb-1">
                        Actividad Económica
                    </label>
                    <textarea
                        id="economicActivity"
                        name="economicActivity"
                        value={formData.economicActivity}
                        onChange={handleChange}
                        rows={3}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                    />
                </div>

                <div className="flex justify-end">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-md ${isLoading
                                ? 'bg-gray-300 cursor-not-allowed'
                                : 'bg-green-600 hover:bg-green-700 text-white'
                            }`}
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="h-5 w-5 animate-spin" />
                                <span>Guardando...</span>
                            </>
                        ) : (
                            <>
                                <Save className="h-5 w-5" />
                                <span>Guardar Datos</span>
                            </>
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
}