import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function SuccessStories() {
    return (
        <section id="success-stories" className="py-16 bg-gradient-to-br from-green-50 to-white">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl md:text-5xl font-bold text-center mb-12">
                    Historias de Éxito
                </h2>

                <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                    {/* Solpiscina Case */}
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
                        <img
                            src="https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1200&q=80"
                            alt="Mantenimiento de piscinas"
                            className="w-full h-48 object-cover"
                        />
                        <div className="p-6">
                            <div className="flex items-center space-x-4 mb-4">
                                <img
                                    src="https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=400&q=80"
                                    alt="Javier Oyanedel"
                                    className="w-12 h-12 rounded-full object-cover"
                                />
                                <div>
                                    <h3 className="font-bold text-lg">Solpiscina</h3>
                                    <p className="text-gray-600">Mantenimiento de piscinas</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-500">Documentos mensuales</span>
                                    <span className="font-medium">90</span>
                                </div>
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-500">Tiempo de facturación</span>
                                    <span className="font-medium text-green-600">De 4h a 10min</span>
                                </div>
                            </div>
                            <div className="mt-6">
                                <Link
                                    to="/success/solpiscina"
                                    className="inline-flex items-center text-green-600 hover:text-green-700"
                                >
                                    <span>Ver caso completo</span>
                                    <ArrowRight className="h-4 w-4 ml-1" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Montu Case */}
                    <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
                        <img
                            src="https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&w=800&q=80"
                            alt="Marketing de influencers"
                            className="w-full h-48 object-cover"
                        />
                        <div className="p-6">
                            <div className="flex items-center space-x-4 mb-4">
                                <div className="w-12 h-12 rounded-full overflow-hidden bg-white border border-gray-200">
                                    <img
                                        src="/montu-logo.png"
                                        alt="Montu Logo"
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <div>
                                    <h3 className="font-bold text-lg">Montu</h3>
                                    <p className="text-gray-600">Plataforma de influencers</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-500">Documentos mensuales</span>
                                    <span className="font-medium">150+</span>
                                </div>
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-500">Integración</span>
                                    <span className="font-medium text-green-600">API Automática</span>
                                </div>
                            </div>
                            <div className="mt-6">
                                <Link
                                    to="/success/montu"
                                    className="inline-flex items-center text-green-600 hover:text-green-700"
                                >
                                    <span>Ver caso completo</span>
                                    <ArrowRight className="h-4 w-4 ml-1" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}