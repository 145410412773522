import React, { useState, useEffect, useCallback } from 'react';
import { Layout } from '../components/platform/layout/Layout';
import { DocumentsTable, Document as DocumentTableType } from '../components/platform/DocumentsTable';
import { Pagination } from '../components/platform/Pagination';
import { DocumentsHeader } from '../components/platform/DocumentsHeader';
import { SIICredentialsSetup } from '../components/platform/SIICredentialSetup';
import { TableLoader } from '../components/TableLoader';
import { SpinningRing } from '../components/SpinningRing';
import { InvoiceCreation } from '../components/platform/invoice/InvoiceCreation';
import { BulkInvoiceCreation } from '../components/platform/invoice/bulk/BulkInvoiceCreation';
import { FileText } from 'lucide-react';
import { api } from '../services/api';
import { CollapsibleFilters, Filters } from '../components/platform/filters/CollapsibleFilters';

import { motion } from 'framer-motion';
import { useEntity } from '../context/EntityContext';
import { refreshDocuments, initializeCompanyFromProfile } from '../utils/documentUtils';

interface Document extends Omit<DocumentTableType, 'dte_type_code'> {
  id: string;
  date_issued: string;
  folio: string;
  amount_with_iva: number;
  status: "PENDING" | "COMPLETED";
  sender: number;
  receiver: number;
  receiver_name: string;
  receiver_tax_id: string;
  dte_type: number;
  dte_type_code: number;
  document_batch: string | null;
  created_at: string;
  has_credit_note?: boolean;
  credit_note_folio?: string;
}

interface PaginatedResponse {
  count: number;
  total_pages: number;
  current_page: number;
  next: string | null;
  previous: string | null;
  results: Document[];
}

interface MasterEntity {
  id: string;
  name: string;
  tax_id: string;
}

export function Platform() {
  const { state: entityState, dispatch } = useEntity();
  const selectedEntity = entityState.selectedEntity;
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);

  const [state, setState] = useState({
    documents: [] as Document[],
    selectedDocuments: [] as string[],
    isFiltersOpen: false,
    filters: {
      hasCreditNote: undefined as boolean | undefined,
      paymentStatus: undefined as 'paid' | 'unpaid' | undefined
    } as Filters,
    currentPage: 1,
    totalPages: 0,
    searchTerm: '',
    loadingDocuments: false,
    initialLoading: true,
    isBulkCreation: false,
    isRefreshing: false,
    isProcessing: false,
    refreshMessage: null as { type: 'success' | 'error', text: string } | null,
    siiConfigured: localStorage.getItem('sii_configured')
  });

  // Efecto para inicializar la empresa después del onboarding
  useEffect(() => {
    const initializeCompanyAfterOnboarding = async () => {
      const companyRut = localStorage.getItem('companyRut');

      // Si tenemos el rut de la empresa pero no tenemos las entidades maestras
      if (companyRut && !localStorage.getItem('masterEntities')) {
        try {
          // Obtener el perfil del usuario que incluye las entidades maestras
          const response = await api.get('/auth/profile/');
          const profileData = await response.json();
          const entities = profileData.master_entities || [];

          if (entities.length > 0) {
            console.log('[Platform] Entidades maestras obtenidas del perfil:', entities);

            // Guardar las entidades maestras
            localStorage.setItem('masterEntities', JSON.stringify(entities));

            // Buscar la entidad que coincida con el RUT de la empresa
            const matchingEntity = entities.find(
              (entity: MasterEntity) => entity.tax_id === companyRut.replace(/\./g, '').replace('-', '')
            );

            const entityToSelect = matchingEntity || entities[0];

            // Seleccionar la entidad
            localStorage.setItem('selectedEntityId', entityToSelect.id);
            localStorage.setItem('selectedEntityName', entityToSelect.name);

            console.log('[Platform] Entidad seleccionada:', entityToSelect);

            // Actualizar el estado global
            dispatch({
              type: "SET_MASTER_ENTITIES",
              payload: entities
            });

            dispatch({
              type: "SET_ENTITY",
              payload: {
                id: entityToSelect.id,
                name: entityToSelect.name
              }
            });
          }
        } catch (error) {
          console.error('Error inicializando empresa después del onboarding:', error);
        }
      }
    };

    // Solo inicializamos si el SII está configurado
    if (state.siiConfigured !== 'false') {
      initializeCompanyAfterOnboarding();
    }
  }, [dispatch, state.siiConfigured]); // Agregamos siiConfigured como dependencia

  const handleFilterChange = useCallback((newFilters: Filters) => {
    console.log('Aplicando filtros:', newFilters);
    setState(prev => ({
      ...prev,
      filters: newFilters,
      currentPage: 1 // Reset to first page when filters change
    }));
  }, []);

  const fetchDocuments = useCallback(async (entityId: string, page: number = 1, search: string = '') => {
    try {
      setState(prev => ({ ...prev, loadingDocuments: true }));

      let url = `/master-entities/${entityId}/documents/?page=${page}`;

      // Añadir filtro de búsqueda si existe
      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }

      // Añadir filtro de nota de crédito si está definido
      if (state.filters.hasCreditNote !== undefined) {
        url += `&has_credit_note=${state.filters.hasCreditNote}`;
      }

      // Añadir filtro de estado de pago si está definido
      if (state.filters.paymentStatus !== undefined) {
        url += `&payment_status=${state.filters.paymentStatus}`;
      }

      console.log("Consultando documentos con URL:", url);

      const response = await api.get(url);
      const data: PaginatedResponse = await response.json();

      setState(prev => ({
        ...prev,
        documents: data.results || [],
        totalPages: data.total_pages,
        loadingDocuments: false,
        initialLoading: false
      }));
    } catch (err) {
      console.error('Error fetching documents:', err);
      setState(prev => ({
        ...prev,
        documents: [],
        totalPages: 0,
        loadingDocuments: false,
        initialLoading: false
      }));
    }
  }, [state.filters]); // Actualizar para incluir todas las propiedades de filters

  const handleRefresh = async () => {
    try {
      setState(prev => ({ ...prev, isRefreshing: true }));

      if (!selectedEntity) {
        throw new Error('No hay entidad seleccionada');
      }

      const response = await api.post('/scrapers/documents/', {
        master_entity_id: selectedEntity.id
      });

      const responseData = await response.json();

      if (responseData.success) {
        setState(prev => ({
          ...prev,
          refreshMessage: {
            type: 'success',
            text: `Se encontraron ${responseData.new_documents} documentos nuevos`
          }
        }));

        setTimeout(() => {
          setState(prev => ({ ...prev, refreshMessage: null }));
        }, 5000);

        await fetchDocuments(selectedEntity.id, state.currentPage, state.searchTerm);
      }
    } catch (error) {
      console.error('Error refreshing documents:', error);
      setState(prev => ({
        ...prev,
        refreshMessage: {
          type: 'error',
          text: 'Error al actualizar documentos'
        }
      }));
    } finally {
      setState(prev => ({ ...prev, isRefreshing: false }));
    }
  };

  useEffect(() => {
    if (selectedEntity) {
      setState(prev => ({ ...prev, loadingDocuments: true }));
      const debounceTimer = setTimeout(() => {
        fetchDocuments(selectedEntity.id, state.currentPage, state.searchTerm);
      }, 300);
      return () => clearTimeout(debounceTimer);
    } else {
      const storedEntityId = localStorage.getItem('selectedEntityId');
      if (!storedEntityId) {
        setState(prev => ({ ...prev, initialLoading: false }));
        return;
      }

      setState(prev => ({ ...prev, loadingDocuments: true }));
      const debounceTimer = setTimeout(() => {
        fetchDocuments(storedEntityId, state.currentPage, state.searchTerm);
      }, 300);

      return () => clearTimeout(debounceTimer);
    }
  }, [selectedEntity, state.currentPage, state.searchTerm, state.filters, fetchDocuments]);

  const handleSearch = (term: string) => {
    setState(prev => ({ ...prev, searchTerm: term, currentPage: 1 }));
  };

  const handlePageChange = useCallback((page: number) => {
    setState(prev => ({ ...prev, currentPage: page }));
  }, []);

  const handleDocumentSelect = useCallback((id: string) => {
    setState(prev => ({
      ...prev,
      selectedDocuments: prev.selectedDocuments.includes(id)
        ? prev.selectedDocuments.filter(docId => docId !== id)
        : [...prev.selectedDocuments, id]
    }));
  }, []);

  const handleCreateCreditNote = async () => {
    if (state.selectedDocuments.length === 0) return;

    if (!confirm(`¿Estás seguro de crear notas de crédito para ${state.selectedDocuments.length} documento(s)?`)) {
      return;
    }

    setState(prev => ({ ...prev, isProcessing: true }));
    console.log(`Iniciando creación de ${state.selectedDocuments.length} nota(s) de crédito`);

    try {
      // Usar SIEMPRE la entidad del contexto para garantizar consistencia
      let entityId = selectedEntity?.id;

      // Si no hay entidad seleccionada en el contexto, intentar obtenerla de localStorage como fallback
      if (!entityId) {
        const storedEntityId = localStorage.getItem('selectedEntityId');
        if (storedEntityId) {
          entityId = storedEntityId;
          console.log('Usando entidad de localStorage como fallback:', entityId);
        } else {
          console.warn('No se encontró entidad en localStorage');
          throw new Error('No hay entidad seleccionada');
        }
      } else {
        console.log('Usando entidad del store:', entityId);
      }

      // Fecha actual en formato YYYY-MM-DD
      const currentDate = new Date().toISOString().split('T')[0];
      const results: Array<{ success: boolean; id: string; error?: string; newDocumentId?: string }> = [];

      // Procesar documentos uno por uno para mayor robustez
      for (const docId of state.selectedDocuments) {
        try {
          console.log(`Procesando documento ${docId}...`);
          // Buscar el documento original para obtener el receiver_rut
          const originalDoc = state.documents.find(doc => doc.id === docId);

          if (!originalDoc) {
            console.error(`No se encontró el documento original con ID ${docId}`);
            results.push({
              success: false,
              id: docId,
              error: 'No se encontró el documento original'
            });
            continue;
          }

          console.log(`Documento original encontrado: ${originalDoc.folio} - ${originalDoc.receiver_name}`);

          // Usar POST para crear un nuevo documento como nota de crédito
          const response = await api.post(`/master-entities/${entityId}/documents_v2/`, {
            from_document_id: docId,
            target_type: '61', // Código para Nota de Crédito
            date_issued: currentDate, // Fecha actual
            total: 0, // Total en 0
            receiver_rut: originalDoc.receiver_tax_id // RUT del receptor del documento original
          });

          const data = await response.json();

          if (!response.ok) {
            console.error(`Error al crear nota de crédito para documento ${docId}:`, data);
            results.push({
              success: false,
              id: docId,
              error: data.error || 'Error desconocido'
            });
          } else {
            console.log(`Nota de crédito creada exitosamente para documento ${docId}`, data);
            results.push({
              success: true,
              id: docId,
              newDocumentId: data.id
            });
          }
        } catch (error) {
          console.error(`Error al procesar documento ${docId}:`, error);
          results.push({
            success: false,
            id: docId,
            error: error instanceof Error ? error.message : 'Error desconocido'
          });
        }
      }

      // Verificar resultados
      const successful = results.filter(r => r.success).length;
      const failed = results.filter(r => !r.success).length;
      console.log(`Resultado final: ${successful} exitosas, ${failed} fallidas`);

      if (successful > 0 && failed === 0) {
        setState(prev => ({
          ...prev,
          refreshMessage: {
            type: 'success',
            text: `Se han creado ${successful} nota(s) de crédito correctamente.`
          },
          selectedDocuments: []
        }));

        // Recargar documentos para mostrar los cambios
        if (selectedEntity) {
          await fetchDocuments(selectedEntity.id, state.currentPage, state.searchTerm);
        }
      } else if (successful > 0 && failed > 0) {
        setState(prev => ({
          ...prev,
          refreshMessage: {
            type: 'error',
            text: `Se crearon ${successful} nota(s) de crédito, pero fallaron ${failed}. Revisa los detalles en la consola.`
          },
          // Solo mantener seleccionados los documentos que fallaron
          selectedDocuments: prev.selectedDocuments.filter(id => !results.find(r => r.success && r.id === id))
        }));

        // Recargar documentos para mostrar los cambios parciales
        if (selectedEntity) {
          await fetchDocuments(selectedEntity.id, state.currentPage, state.searchTerm);
        }
      } else {
        setState(prev => ({
          ...prev,
          refreshMessage: {
            type: 'error',
            text: `No se pudo crear ninguna nota de crédito. Revisa los detalles en la consola.`
          }
        }));
      }

      // Limpiar mensaje después de 5 segundos
      setTimeout(() => {
        setState(prev => ({ ...prev, refreshMessage: null }));
      }, 5000);
    } catch (error) {
      console.error('Error general al crear notas de crédito:', error);
      setState(prev => ({
        ...prev,
        refreshMessage: {
          type: 'error',
          text: `Error al crear notas de crédito: ${error instanceof Error ? error.message : 'Error desconocido'}`
        }
      }));
    } finally {
      setState(prev => ({ ...prev, isProcessing: false }));
    }
  };

  if (isCreatingInvoice) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <InvoiceCreation
            onBack={() => setIsCreatingInvoice(false)}
          />
        </div>
      </Layout>
    );
  }

  if (state.isBulkCreation) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <BulkInvoiceCreation
            onBack={() => setState(prev => ({ ...prev, isBulkCreation: false }))}
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="bg-white rounded-lg shadow-lg">
        <div className="relative">
          {state.siiConfigured === 'false' && (
            <div className="fixed inset-0 bg-white/90 backdrop-blur-sm z-50 flex items-start justify-center overflow-y-auto pt-5">
              <div className="w-full max-w-4xl m-4">
                <SIICredentialsSetup
                  onComplete={async () => {
                    try {
                      // Mantenemos el estado de carga
                      setState(prev => ({
                        ...prev,
                        initialLoading: true,
                        loadingDocuments: true
                      }));

                      // 1. Inicializar empresa desde el perfil
                      const storedCompanyRut = localStorage.getItem('companyRut');
                      const companyRut = storedCompanyRut || undefined;
                      const selectedEntity = await initializeCompanyFromProfile(dispatch, companyRut);

                      if (selectedEntity) {
                        // 2. Cargar las facturas iniciales
                        const refreshResult = await refreshDocuments(selectedEntity.id);

                        if (refreshResult.success) {
                          // 3. Actualizar localStorage y estado solo si todo fue exitoso
                          localStorage.setItem('sii_configured', 'true');

                          // 4. Cargar los documentos
                          await fetchDocuments(selectedEntity.id, 1, '');

                          // 5. Actualizar todos los estados necesarios
                          setState(prev => ({
                            ...prev,
                            siiConfigured: 'true',
                            refreshMessage: {
                              type: 'success',
                              text: `Se encontraron ${refreshResult.new_documents} documentos nuevos`
                            },
                            currentPage: 1,
                            searchTerm: '',
                            initialLoading: false,
                            loadingDocuments: false
                          }));

                          setTimeout(() => {
                            setState(prev => ({ ...prev, refreshMessage: null }));
                          }, 5000);
                        } else {
                          throw new Error('Error al cargar los documentos');
                        }
                      } else {
                        throw new Error('No se pudo seleccionar una entidad');
                      }
                    } catch (error) {
                      console.error('Error en el flujo de onboarding:', error);
                      setState(prev => ({
                        ...prev,
                        initialLoading: false,
                        loadingDocuments: false,
                        refreshMessage: {
                          type: 'error',
                          text: 'Hubo un error al configurar las credenciales. Por favor, intenta nuevamente.'
                        }
                      }));

                      setTimeout(() => {
                        setState(prev => ({ ...prev, refreshMessage: null }));
                      }, 5000);
                    }
                  }}
                />
              </div>
            </div>
          )}

          {state.selectedDocuments.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="fixed bottom-6 right-6 left-6 md:left-auto md:right-6 md:w-auto bg-white rounded-lg shadow-xl p-4 z-50 flex items-center justify-between"
            >
              <div className="flex items-center">
                <span className="font-medium text-gray-700 mr-4">
                  {state.selectedDocuments.length} documento(s) seleccionado(s)
                </span>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setState(prev => ({ ...prev, selectedDocuments: [] }))}
                  className="px-3 py-1.5 text-gray-600 hover:text-gray-800"
                  disabled={state.isProcessing}
                >
                  Cancelar
                </button>
                <button
                  onClick={handleCreateCreditNote}
                  className="px-4 py-1.5 bg-red-600 hover:bg-red-700 text-white rounded-md flex items-center text-sm"
                  disabled={state.isProcessing}
                >
                  {state.isProcessing ? (
                    <SpinningRing size="sm" thickness="thin" className="mr-2" />
                  ) : (
                    <FileText className="h-4 w-4 mr-2" />
                  )}
                  Crear Nota de Crédito
                </button>
              </div>
            </motion.div>
          )}

          <DocumentsHeader
            searchTerm={state.searchTerm}
            onSearch={handleSearch}
            isConfigured={state.siiConfigured !== 'false'}
            currentEntity={selectedEntity?.name || ''}
            onRefresh={handleRefresh}
            isRefreshing={state.isRefreshing}
            onCreateInvoice={() => setIsCreatingInvoice(true)}
            onBulkCreation={() => setState(prev => ({ ...prev, isBulkCreation: true }))}
          />
          <CollapsibleFilters
            isOpen={state.isFiltersOpen}
            onToggle={() => setState(prev => ({ ...prev, isFiltersOpen: !prev.isFiltersOpen }))}
            filters={state.filters}
            onFilterChange={handleFilterChange}
          />

          <div className="p-6">
            {state.refreshMessage && (
              <div className={`mb-4 p-3 rounded-md ${state.refreshMessage.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
                {state.refreshMessage.text}
              </div>
            )}

            {state.initialLoading ? (
              <div className="flex justify-center">
                <SpinningRing size="md" thickness="normal" />
              </div>
            ) : state.loadingDocuments ? (
              <TableLoader />
            ) : state.documents.length > 0 ? (
              <>
                <DocumentsTable
                  documents={state.documents}
                  selectedDocuments={state.selectedDocuments}
                  onSelect={handleDocumentSelect}
                />
                <Pagination
                  currentPage={state.currentPage}
                  totalPages={state.totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-500">No hay facturas emitidas aún.</p>
                <div className="mt-4 space-y-2">
                  <p className="text-gray-700 font-medium">¿Qué tipo de emisión deseas realizar?</p>
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={() => setIsCreatingInvoice(true)}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                    >
                      Emitir una Factura
                    </button>
                    <button
                      onClick={() => setState(prev => ({ ...prev, isBulkCreation: true }))}
                      className="px-4 py-2 border border-green-600 text-green-600 rounded-md hover:bg-green-50 transition-colors"
                    >
                      Emisión Masiva
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}