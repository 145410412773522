import React from 'react';
import { Calendar, FileText } from 'lucide-react';

interface InvoiceDetailsData {
    issueDate: string;
    documentType: string;
}

interface InvoiceDetailsProps {
    data: InvoiceDetailsData;
    onChange: (data: InvoiceDetailsData) => void;
}

export function InvoiceDetails({ data, onChange }: InvoiceDetailsProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        onChange({
            ...data,
            [name]: value
        });
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center space-x-2">
                <FileText className="h-5 w-5 text-green-600" />
                <h3 className="text-lg font-medium">Detalles del Documento</h3>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Fecha de Emisión
                    </label>
                    <div className="relative">
                        <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type="date"
                            name="issueDate"
                            value={data.issueDate}
                            onChange={handleChange}
                            className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Tipo de Documento
                    </label>
                    <select
                        name="documentType"
                        value={data.documentType}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        required
                    >
                        <option value="33">Factura Electrónica (33)</option>
                        <option value="34">Factura Exenta Electrónica (34)</option>
                        <option value="56">Nota Débito Electrónica (56)</option>
                        <option value="61">Nota Crédito Electrónica (61)</option>
                    </select>
                </div>
            </div>
        </div>
    );
}