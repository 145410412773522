import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { getAllPosts } from '../../utils/blog';
import { BlogListItem } from '../../types/blog';

export default function BlogIndex() {
    const [posts, setPosts] = useState<BlogListItem[]>([]);

    useEffect(() => {
        getAllPosts().then(setPosts);
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <Header />
            <main className="pt-24 pb-16">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl font-bold text-center mb-12">Blog de Facturación</h1>

                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
                        {posts.map((post) => (
                            <article key={post.slug} className="bg-white rounded-2xl shadow-lg overflow-hidden">
                                <img
                                    src={post.image}
                                    alt={post.title}
                                    className="w-full h-48 object-cover"
                                />

                                <div className="p-6">
                                    <div className="flex flex-wrap gap-2 mb-4">
                                        {post.tags.map((tag) => (
                                            <span
                                                key={tag}
                                                className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full"
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>

                                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                                        {post.title}
                                    </h2>

                                    <p className="text-gray-600 mb-4 line-clamp-3">
                                        {post.description}
                                    </p>

                                    <div className="flex items-center justify-between text-sm text-gray-500">
                                        <time>{new Date(post.date).toLocaleDateString('es-CL')}</time>
                                        <span>{post.readingTime}</span>
                                    </div>

                                    <Link
                                        to={`/blog/${post.slug}`}
                                        className="mt-4 inline-flex items-center space-x-2 text-green-600 font-medium hover:text-green-700"
                                    >
                                        <span>Leer más</span>
                                        <ArrowRight className="h-4 w-4" />
                                    </Link>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}