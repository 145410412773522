import { api } from '../services/api';
import { FormData } from '../components/platform/invoice/types';

interface DraftResponse {
    id?: string;
    pdf_data?: string;
    error?: string;
    status?: string;
}

export const createDraftDocument = async (draftData: FormData, entityId: string): Promise<DraftResponse> => {
    if (!entityId) {
        throw new Error('No hay entidad seleccionada');
    }

    // Verificar datos del cliente
    console.log('Datos del cliente recibidos:', draftData.customer);

    // Verificar productos recibidos
    console.log('Productos recibidos en createDraftDocument (items):', draftData.items);
    console.log('Productos recibidos en createDraftDocument (details):', draftData.details);
    console.log('¿El cliente tiene products? 🔍', !!draftData.items && Array.isArray(draftData.items));
    console.log('¿El cliente tiene details? 🔍', !!draftData.details && Array.isArray(draftData.details));

    // Verificar específicamente si existe la actividad económica
    if (!draftData.customer.receiver_business_activity) {
        console.warn('⚠️ Actividad económica no definida en los datos del cliente');
    } else {
        console.log('✅ Actividad económica del cliente:', draftData.customer.receiver_business_activity);
    }

    // Asegurarnos de que siempre tenemos un array de productos
    // Primero intentamos usar draftData.items, luego draftData.details si está disponible como array
    // si no, usamos un array vacío como último recurso
    let itemsList = [];
    if (draftData.items && Array.isArray(draftData.items) && draftData.items.length > 0) {
        console.log('✅ Usando draftData.items para los productos');
        itemsList = draftData.items;
    } else if (draftData.details && Array.isArray(draftData.details) && draftData.details.length > 0) {
        console.log('✅ Usando draftData.details para los productos');
        itemsList = draftData.details;
    } else {
        console.warn('⚠️ No se encontraron productos. Usando array vacío como fallback.');
    }

    // Verificar si hay productos válidos
    if (itemsList.length === 0) {
        console.warn('⚠️ No hay productos para enviar al backend');
    } else {
        console.log(`✅ Se enviarán ${itemsList.length} productos al backend`);
        console.log('Primer producto:', itemsList[0]);
    }

    const totalNeto = itemsList.reduce((total, item) => {
        return total + (item.item_total || 0);
    }, 0);

    const iva = Math.round(totalNeto * 0.19);
    const totalConIva = totalNeto + iva;

    // Extraer correctamente los campos de dirección anidados
    let receiverAddress = '';
    let receiverDistrict = '';
    let receiverCity = '';

    if (typeof draftData.customer.receiver_address === 'string') {
        receiverAddress = draftData.customer.receiver_address;
        receiverDistrict = draftData.customer.receiver_district || '';
        receiverCity = draftData.customer.receiver_city || '';
    } else if (typeof draftData.customer.receiver_address === 'object') {
        // Es un objeto anidado
        receiverAddress = draftData.customer.receiver_address.address || '';
        receiverDistrict = draftData.customer.receiver_address.district?.name || '';
        receiverCity = draftData.customer.receiver_address.district?.city?.name || '';
    }

    // Asegurar que se incluyen todos los campos requeridos
    const businessActivity = draftData.customer.receiver_business_activity || "";

    const requestBody = {
        date_issued: draftData.details.issueDate,
        purchase_transaction_type: "1",
        sale_transaction_type: "1",
        payment_method: "1",
        due_date: draftData.details.issueDate,
        dte_type: "1033", // Tipo borrador
        receiver_rut: draftData.customer.receiver_rut,
        receiver_business_name: draftData.customer.receiver_business_name,
        receiver_business_activity: businessActivity,
        receiver_contact: draftData.customer.receiver_contact || "",
        receiver_address: receiverAddress,
        receiver_district: receiverDistrict,
        receiver_city: receiverCity || "SANTIAGO",
        total: totalConIva,
        details: itemsList.map(item => ({
            item_name: item.item_name || 'Producto sin nombre',
            item_description: item.item_description || "",
            quantity: Number(item.quantity) || 1,
            unit_price: Number(item.price) || 0,
            item_total: Number(item.item_total) || 0,
            item_code: item.code || "",
            item_type_code: "",
            unit: "",
            discount_percent: Number(item.discount) || 0,
            discount_amount: item.discount ? Math.round((item.quantity * item.price * item.discount) / 100) : 0,
            other_tax: ""
        })),
        references: draftData.references || []
    };

    console.log('📝 Request body a enviar:', requestBody);
    console.log('🔍 Verificando campos de dirección en el request:', {
        dirección: requestBody.receiver_address,
        comuna: requestBody.receiver_district,
        ciudad: requestBody.receiver_city
    });
    console.log('🔍 Verificando detalles de productos en el request:', requestBody.details);
    console.log('🔍 Verificando referencias en el request:', requestBody.references);

    const response = await api.post(`/master-entities/${entityId}/documents/`, requestBody);
    const data = await response.json();

    if (data.id) {
        localStorage.setItem('draftDocumentId', data.id.toString());
    }

    return data;
}; 