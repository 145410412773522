import React, { useState } from 'react';
import { User, Loader2 } from 'lucide-react';

interface NameStepProps {
    onComplete: (data: { firstName: string; lastName: string }) => void;
    initialValue: { firstName: string; lastName: string };
}

export function NameStep({ onComplete, initialValue }: NameStepProps) {
    const [firstName, setFirstName] = useState(initialValue.firstName);
    const [lastName, setLastName] = useState(initialValue.lastName);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!firstName || !lastName) {
            setError('Todos los campos son requeridos');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${import.meta.env.VITE_API_URL}/signup/name/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName
                })
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Error al registrar el nombre');
            }

            // Guardar el session ID en localStorage
            localStorage.setItem('onboarding_session_id', data.onboarding_session_id);

            onComplete({ firstName, lastName });
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Error al registrar el nombre. Por favor, intenta nuevamente.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                        Nombre
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setError('');
                            }}
                            className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'
                                } rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500`}
                            placeholder="Juan"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                        Apellido
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                setError('');
                            }}
                            className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'
                                } rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500`}
                            placeholder="Pérez"
                        />
                    </div>
                </div>
            </div>

            {error && (
                <p className="text-sm text-red-600">
                    {error}
                </p>
            )}

            <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
            >
                {isLoading ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                    <span>Continuar</span>
                )}
            </button>
        </form>
    );
}