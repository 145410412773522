import React from 'react';
import { X } from 'lucide-react';
import { SimpleExample } from './SimpleExample';
import { ComplexExample } from './ComplexExample';
import { ColumnFormat } from './ColumnFormat';

interface ExamplesModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function ExamplesModal({ isOpen, onClose }: ExamplesModalProps) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                    onClick={onClose}
                />
                <div className="relative z-50 bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                    <div className="p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-semibold">Ejemplos de Formato</h2>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-600 transition-colors"
                            >
                                <X className="h-5 w-5" />
                            </button>
                        </div>

                        <div className="space-y-8">
                            <SimpleExample />
                            <ComplexExample />
                            <ColumnFormat />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}