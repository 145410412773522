import React from 'react';
import { InvoiceCreation } from '../components/platform/invoice/InvoiceCreation';
import { Layout } from '../components/platform/layout/Layout';

export function InvoiceEmissionPage() {
    return (
        <Layout>
            <div className="p-6">
                <InvoiceCreation
                    onBack={() => window.history.back()}
                    onNext={() => { }}
                />
            </div>
        </Layout>
    );
} 