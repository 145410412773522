import React, { useState, useEffect } from 'react';
import { IssuerInfo } from './draft/IssuerInfo';
import { CustomerInfo } from './draft/CustomerInfo';
import { InvoiceDetails } from './draft/InvoiceDetails';
import { ProductList } from './draft/ProductList';
import { References } from './draft/References';
import { api } from '../../../services/api';
import { useEntity } from '../../../context/EntityContext';

interface CustomerData {
    receiver_rut: string;
    receiver_business_name: string;
    receiver_address: string;
    receiver_district: string;
    receiver_city: string;
    receiver_business_activity: string;
    receiver_activity_code: string;
    receiver_contact: string;
    master_entity_id: string;
}

interface Product {
    id: string;
    name: string;
    code: string;
    price: number;
    quantity: number;
    discount?: number;
    item_name: string;
    unit_price: number;
    item_total: number;
    item_description?: string;
}

type DocumentType = "30" | "32" | "33" | "34" | "35" | "38" | "39" | "40" | "41" | "43" | "45" | "46" | "48" | "50" | "52" | "55" | "56" | "60" | "61" | "103" | "801" | "802" | "803" | "804" | "805" | "806" | "813" | "820" | "821" | "822" | "823" | "HES";

interface Reference {
    id: string;
    reference_type: DocumentType;
    reference_folio: string;
    reference_date: string;
    reference_reason: string;
}

interface FormData {
    issuer: {
        name: string;
        address: string;
        district: string;
        city: string;
        saleType: string;
        email: string;
        phone: string;
        businessLine: string;
        economicActivity: string;
    };
    customer: CustomerData;
    details: {
        issueDate: string;
        documentType: string;
    };
    items: Product[];
    references: Reference[];
}

interface RequestData {
    date_issued: string;
    purchase_transaction_type: string;
    sale_transaction_type: string;
    payment_method: string;
    due_date: string;
    dte_type: number;
    receiver_rut: string;
    receiver_business_name: string;
    receiver_business_activity: string;
    receiver_contact: string;
    receiver_address: string;
    receiver_district: string;
    receiver_city: string;
    total: number;
    details: Array<{
        item_name: string;
        item_description: string;
        quantity: number;
        unit_price: number;
        item_total: number;
        item_code: string;
        item_type_code: string;
        unit: string;
        discount_percent: number;
        other_tax: string;
    }>;
    references: Reference[];
}

interface InvoiceDraftProps {
    onSubmit: (data: RequestData) => void;
    isLoading?: boolean;
    initialData?: FormData;
}

const initialFormData: FormData = {
    issuer: {
        name: '',
        address: '',
        district: '',
        city: '',
        saleType: '1',
        email: '',
        phone: '',
        businessLine: '',
        economicActivity: ''
    },
    customer: {
        receiver_rut: '',
        receiver_business_name: '',
        receiver_address: '',
        receiver_district: '',
        receiver_city: '',
        receiver_business_activity: '',
        receiver_activity_code: '',
        receiver_contact: '',
        master_entity_id: ''
    },
    details: {
        issueDate: new Date().toISOString().split('T')[0],
        documentType: '33'
    },
    items: [],
    references: []
};

export function InvoiceDraft({ onSubmit, isLoading, initialData }: InvoiceDraftProps) {
    const { state: entityState } = useEntity();
    const [formData, setFormData] = useState<FormData>(() => {
        const savedFormData = localStorage.getItem('invoiceFormData');
        console.log('Inicializando formData en InvoiceDraft');
        console.log('Datos guardados:', savedFormData);
        console.log('Initial data:', initialData);

        if (savedFormData) {
            try {
                const parsedData = JSON.parse(savedFormData);
                console.log('Usando datos del localStorage:', parsedData);

                // Verificar si los datos tienen la estructura esperada
                if (parsedData.customer && typeof parsedData.customer === 'object') {
                    // Los datos tienen la estructura correcta
                    return parsedData;
                } else if (parsedData.selectedAddress || parsedData.selectedActivity) {
                    // Los datos tienen una estructura diferente, convertirlos al formato esperado
                    console.log('Datos con estructura diferente, adaptando al formato esperado');

                    // Crear estructura base con los valores por defecto
                    const formattedData = { ...initialFormData };

                    // Si hay datos de dirección, mapearlos a la estructura esperada
                    if (parsedData.selectedAddress) {
                        formattedData.customer.receiver_address = parsedData.selectedAddress.address || '';

                        if (parsedData.selectedAddress.district) {
                            formattedData.customer.receiver_district = parsedData.selectedAddress.district.name || '';

                            if (parsedData.selectedAddress.district.city) {
                                formattedData.customer.receiver_city = parsedData.selectedAddress.district.city.name || '';
                            }
                        }
                    }

                    // Si hay datos de actividad económica, mapearlos
                    if (parsedData.selectedActivity) {
                        formattedData.customer.receiver_business_activity = parsedData.selectedActivity.name || '';
                        formattedData.customer.receiver_activity_code = parsedData.selectedActivity.code || '';
                    }

                    return formattedData;
                }
            } catch (error) {
                console.error('Error al parsear datos del localStorage:', error);
            }
        }

        if (initialData) {
            console.log('Usando initialData:', initialData);
            return initialData;
        }

        console.log('Usando initialFormData:', initialFormData);
        return initialFormData;
    });

    // Efecto para llamar al backend cuando se carga el componente
    useEffect(() => {
        const callBackendOnLoad = async () => {
            try {
                if (!entityState.selectedEntity?.id) {
                    console.error('No hay entidad seleccionada en el contexto');
                    return;
                }

                const masterEntityId = entityState.selectedEntity.id;
                console.log('Usando entidad del contexto:', masterEntityId);

                // Datos básicos para la llamada inicial
                const backendData: any = {
                    header: {
                        issueDate: formData.details.issueDate,
                    },
                    dte_type: {
                        code: "1033"
                    }
                };

                // Si hay un cliente seleccionado, incluir su RUT si existe
                if (formData.customer && formData.customer.receiver_rut) {
                    backendData.receiver_rut = formData.customer.receiver_rut;
                    console.log('Cliente seleccionado, incluyendo RUT:', formData.customer.receiver_rut);
                }

                // console.log('Llamando al backend al cargar el componente:', backendData);
                // console.log('Master Entity ID:', masterEntityId);

                // const response = await api.post(`/master-entities/${masterEntityId}/documents_v2/`, backendData);
                // const responseData = await response.json();

                // console.log('Respuesta del backend al cargar:', responseData);
            } catch (error) {
                console.error('Error al llamar al backend durante la carga:', error);
            }
        };

        callBackendOnLoad();
    }, [entityState.selectedEntity, formData.customer?.receiver_rut]); // Usar entityState.selectedEntity como dependencia

    useEffect(() => {
        console.log('formData cambió:', formData);
        localStorage.setItem('invoiceFormData', JSON.stringify(formData));
    }, [formData]);

    const [errors, setErrors] = useState<string[]>([]);

    const validateForm = () => {
        const newErrors: string[] = [];

        // Validaciones de campos obligatorios
        if (!formData.details.issueDate) newErrors.push('La fecha de emisión es obligatoria.');

        // Verificar si formData.customer existe antes de acceder a sus propiedades
        if (!formData.customer) {
            newErrors.push('No hay información del cliente.');
            return false;
        }

        if (!formData.customer.receiver_rut) newErrors.push('El RUT del receptor es obligatorio.');
        if (!formData.customer.receiver_business_name) newErrors.push('La razón social del receptor es obligatoria.');

        // Validación de dirección considerando que puede ser un objeto anidado
        if (typeof formData.customer.receiver_address === 'string') {
            if (!formData.customer.receiver_address) newErrors.push('La dirección del receptor es obligatoria.');
            if (!formData.customer.receiver_district) newErrors.push('La comuna del receptor es obligatoria.');
            if (!formData.customer.receiver_city) newErrors.push('La ciudad del receptor es obligatoria.');
        } else if (typeof formData.customer.receiver_address === 'object') {
            if (!formData.customer.receiver_address?.address) newErrors.push('La dirección del receptor es obligatoria.');
            if (!formData.customer.receiver_address?.district?.name) newErrors.push('La comuna del receptor es obligatoria.');
            if (!formData.customer.receiver_address?.district?.city?.name) newErrors.push('La ciudad del receptor es obligatoria.');
        } else {
            newErrors.push('La dirección del receptor es obligatoria.');
        }

        if (!formData.customer.receiver_business_activity) newErrors.push('El giro del receptor es obligatorio.');

        if (!formData.items || formData.items.length === 0) newErrors.push('Debe haber al menos un ítem en los detalles.');

        // Verificar si formData.items existe antes de iterar
        if (formData.items) {
            formData.items.forEach((item: Product, index: number) => {
                if (!item.item_name) newErrors.push(`El nombre del ítem ${index + 1} es obligatorio.`);
                if (item.quantity <= 0) newErrors.push(`La cantidad del ítem ${index + 1} debe ser mayor a 0.`);
                if (item.item_total !== item.quantity * item.unit_price) newErrors.push(`El total del ítem ${index + 1} debe ser igual a cantidad * precio unitario.`);
            });
        }

        // Validar referencias si existen
        if (formData.references) {
            formData.references.forEach((ref: Reference, index: number) => {
                if (ref.reference_type) {
                    if (!ref.reference_folio) {
                        newErrors.push(`La referencia ${index + 1} requiere un folio`);
                    }
                    if (!ref.reference_date) {
                        newErrors.push(`La referencia ${index + 1} requiere una fecha`);
                    }
                }
            });
        }

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Mostrar las referencias actuales
        console.log('Referencias en formData al enviar el formulario:', formData.references);

        // Asegurarse de que formData.items existe y es un array
        if (!formData.items) {
            setFormData({ ...formData, items: [] });
            setErrors(['No hay items definidos']);
            return;
        }

        // Depurar los productos antes de validar
        console.log('Productos antes de validar:', formData.items);
        console.log('Cantidad de productos:', formData.items.length);

        // Verificar si hay productos con valores inválidos
        const invalidItems = formData.items.filter((item: Product) => !(item.price > 0 && item.quantity > 0));
        if (invalidItems.length > 0) {
            console.warn('⚠️ Productos con valores inválidos:', invalidItems);
        }

        if (validateForm()) {
            // Verificar si formData.customer existe
            if (!formData.customer) {
                setErrors(['No hay información del cliente']);
                return;
            }

            // Filtrar productos que tengan precio y cantidad
            const validItems = formData.items.filter((item: Product) => item.price > 0 && item.quantity > 0);

            console.log('Items originales:', formData.items);
            console.log('Items válidos:', validItems);
            console.log('Número de items válidos:', validItems.length);
            console.log('Estructura del primer item:', validItems.length > 0 ? validItems[0] : 'No hay items');
            console.log('Datos del cliente antes de enviar:', formData.customer);

            // Si no hay productos válidos después del filtro pero había productos en formData.items
            if (validItems.length === 0 && formData.items.length > 0) {
                console.error('❌ Todos los productos fueron filtrados como inválidos');
                alert('Hay productos con precio o cantidad inválidos. Por favor, revisa los datos de los productos.');
                return;
            }

            // Verificar si tenemos actividad económica
            if (!formData.customer.receiver_business_activity) {
                console.warn('⚠️ No se ha definido una actividad económica para el cliente');
            } else {
                console.log('✅ Actividad económica a enviar:', formData.customer.receiver_business_activity);
            }

            // Extraer correctamente los campos de dirección anidados
            let receiverAddress = '';
            let receiverDistrict = '';
            let receiverCity = '';

            if (typeof formData.customer.receiver_address === 'string') {
                receiverAddress = formData.customer.receiver_address;
                receiverDistrict = formData.customer.receiver_district || '';
                receiverCity = formData.customer.receiver_city || '';
            } else if (typeof formData.customer.receiver_address === 'object') {
                // Es un objeto anidado
                receiverAddress = formData.customer.receiver_address?.address || '';
                receiverDistrict = formData.customer.receiver_address?.district?.name || '';
                receiverCity = formData.customer.receiver_address?.district?.city?.name || '';
            }

            // Actualizar el formData con los items válidos
            const updatedFormData = {
                ...formData,
                items: validItems,
                date_issued: formData.details.issueDate,
                receiver_rut: formData.customer.receiver_rut,
                receiver_business_name: formData.customer.receiver_business_name,
                receiver_business_activity: formData.customer.receiver_business_activity,
                receiver_activity_code: formData.customer.receiver_activity_code,
                receiver_contact: formData.customer.receiver_contact,
                receiver_address: receiverAddress,
                receiver_district: receiverDistrict,
                receiver_city: receiverCity,
                // Asegurarse de que details sea un array donde el backend espera los items
                details: validItems,
                // Incluir las referencias
                references: formData.references || []
            };

            console.log('FormData final:', updatedFormData);
            console.log('Actividad económica en formData final:', updatedFormData.receiver_business_activity);
            console.log('Datos de dirección en formData final:', {
                dirección: updatedFormData.receiver_address,
                comuna: updatedFormData.receiver_district,
                ciudad: updatedFormData.receiver_city
            });
            console.log('Productos en formData final (items):', updatedFormData.items);
            console.log('Productos en formData final (details):', updatedFormData.details);
            console.log('Referencias en formData final:', updatedFormData.references);
            console.log('⚠️ IMPORTANTE: ¿El campo details es un array?', Array.isArray(updatedFormData.details));

            // Continuar con el flujo normal sin llamar al backend nuevamente
            onSubmit(updatedFormData);
        }
    };

    const handleCustomerDataChange = (customerData: CustomerData) => {
        console.log('Actualizando datos del cliente:', customerData);
        const updatedFormData = {
            ...formData,
            customer: customerData
        };
        setFormData(updatedFormData);
        localStorage.setItem('invoiceFormData', JSON.stringify(updatedFormData));
    };

    const handleIssuerDataChange = (issuerData: any) => {
        console.log('Actualizando datos del emisor:', issuerData);
        const updatedFormData = {
            ...formData,
            issuer: issuerData
        };
        setFormData(updatedFormData);
        localStorage.setItem('invoiceFormData', JSON.stringify(updatedFormData));
    };

    const handleDetailsDataChange = (detailsData: any) => {
        console.log('Actualizando detalles:', detailsData);
        setFormData((prev: FormData) => ({
            ...prev,
            details: detailsData
        }));
    };

    const handleItemsChange = (items: Product[]) => {
        console.log('Actualizando items:', items);
        const updatedFormData = {
            ...formData,
            items
        };
        setFormData(updatedFormData);
        localStorage.setItem('invoiceFormData', JSON.stringify(updatedFormData));
    };

    const handleReferencesChange = (references: Reference[]) => {
        console.log('Actualizando referencias:', references);
        const updatedFormData = {
            ...formData,
            references
        };
        setFormData(updatedFormData);
        localStorage.setItem('invoiceFormData', JSON.stringify(updatedFormData));
    };

    return (
        <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto">
                <form onSubmit={handleSubmit} className="space-y-8">
                    {errors.length > 0 && (
                        <div className="bg-red-100 text-red-700 p-4 rounded">
                            <ul>
                                {errors.map((error: string, index: number) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <IssuerInfo
                        data={formData.issuer}
                        onChange={handleIssuerDataChange}
                    />

                    <CustomerInfo
                        data={formData.customer}
                        onChange={handleCustomerDataChange}
                    />

                    <InvoiceDetails
                        data={formData.details}
                        onChange={handleDetailsDataChange}
                    />

                    <ProductList
                        products={formData.items}
                        onChange={handleItemsChange}
                        customer={{
                            receiver_rut: formData.customer.receiver_rut,
                            master_entity_id: formData.customer.master_entity_id
                        }}
                    />

                    <References
                        references={formData.references}
                        onChange={handleReferencesChange}
                    />

                    <div className="flex justify-end pt-6 border-t">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors disabled:bg-green-400 flex items-center space-x-2"
                        >
                            {isLoading ? 'Previsualizando...' : 'Previsualizar Documento'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}