import React from 'react';
import { TopBar } from './TopBar';
import { Sidebar } from './Sidebar';

interface LayoutProps {
    children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
    return (
        <div className="min-h-screen bg-gray-50">
            <TopBar />
            <div className="hidden md:block">
                <Sidebar />
            </div>
            <main className="md:ml-64">
                <div className="pt-16">
                    {children}
                </div>
            </main>
        </div>
    );
}