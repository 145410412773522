import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    // Redirigir al login si no hay token
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}