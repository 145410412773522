import React from 'react';
import { motion } from 'framer-motion';
import { Eye, FileText, Calendar, Package, ChevronRight, FileCheck, Download, Send } from 'lucide-react';
import { LoadingCircle } from './LoadingCircle';
import { TypingAnimation } from './TypingAnimation';
import { Invoice } from '../../types/invoice';
import { processMarkdownBold } from '../../utils/textProcessing';

interface ChatBubbleProps {
    role: 'user' | 'assistant';
    content: string;
    isTyping?: boolean;
    isInvoice?: boolean;
    isBulkInvoice?: boolean;
    onPreview?: () => void;
    onEmit?: () => void;
}

export function ChatBubble({ role, content, isTyping, isInvoice, isBulkInvoice, onPreview, onEmit }: ChatBubbleProps) {
    const isJson = isInvoice || isBulkInvoice;
    let parsedContent: any = null;

    const bubbleVariants = {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 }
    };

    if (isJson) {
        try {
            parsedContent = JSON.parse(content);
        } catch (e) {
            console.error('Error parsing JSON:', e);
        }
    }

    // Determinar el tipo de contenido
    const isGeneratedInvoice = parsedContent &&
        typeof parsedContent === 'object' &&
        'content' in parsedContent &&
        parsedContent.content &&
        'pdf_data' in parsedContent.content;

    const isBulkInvoiceList = Array.isArray(parsedContent);

    return (
        <motion.div
            variants={bubbleVariants}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                duration: 0.2,
                ease: "easeOut"
            }}
            className={`flex ${role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
        >
            <div
                className={`${role === 'user' ? 'max-w-[45%]' : 'w-[85%]'} rounded-2xl shadow-sm ${role === 'user'
                    ? 'bg-[#343541] text-white'
                    : 'bg-white border border-gray-100 text-gray-800'
                    } ${isJson ? 'overflow-hidden' : ''}`}
            >
                {isJson ? (
                    <div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            className="p-4"
                        >
                            {isGeneratedInvoice && (
                                <div className="mb-4">
                                    <div className="flex items-center justify-between mb-2">
                                        <div className="flex items-center space-x-2">
                                            <FileCheck className="h-5 w-5 text-green-600" />
                                            <h3 className="font-medium">
                                                Factura {parsedContent.content.id ? `#${parsedContent.content.id}` : 'Generada'}
                                            </h3>
                                        </div>
                                        <div className="flex items-center space-x-1 text-sm text-gray-500">
                                            <Calendar className="h-4 w-4" />
                                            <span>{parsedContent.content.date_issued || 'Fecha no disponible'}</span>
                                        </div>
                                    </div>
                                    <iframe
                                        src={parsedContent.content.pdf_data}
                                        className="w-full h-[2000px] border border-gray-200 rounded-lg"
                                        title="Vista previa de factura"
                                    />
                                </div>
                            )}

                            {isBulkInvoiceList && (
                                <div>
                                    <div className="flex items-center space-x-2 mb-4">
                                        <FileText className="h-5 w-5 text-green-600" />
                                        <h3 className="font-medium">Facturas a Emitir ({parsedContent.length})</h3>
                                    </div>
                                    <div className="space-y-3">
                                        {parsedContent.map((invoice: Invoice, index) => (
                                            <div key={index} className="bg-white rounded-lg border border-gray-200">
                                                <div className="p-3 flex items-center justify-between">
                                                    <div className="flex items-center space-x-3">
                                                        <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center text-gray-500">
                                                            {index + 1}
                                                        </div>
                                                        <div>
                                                            <div className="font-medium">{invoice.cliente}</div>
                                                            <div className="text-sm text-gray-500">
                                                                {invoice.items.length} {invoice.items.length === 1 ? 'ítem' : 'ítems'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center space-x-3">
                                                        <div className="text-right">
                                                            <div className="font-medium">${invoice.total.toLocaleString()}</div>
                                                            <div className="text-xs text-gray-500">{invoice.fecha}</div>
                                                        </div>
                                                        <ChevronRight className="h-4 w-4 text-gray-400" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </motion.div>
                        <div className="p-3 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3">
                            {isGeneratedInvoice && (
                                <a
                                    href={parsedContent.content.pdf_data}
                                    download={`factura_${parsedContent.content.id || 'generada'}.pdf`}
                                    className="flex items-center space-x-1.5 px-4 py-2 bg-green-600 text-white hover:bg-green-700 rounded-md text-sm transition-colors"
                                >
                                    <Download className="h-4 w-4" />
                                    <span>Descargar PDF</span>
                                </a>
                            )}
                            {isBulkInvoiceList && (
                                <button
                                    onClick={onEmit}
                                    className="flex items-center space-x-1.5 px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-md text-sm transition-colors"
                                >
                                    <Send className="h-4 w-4" />
                                    <span>Emitir Facturas</span>
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="p-4 text-sm">
                        {isTyping ? (
                            <LoadingCircle />
                        ) : (
                            <TypingAnimation text={processMarkdownBold(content)} />
                        )}
                    </div>
                )}
            </div>
        </motion.div>
    );
}