import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Header } from '../../components/Header';
import { CountryProvider } from '../../context/CountryContext';
import { Footer } from '../../components/Footer';
import { getPostBySlug } from '../../utils/blog';
import type { BlogPost } from '../../types/blog';

export default function BlogPostPage() {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<BlogPost | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (slug) {
            getPostBySlug(slug).then((post) => {
                if (post) {
                    setPost(post);
                }
            });
        }
    }, [slug]);

    if (!post) return null;

    return (
        <CountryProvider>
            <div className="min-h-screen bg-white">
                <Header />
                <main className="pt-24 pb-16">
                    <article className="container mx-auto px-4 max-w-4xl">
                        <Link
                            to="/blog"
                            className="inline-flex items-center space-x-2 text-gray-600 hover:text-gray-900 mb-8"
                        >
                            <ArrowLeft className="h-4 w-4" />
                            <span>Volver al blog</span>
                        </Link>

                        <img
                            src={post.image}
                            alt={post.title}
                            className="w-full h-96 object-cover rounded-2xl mb-8"
                        />

                        <header className="mb-12">
                            <div className="flex flex-wrap gap-2 mb-4">
                                {post.tags.map((tag) => (
                                    <span
                                        key={tag}
                                        className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>

                            <h1 className="text-4xl font-bold text-gray-900 mb-4">
                                {post.title}
                            </h1>

                            <div className="flex items-center space-x-4 text-gray-500">
                                <span>{post.author}</span>
                                <span>•</span>
                                <time>{new Date(post.date).toLocaleDateString('es-CL')}</time>
                                <span>•</span>
                                <span>{post.readingTime}</span>
                            </div>
                        </header>

                        <div
                            className="prose prose-green prose-lg max-w-none"
                            dangerouslySetInnerHTML={{ __html: post.content }}
                        >
                        </div>
                    </article>
                </main>
                <Footer />
            </div>
        </CountryProvider>
    );
}