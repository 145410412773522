import React, { useState, useRef, useEffect } from 'react';
import { Mail, MessageSquare } from 'lucide-react';
import { DemoRequestModal } from './modals/DemoRequestModal';
import { useChat } from '../hooks/useChat';
import { useCountry } from '../context/CountryContext';

function Contact() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openChat } = useChat();
  const { country } = useCountry();
  const chatButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Add click event listener to the chat button
    const handleClick = () => {
      openChat();
    };

    const button = chatButtonRef.current;
    if (button) {
      button.addEventListener('click', handleClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, [openChat]);

  return (
    <section id="contacto" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">
            {country === 'chile'
              ? '¿Listo para simplificar tu facturación?'
              : '¿Listo para simplificar tu facturación?'}
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            {country === 'chile'
              ? 'Solicita una demo y descubre cómo Tu Pana puede ayudarte a ahorrar tiempo en tu proceso de facturación.'
              : 'Solicita una demo y descubre cómo Tu Pana puede ayudarte a ahorrar tiempo en tu proceso de facturación.'}
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-3 rounded-lg hover:bg-green-700 transition"
            >
              <Mail className="h-5 w-5" />
              <span>{country === 'chile' ? 'Solicitar Demo' : 'Solicitar Demo'}</span>
            </button>
            <button
              ref={chatButtonRef}
              onClick={openChat}
              className="flex items-center justify-center space-x-2 border-2 border-green-600 text-green-600 px-8 py-3 rounded-lg hover:bg-green-50 transition"
            >
              <MessageSquare className="h-5 w-5" />
              <span>{country === 'chile' ? 'Chatear con Ventas' : 'Chatear con Ventas'}</span>
            </button>
          </div>
        </div>
        <DemoRequestModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </section>
  );
}

export { Contact };