import React from 'react';
import { HandshakeIcon, Mail, Phone, MapPin } from 'lucide-react';
import { useCountry } from '../context/CountryContext';

export function Footer() {
  const { country } = useCountry();

  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <HandshakeIcon className="h-8 w-8 text-green-400" />
              <span className="text-2xl font-bold">Tu Pana</span>
            </div>
            <p className="text-gray-400">
              {country === 'chile'
                ? 'Simplificando la facturación para empresas chilenas.'
                : 'Simplificando la facturación para empresas españolas.'}
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Contacto</h3>
            <ul className="space-y-2 text-gray-400">
              <li className="flex items-center space-x-2">
                <Mail className="h-5 w-5 text-green-400" />
                <span>{country === 'chile' ? 'contacto@tupana.ai' : 'contacto@tupana.ai'}</span>
              </li>
              <li className="flex items-center space-x-2">
                <Phone className="h-5 w-5 text-green-400" />
                <span>{country === 'chile' ? '+569 63000871' : '+34 912 456 789'}</span>
              </li>
              <li className="flex items-center space-x-2">
                <MapPin className="h-5 w-5 text-green-400" />
                <span>{country === 'chile' ? 'Santiago, Chile' : 'Madrid, España'}</span>
              </li>
            </ul>
          </div>

          {/* Rest of the footer remains the same */}
        </div>
      </div>
    </footer>
  );
}