import React, { useState, useCallback } from 'react';
import { Download, AlertCircle, HelpCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ExamplesModal } from './examples/ExamplesModal';
import { FormatExplanation } from './components/FormatExplanation';
import { ExcelTable } from './components/ExcelTable';
import { FileUploader } from './components/FileUploader';
import { api } from '../../../../services/api';
import { useEntity } from '../../../../context/EntityContext';

interface UploadResponse {
    message: string;
    documents_created: number;
    documents: Array<{
        id: number;
        folio: string;
        date_issued: string;
        receiver_name: string;
        receiver_tax_id: string;
        amount_with_iva: string;
        status: string;
        pdf_file: string | null;
        pdf_base64: string | null;
        created_at: string;
        updated_at: string;
        references: Array<{
            reference_type: string;
            reference_folio: string;
            reference_date: string;
            reference_reason: string;
        }>;
        dte_type_code: number;
        dte_type_description: string;
    }>;
}

interface ExcelUploadProps {
    onComplete?: (data: UploadResponse) => void;
}

export function ExcelUpload({ onComplete }: ExcelUploadProps) {
    const navigate = useNavigate();
    const { state: { selectedEntity } } = useEntity();
    const [error, setError] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showExamples, setShowExamples] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleDownloadTemplate = async () => {
        try {
            const entityId = localStorage.getItem('selectedEntityId');
            if (!entityId) {
                throw new Error('No hay entidad seleccionada');
            }

            const response = await api.get(`/master-entities/${entityId}/download-template/`);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Template Facturas Pana.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error al descargar template:', error);
            setError('Error al descargar el template. Por favor, intente nuevamente.');
        }
    };

    const handleFile = useCallback(async (file: File) => {
        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xlsm')) {
            setError('Solo se permiten archivos Excel (.xlsx, .xlsm)');
            return;
        }

        setIsProcessing(true);
        setError(null);
        setSelectedFile(file);
        setIsProcessing(false);
    }, []);

    const handlePreview = async () => {
        if (!selectedFile) {
            setError('Por favor, sube un archivo Excel antes de continuar');
            return;
        }

        setIsProcessing(true);
        setError(null);

        try {
            if (!selectedEntity?.id) {
                throw new Error('No hay entidad seleccionada');
            }

            const formData = new FormData();
            formData.append('file', selectedFile);

            const response = await api.post(`/master-entities/${selectedEntity.id}/documents/upload-v2/`, formData);
            const data = await response.json();

            if (onComplete) {
                onComplete(data);
            }

            navigate('/platform/bulk-invoice-preview', {
                state: {
                    documents: data.documents,
                    documentsCreated: data.documents_created,
                    message: data.message
                }
            });
        } catch (error) {
            console.error('Error al procesar el archivo:', error);
            setError(error instanceof Error ? error.message : 'Error al procesar el archivo');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="space-y-8">
            {error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                    <div className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-400 mt-0.5" />
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">
                                Error en el archivo
                            </h3>
                            <div className="mt-2 text-sm text-red-700 whitespace-pre-line">
                                {error}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="space-y-6">
                <div className="text-center">
                    <h3 className="text-lg font-medium mb-4">Formato del Excel</h3>
                    <div className="flex justify-center items-center space-x-2">
                        <p className="text-gray-600">
                            El archivo Excel debe seguir el siguiente formato:
                        </p>
                        <button
                            onClick={() => setShowExamples(true)}
                            className="text-green-600 hover:text-green-700 flex items-center space-x-1"
                        >
                            <HelpCircle className="h-5 w-5" />
                            <span>Ver ejemplos</span>
                        </button>
                    </div>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                    <div>
                        <ExcelTable />
                        <FormatExplanation />
                    </div>
                </div>

                <div className="flex justify-center">
                    <button
                        onClick={handleDownloadTemplate}
                        className="flex items-center space-x-2 px-4 py-2 text-green-600 border border-green-600 rounded-lg hover:bg-green-50"
                    >
                        <Download className="h-5 w-5" />
                        <span>Descargar Plantilla Excel</span>
                    </button>
                </div>

                <FileUploader
                    onFileSelect={handleFile}
                    isProcessing={isProcessing}
                    isDragging={isDragging}
                    setIsDragging={setIsDragging}
                />

                {selectedFile && (
                    <div className="text-center text-sm text-gray-600">
                        Archivo seleccionado: {selectedFile.name}
                    </div>
                )}
            </div>

            <ExamplesModal isOpen={showExamples} onClose={() => setShowExamples(false)} />

            <div className="flex justify-center mt-8 pt-8 border-t">
                <button
                    onClick={handlePreview}
                    disabled={isProcessing || !selectedFile}
                    className={`px-6 py-3 rounded-lg transition-colors flex items-center space-x-2 ${isProcessing || !selectedFile
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-green-600 hover:bg-green-700 text-white'
                        }`}
                >
                    <span>
                        {isProcessing
                            ? 'Procesando...'
                            : 'Previsualizar Facturas'
                        }
                    </span>
                </button>
            </div>
        </div>
    );
}