import React, { useState, useEffect, useRef } from 'react';
import { User, Plus } from 'lucide-react';
import { api } from '../../../../services/api';
import { CreateCustomerModal } from '../modals/CreateCustomerModal';
import { CustomerSearch } from './components/CustomerSearch';
import { AddressSelector } from './components/AddressSelector';
import { ActivitySelector } from './components/ActivitySelector';
import { CustomerData, CustomerListItem, Address, Activity } from './types';

interface CustomerInfoProps {
    data: CustomerData;
    onChange: (data: CustomerData) => void;
}

export function CustomerInfo({ data, onChange }: CustomerInfoProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const rutInputRef = useRef<HTMLInputElement>(null);
    const addressDropdownRef = useRef<HTMLDivElement>(null);
    const activityDropdownRef = useRef<HTMLDivElement>(null);
    const [customers, setCustomers] = useState<CustomerListItem[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAddressDropdown, setShowAddressDropdown] = useState(false);
    const [showActivityDropdown, setShowActivityDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerListItem | null>(null);
    const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const [isManualAddress, setIsManualAddress] = useState(false);
    const [isManualActivity, setIsManualActivity] = useState(false);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [manualAddress, setManualAddress] = useState({
        address: '',
        district: { name: '', city: { name: '' } }
    });
    const [manualActivity, setManualActivity] = useState({
        name: '',
        code: ''
    });
    const [newCustomerRut, setNewCustomerRut] = useState('');

    useEffect(() => {
        const fetchCustomersList = async () => {
            try {
                const entityId = localStorage.getItem('selectedEntityId');
                if (!entityId) {
                    throw new Error('No hay entidad seleccionada');
                }

                const response = await api.get(`/master-entities/${entityId}/customers/`);
                if (!response.ok) {
                    throw new Error('Error al cargar la lista de clientes');
                }

                const data = await response.json();
                setCustomers(data);
            } catch (err) {
                console.error('Error loading customers:', err);
                setError('Error al cargar la lista de clientes');
            }
        };

        fetchCustomersList();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
            if (addressDropdownRef.current && !addressDropdownRef.current.contains(event.target as Node)) {
                setShowAddressDropdown(false);
            }
            if (activityDropdownRef.current && !activityDropdownRef.current.contains(event.target as Node)) {
                setShowActivityDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    const handleCustomerSelect = async (customer: CustomerListItem) => {
        setIsLoading(true);
        setError(null);
        setSelectedCustomer(customer);
        setIsManualAddress(false);
        setIsManualActivity(false);

        try {
            const selectedEntityId = localStorage.getItem('selectedEntityId');
            if (!selectedEntityId) {
                throw new Error('No entity selected');
            }

            const response = await api.get(`/master-entities/${customer.master_entity_id}/`);
            if (!response.ok) {
                throw new Error('Error al obtener datos del SII');
            }

            const customerData = await response.json();
            console.log('Customer data:', customerData);

            // Set addresses and activities
            setSelectedCustomer({
                ...customer,
                addresses: customerData.addresses?.length ? customerData.addresses : [],
                activities: customerData.activities?.length ? customerData.activities : []
            });

            // Set first address and activity as default
            const defaultAddress = customerData.addresses?.[0] || null;
            const defaultActivity = customerData.activities?.[0] || null;
            setSelectedAddress(defaultAddress || null);
            setSelectedActivity(defaultActivity || null);

            onChange({
                ...data,
                receiver_rut: customer.tax_id,
                receiver_business_name: customer.name,
                receiver_address: defaultAddress ? {
                    address: defaultAddress.address,
                    district: {
                        name: defaultAddress.district.name,
                        city: {
                            name: defaultAddress.district.city.name
                        }
                    }
                } : {
                    address: customer.address || '',
                    district: {
                        name: customer.district || '',
                        city: {
                            name: customer.city || ''
                        }
                    }
                },
                receiver_business_activity: defaultActivity?.name || customer.economic_activity || '',
                receiver_activity_code: defaultActivity?.code || customer.activity_code || '',
                master_entity_id: customer.master_entity_id.toString()
            });
        } catch (err) {
            setError('Error al obtener los datos del cliente');
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewCustomerRut = async (rut: string) => {
        setIsNewCustomer(true);
        if (rutInputRef.current) {
            rutInputRef.current.focus();
        }
        const formattedRut = formatRutInput(rut);
        if (!formattedRut) return;

        try {
            const cleanRut = formattedRut.replace(/\./g, '').replace(/-/g, '');
            const rutNumber = parseInt(cleanRut.slice(0, -1));

            const response = await api.post(`/master-entities/`, {
                tax_id_number: rutNumber
            });

            if (!response.ok) {
                throw new Error('Error al buscar el cliente');
            }

            const customerData = await response.json();
            onChange({
                ...data,
                receiver_rut: formattedRut,
                receiver_business_name: customerData.name || '',
                receiver_address: {
                    address: customerData.address || '',
                    district: {
                        name: customerData.district || '',
                        city: {
                            name: customerData.city || ''
                        }
                    }
                },
                receiver_business_activity: customerData.economic_activity || '',
                receiver_activity_code: customerData.activity_code || '',
                receiver_contact: customerData.contact || ''
            });
        } catch (error) {
            console.error('Error al buscar cliente:', error);
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <User className="h-5 w-5 text-green-600" />
                    <h3 className="text-lg font-medium ml-2">
                        Información del Cliente
                        <span className="text-red-500 ml-1">*</span>
                    </h3>
                </div>
            </div>

            <CustomerSearch
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                customers={customers}
                selectedCustomerRut={data.receiver_rut}
                onCustomerSelect={handleCustomerSelect}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                onNewCustomerRut={handleNewCustomerRut}
                dropdownRef={dropdownRef}
            />

            {data.receiver_rut && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">RUT</label>
                        <input
                            ref={rutInputRef}
                            type="text"
                            value={data.receiver_rut}
                            disabled={!isNewCustomer}
                            onChange={(e) => {
                                const formattedRut = formatRutInput(e.target.value);
                                onChange({ ...data, receiver_rut: formattedRut });
                            }}
                            onBlur={(e) => {
                                if (e.target.value && isNewCustomer) {
                                    handleNewCustomerRut(e.target.value);
                                }
                            }}
                            className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${isNewCustomer ? 'bg-white' : 'bg-gray-50'
                                }`}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Razón Social</label>
                        <input
                            type="text"
                            value={data.receiver_business_name}
                            disabled
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                        />
                    </div>

                    <div className="md:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Dirección</label>
                        <AddressSelector
                            ref={addressDropdownRef}
                            selectedAddress={selectedAddress}
                            addresses={selectedCustomer?.addresses}
                            onAddressSelect={(address) => {
                                setSelectedAddress(address);
                                onChange({
                                    ...data,
                                    receiver_address: {
                                        address: address.address,
                                        district: {
                                            name: address.district.name,
                                            city: {
                                                name: address.district.city.name
                                            }
                                        }
                                    }
                                });
                            }}
                            showDropdown={showAddressDropdown}
                            setShowDropdown={setShowAddressDropdown}
                            isManualAddress={isManualAddress}
                            setIsManualAddress={setIsManualAddress}
                            manualAddress={manualAddress}
                            onManualAddressChange={(newAddress) => {
                                setManualAddress(newAddress);
                                onChange({
                                    ...data,
                                    receiver_address: newAddress
                                });
                            }}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Giro</label>
                        <ActivitySelector
                            ref={activityDropdownRef}
                            selectedActivity={selectedActivity}
                            activities={selectedCustomer?.activities}
                            onActivitySelect={(activity) => {
                                setSelectedActivity(activity);
                                onChange({
                                    ...data,
                                    receiver_business_activity: activity.name,
                                    receiver_activity_code: activity.code
                                });
                            }}
                            showDropdown={showActivityDropdown}
                            setShowDropdown={setShowActivityDropdown}
                            isManualActivity={isManualActivity}
                            setIsManualActivity={setIsManualActivity}
                            manualActivity={manualActivity}
                            onManualActivityChange={(newActivity) => {
                                setManualActivity(newActivity);
                                onChange({
                                    ...data,
                                    receiver_business_activity: newActivity.name,
                                    receiver_activity_code: newActivity.code
                                });
                            }}
                        />
                    </div>
                </div>
            )}

            {isLoading && (
                <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-green-600"></div>
                </div>
            )}

            {error && (
                <div className="text-red-600 text-sm bg-red-50 p-4 rounded-md">
                    {error}
                </div>
            )}
        </div>
    );
}