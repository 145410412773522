import { FileSpreadsheet, CheckCircle, Bot, FileText, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCountry } from '../context/CountryContext';
import { useState } from 'react';
import { DemoRequestModal } from './modals/DemoRequestModal';
import { CountrySelector } from './CountrySelector';

export function Hero() {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
  const { country, translations } = useCountry();

  return (
    <div className="pt-16 pb-12 bg-gradient-to-br from-green-50 to-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 mb-8 md:mb-0">
            <div className="flex items-start space-x-6 mb-6">
              <div className="w-full">
                <h2 className="text-4xl md:text-6xl font-bold text-gray-900 text-center md:text-left mb-6">
                  {country === 'chile' ? '¿Chato de facturar 🤦‍♂️?' : '¿Cansado de facturar 🤦‍♂️?'}
                </h2>

                <div className="flex flex-col md:flex-row items-center md:items-center">
                  <div className="text-center md:text-left mb-6 md:mb-0">
                    <h1 className="text-3xl md:text-6xl font-bold text-gray-900">
                      Acá está{' '}
                      <span className="text-green-600">tu Pana</span>
                    </h1>
                  </div>
                  <div className="relative md:ml-16">
                    <div className="absolute -inset-1.5 bg-gradient-to-r from-green-600 to-green-400 rounded-full blur opacity-25"></div>
                    <img
                      src="/prueba.png"
                      alt="Tu Pana"
                      className="relative w-24 h-24 md:w-40 md:h-40 rounded-full object-cover border-4 border-green-600"
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="text-xl text-gray-600 mb-8 text-center md:text-left">
              {country === 'chile'
                ? 'Factura de forma masiva y simple. Sube tu Excel y nosotros nos encargamos del resto.'
                : 'Factura de forma masiva y sencilla. Sube tu Excel y nosotros nos encargamos del resto.'}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
              <Link
                to="/signup"
                className="bg-green-600 text-white px-10 py-4 rounded-lg hover:bg-green-700 transition text-lg text-center font-medium"
              >
                {country === 'chile' ? 'Crear Cuenta' : 'Crear Cuenta'}
              </Link>
              <button
                onClick={() => setIsDemoModalOpen(true)}
                className="border-2 border-green-600 text-green-600 px-10 py-4 rounded-lg hover:bg-green-50 transition text-lg text-center font-medium"
              >
                {country === 'chile' ? 'Solicitar Demo' : 'Solicitar Demo'}
              </button>
            </div>
          </div>

          <div className="md:w-1/3 flex justify-start">
            <div className="relative">
              <div className="relative h-[600px]">
                {/* Excel Upload */}
                <div className="absolute -left-40 top-0 bg-white p-6 rounded-lg shadow-xl transform -rotate-6 hover:rotate-0 transition-transform duration-300 z-20 w-[300px]">
                  <div className="flex items-center space-x-4 mb-4">
                    <FileSpreadsheet className="h-8 w-8 text-green-600" />
                    <span className="text-lg font-semibold">{country === 'chile' ? 'facturacion-masiva.xlsx' : 'facturacion-masiva.xlsx'}</span>
                  </div>
                  <div className="space-y-3">
                    {[1, 2, 3].map((item) => (
                      <div key={item} className="flex items-center space-x-2">
                        <CheckCircle className="h-5 w-5 text-green-500" />
                        <div className="h-4 bg-gray-200 rounded w-48"></div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Purchase Orders */}
                <div className="absolute -left-50 top-[120px] bg-white p-6 rounded-lg shadow-xl transform rotate-3 hover:rotate-0 transition-transform duration-300 z-30 w-[300px]">
                  <div className="flex items-center space-x-4 mb-4">
                    <FileText className="h-8 w-8 text-blue-600" />
                    <span className="text-lg font-semibold">{country === 'chile' ? 'Órdenes de Compra' : 'Pedidos'}</span>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center justify-between text-sm">
                      <span>{country === 'chile' ? 'OC-2024-001' : 'PED-2024-001'}</span>
                      <span className="text-green-600">{country === 'chile' ? 'Procesada ✓' : 'Procesado ✓'}</span>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span>{country === 'chile' ? 'OC-2024-002' : 'PED-2024-002'}</span>
                      <span className="text-blue-600">{country === 'chile' ? 'En proceso...' : 'En proceso...'}</span>
                    </div>
                  </div>
                </div>

                {/* AI Chat */}
                <div className="absolute left-20 top-[220px] bg-white p-6 rounded-lg shadow-xl transform rotate-6 hover:rotate-0 transition-transform duration-300 z-40 w-[300px]">
                  <div className="flex items-center space-x-4 mb-4">
                    <Bot className="h-8 w-8 text-purple-600" />
                    <span className="text-lg font-semibold">Tu Pana</span>
                  </div>
                  <div className="space-y-2 text-sm">
                    <div className="bg-purple-50 p-2 rounded-lg">
                      {country === 'chile' ? '¿Puedes procesar esta OC?' : '¿Puedes procesar este pedido?'}
                    </div>
                    <div className="bg-green-50 p-2 rounded-lg">
                      {country === 'chile' ? '¡Claro! Procesando documento...' : '¡Por supuesto! Procesando documento...'}
                    </div>
                  </div>
                </div>

                {/* API Integration */}
                <div className="absolute left-40 bottom-20 bg-white p-6 rounded-lg shadow-xl transform -rotate-3 hover:rotate-0 transition-transform duration-300 z-10 w-[300px]">
                  <div className="flex items-center space-x-4 mb-4">
                    <Zap className="h-8 w-8 text-yellow-600" />
                    <span className="text-lg font-semibold">API REST</span>
                  </div>
                  <div className="font-mono text-xs bg-gray-800 text-green-400 p-2 rounded">
                    POST /api/v1/invoices
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoRequestModal
        isOpen={isDemoModalOpen}
        onClose={() => setIsDemoModalOpen(false)}
      />
    </div>
  );
}

