import React, { useMemo } from 'react';
import { FileText, Eye, Send, FileSpreadsheet } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

interface Step {
    icon: React.ElementType;
    title: string;
    description: string;
    path: string;
}

interface InvoiceStepsProps {
    currentStep: number;
    mode: 'single' | 'bulk';
}

export function InvoiceSteps({ currentStep, mode }: InvoiceStepsProps) {
    const location = useLocation();

    const steps = useMemo(() => {
        const stepsConfig = {
            single: [
                {
                    icon: FileText,
                    title: 'Datos de Factura',
                    description: 'Ingresa los detalles',
                    path: '/platform/invoice-form'
                },
                {
                    icon: Eye,
                    title: 'Previsualización',
                    description: 'Revisa antes de emitir',
                    path: '/platform/invoice-preview'
                },
                {
                    icon: Send,
                    title: 'Emisión',
                    description: 'Emite al SII',
                    path: '/platform/invoice-signature'
                }
            ],
            bulk: [
                {
                    icon: FileSpreadsheet,
                    title: 'Subir Excel',
                    description: 'Carga tu archivo',
                    path: '/platform/bulk-invoice-excel'
                },
                {
                    icon: Eye,
                    title: 'Previsualización',
                    description: 'Revisa los documentos',
                    path: '/platform/bulk-invoice/preview'
                },
                {
                    icon: Send,
                    title: 'Emisión',
                    description: 'Emite al SII',
                    path: '/platform/bulk-invoice/emit'
                }
            ]
        };
        return stepsConfig[mode] || [];
    }, [mode]);

    if (!steps.length) {
        return null;
    }

    const isStepActive = (index: number) => {
        return index <= currentStep - 1 || location.pathname === '/platform/invoice-signature';
    };

    const isLineActive = (index: number) => {
        return index === 0 && currentStep >= 2 || // Line after first step
            index === 1 && (currentStep >= 3 || location.pathname === '/platform/invoice-signature');    // Line after second step
    };

    return (
        <div className="flex justify-center py-8">
            <div className="flex items-center">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <Link
                            to={step.path}
                            className={`flex items-center ${currentStep >= index ? 'cursor-pointer' : 'cursor-not-allowed pointer-events-none'
                                }`}
                        >
                            <div className="flex flex-col items-center">
                                <div
                                    className={`w-8 h-8 rounded-full flex items-center justify-center ${isStepActive(index)
                                            ? 'bg-green-600 text-white'
                                            : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    <step.icon className="h-4 w-4" />
                                </div>
                                <h3 className={`text-sm font-medium mt-2 ${isStepActive(index)
                                        ? 'text-green-600'
                                        : 'text-gray-600'
                                    }`}>
                                    {step.title}
                                </h3>
                                <p className="text-xs text-gray-500 text-center max-w-[120px]">
                                    {step.description}
                                </p>
                            </div>
                        </Link>
                        {index < steps.length - 1 && (
                            <div className="mx-4">
                                <div
                                    className={`w-16 h-0.5 ${isLineActive(index)
                                            ? 'bg-green-600'
                                            : 'bg-gray-200'
                                        }`}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}