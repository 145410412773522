import { useState } from 'react';
import { HandshakeIcon, Settings, LogOut, Menu, X } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { api } from '../../../services/api';
import { EntitySelector } from '../EntitySelector';
import { useEntity } from '../../../context/EntityContext';

export function TopBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { dispatch } = useEntity();

    const handleLogout = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (!refreshToken || !accessToken) {
                throw new Error('No hay tokens');
            }

            const response = await api.post('/auth/logout/', {
                refresh: refreshToken
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error en logout:', errorData);
                throw new Error(errorData.error || 'Error al cerrar sesión');
            }

            // Primero limpiamos el estado del contexto
            dispatch({ type: "CLEAR_ENTITY" });

            // Luego limpiamos localStorage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('selectedEntityId');
            localStorage.removeItem('selectedEntityName');
            localStorage.removeItem('masterEntities');

            navigate('/login');
        } catch (error) {
            console.error('Error en logout:', error);
            // Aún en caso de error, limpiamos el estado y localStorage
            dispatch({ type: "CLEAR_ENTITY" });
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('selectedEntityId');
            localStorage.removeItem('selectedEntityName');
            localStorage.removeItem('masterEntities');
            navigate('/login');
        }
    };

    return (
        <div className="fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 z-50">
            <div className="h-full flex items-center justify-between px-4">
                <div className="flex items-center space-x-8">
                    <Link to="/" className="flex items-center space-x-2">
                        <HandshakeIcon className="h-8 w-8 text-green-600" />
                        <span className="text-xl font-semibold text-gray-900">Pana</span>
                    </Link>
                    <div className="hidden md:flex items-center space-x-8">
                        <Link
                            to="/platform/your-pana"
                            className={`font-medium ${location.pathname === '/platform/your-pana'
                                ? 'text-green-600'
                                : 'text-gray-600 hover:text-green-600'
                                }`}
                        >
                            Tu Pana
                        </Link>
                        <Link
                            to="/platform/invoices-issued"
                            className={`font-medium ${location.pathname.includes('/platform/invoices')
                                ? 'text-green-600'
                                : 'text-gray-600 hover:text-green-600'
                                }`}
                        >
                            Tus Facturas
                        </Link>
                        <Link
                            to="/platform/customers"
                            className={`font-medium ${location.pathname.includes('/platform/customers')
                                ? 'text-green-600'
                                : 'text-gray-600 hover:text-green-600'
                                }`}
                        >
                            Tus Datos
                        </Link>
                    </div>
                </div>

                <div className="flex items-center space-x-4">
                    <EntitySelector />

                    <div className="hidden md:flex items-center space-x-2">
                        <Link
                            to="/settings/sii-credentials"
                            className={`p-2 rounded-md hover:bg-gray-100 transition-colors ${location.pathname === '/settings/sii-credentials' ? 'text-green-600 bg-green-50'
                                : 'text-gray-600 hover:text-green-600 hover:bg-gray-50'}`}
                        >
                            <Settings className="h-5 w-5 text-gray-500" />
                        </Link>

                        <button
                            onClick={handleLogout}
                            className="p-2 rounded-md hover:bg-gray-100 transition-colors"
                        >
                            <LogOut className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>

                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors"
                    >
                        {isMenuOpen ? (
                            <X className="h-6 w-6 text-gray-500" />
                        ) : (
                            <Menu className="h-6 w-6 text-gray-500" />
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            {isMenuOpen && (
                <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-200">
                    <div className="py-2">
                        <Link
                            to="/platform/your-pana"
                            className={`block px-4 py-2 hover:bg-gray-100 transition-colors ${location.pathname === '/platform/your-pana' ? 'text-green-600' : 'text-gray-600'}`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Tu Pana
                        </Link>
                        <Link
                            to="/platform/invoices-issued"
                            className={`block px-4 py-2 hover:bg-gray-100 transition-colors ${location.pathname.includes('/platform/invoices') ? 'text-green-600' : 'text-gray-600'}`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Tus Facturas
                        </Link>
                        <Link
                            to="/platform/customers"
                            className={`block px-4 py-2 hover:bg-gray-100 transition-colors ${location.pathname.includes('/platform/customers') ? 'text-green-600' : 'text-gray-600'}`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Tus Datos
                        </Link>
                        <Link
                            to="/settings/sii-credentials"
                            className={`block px-4 py-2 hover:bg-gray-100 transition-colors ${location.pathname === '/settings' ? 'bg-gray-100' : ''}`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <div className="flex items-center space-x-2">
                                <Settings className="h-5 w-5 text-gray-500" />
                                <span className="text-gray-700">Configuración</span>
                            </div>
                        </Link>

                        <button
                            onClick={() => {
                                setIsMenuOpen(false);
                                handleLogout();
                            }}
                            className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
                        >
                            <div className="flex items-center space-x-2">
                                <LogOut className="h-5 w-5 text-gray-500" />
                                <span className="text-gray-700">Cerrar sesión</span>
                            </div>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
