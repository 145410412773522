import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Search } from 'lucide-react';

interface HeaderProps {
    documentsCount: number;
    documentsCreated: number;
    searchTerm: string;
    onSearchChange: (term: string) => void;
}

export function Header({ documentsCount, documentsCreated, searchTerm, onSearchChange }: HeaderProps) {
    return (
        <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-semibold text-gray-900">
                    Previsualización de Facturas
                </h3>
                {documentsCount > 0 && (
                    <Link
                        to="/platform/bulk-invoice-signature"
                        state={{ documents: documentsCount }}
                        className="inline-flex items-center px-6 py-2.5 bg-green-600 text-white text-sm font-medium rounded-lg hover:bg-green-700 transition-colors shadow-sm"
                    >
                        <span>Continuar a Emisión</span>
                        <ArrowRight className="ml-2 h-4 w-4" />
                    </Link>
                )}
            </div>

            <div className="flex items-center justify-between">
                <div className="relative flex-1 max-w-lg">
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => onSearchChange(e.target.value)}
                        placeholder="Buscar por nombre o RUT..."
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                    />
                </div>
                <div className="ml-4 text-sm text-gray-600">
                    {documentsCreated} documentos creados
                </div>
            </div>
        </div>
    );
}