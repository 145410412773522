import React from 'react';
import { Filter } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export interface Filters {
    hasCreditNote?: boolean;
    paymentStatus?: 'paid' | 'unpaid' | undefined;
}

interface CollapsibleFiltersProps {
    isOpen: boolean;
    onToggle: () => void;
    filters: Filters;
    onFilterChange: (filters: Filters) => void;
}

export function CollapsibleFilters({ isOpen, onToggle, filters, onFilterChange }: CollapsibleFiltersProps) {
    return (
        <div className="border-b border-gray-100">
            <button
                onClick={onToggle}
                className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50/50 transition-all group"
            >
                <div className="flex items-center space-x-3">
                    <div className="w-8 h-8 rounded-lg bg-green-50 flex items-center justify-center text-green-600 group-hover:bg-green-100 transition-colors">
                        <Filter className="h-5 w-5" />
                    </div>
                    <span className="font-medium text-gray-900">Filtros</span>
                </div>
                <div className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="overflow-hidden"
                    >
                        <div className="px-6 py-6 bg-gradient-to-b from-gray-50/50 to-white border-t border-gray-100">
                            <div className="flex items-center justify-between">
                                {/* Notas de Crédito */}
                                <div className="flex items-center space-x-12">
                                    <h3 className="text-sm font-medium text-gray-900 w-48">Estado de Nota de Crédito</h3>
                                    <div className="flex items-center space-x-6">
                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.hasCreditNote === true}
                                                onChange={() => onFilterChange({ ...filters, hasCreditNote: true })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">Con NC</span>
                                        </label>

                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.hasCreditNote === false}
                                                onChange={() => onFilterChange({ ...filters, hasCreditNote: false })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">Sin NC</span>
                                        </label>

                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.hasCreditNote === undefined}
                                                onChange={() => onFilterChange({ ...filters, hasCreditNote: undefined })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">Todos</span>
                                        </label>
                                    </div>
                                </div>

                                {/* Estado de Pago */}
                                <div className="flex items-center space-x-12">
                                    <h3 className="text-sm font-medium text-gray-900 w-32">Estado de Pago</h3>
                                    <div className="flex items-center space-x-6">
                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.paymentStatus === 'paid'}
                                                onChange={() => onFilterChange({ ...filters, paymentStatus: 'paid' })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">Pagado</span>
                                        </label>

                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.paymentStatus === 'unpaid'}
                                                onChange={() => onFilterChange({ ...filters, paymentStatus: 'unpaid' })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">No Pagado</span>
                                        </label>

                                        <label className="flex items-center space-x-2 cursor-pointer">
                                            <input
                                                type="radio"
                                                checked={filters.paymentStatus === undefined}
                                                onChange={() => onFilterChange({ ...filters, paymentStatus: undefined })}
                                                className="h-4 w-4 text-green-600 border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-green-500/20"
                                            />
                                            <span className="text-sm font-medium text-gray-700">Todos</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}