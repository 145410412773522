import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/index';
import './index.css';
import { EntityProvider } from './context/EntityContext';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <EntityProvider>
    <RouterProvider router={router} />
  </EntityProvider>
);