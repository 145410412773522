import { api } from '../services/api';
import { Dispatch } from 'react';

interface MasterEntity {
    id: string;
    name: string;
    tax_id: string;
}

export const refreshDocuments = async (entityId: string) => {
    const response = await api.post('/scrapers/documents/', {
        master_entity_id: entityId
    });

    return await response.json();
};

export const initializeCompanyFromProfile = async (dispatch: Dispatch<any>, companyRut?: string) => {
    try {
        const response = await api.get('/auth/profile/');
        const profileData = await response.json();
        const entities = profileData.master_entities || [];

        if (entities.length > 0) {
            console.log('[DocumentUtils] Entidades maestras obtenidas del perfil:', entities);
            localStorage.setItem('masterEntities', JSON.stringify(entities));

            let entityToSelect;
            if (companyRut) {
                const matchingEntity = entities.find(
                    (entity: MasterEntity) => entity.tax_id === companyRut.replace(/\./g, '').replace('-', '')
                );
                entityToSelect = matchingEntity || entities[0];
            } else {
                entityToSelect = entities[0];
            }

            localStorage.setItem('selectedEntityId', entityToSelect.id);
            localStorage.setItem('selectedEntityName', entityToSelect.name);

            dispatch({
                type: "SET_MASTER_ENTITIES",
                payload: entities
            });

            dispatch({
                type: "SET_ENTITY",
                payload: {
                    id: entityToSelect.id,
                    name: entityToSelect.name
                }
            });

            return entityToSelect;
        }
        return null;
    } catch (error) {
        console.error('Error inicializando empresa desde el perfil:', error);
        return null;
    }
}; 