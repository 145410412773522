import React from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { FileSpreadsheet, Zap, Clock, CheckCircle, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function RetailLanding() {
    return (
        <div className="min-h-screen bg-white">
            <Header />
            <main>
                {/* Hero Section */}
                <section className="pt-24 pb-16 bg-gradient-to-br from-green-50 to-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-6xl mx-auto">
                            <div className="grid md:grid-cols-2 gap-12 items-center">
                                <div>
                                    <h1 className="text-5xl font-bold mb-6">
                                        Factura órdenes de compra de supermercados en segundos
                                    </h1>
                                    <p className="text-xl text-gray-600 mb-8">
                                        Adaptamos automáticamente las OCs de Jumbo, SMU, Walmart y más.
                                        Sube tu Excel y deja que Pana haga el resto.
                                    </p>
                                    <Link
                                        to="/signup"
                                        className="inline-flex items-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                    >
                                        <span>Sube tu OC y pruébalo gratis</span>
                                        <ArrowRight className="h-5 w-5" />
                                    </Link>
                                </div>

                                <div className="relative">
                                    {/* OC Example */}
                                    <div className="absolute left-0 top-0 w-3/4 bg-white rounded-lg shadow-lg p-4 transform -rotate-6">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="text-sm font-medium text-gray-600">Orden de Compra</div>
                                            <div className="text-sm text-gray-500">#OC-2024-001</div>
                                        </div>
                                        <div className="space-y-2 text-sm">
                                            <div className="grid grid-cols-2 gap-2">
                                                <div className="text-gray-600">Proveedor:</div>
                                                <div>Empresa ABC SpA</div>
                                                <div className="text-gray-600">RUT:</div>
                                                <div>76.123.456-7</div>
                                            </div>
                                            <table className="w-full mt-4">
                                                <thead className="text-gray-600">
                                                    <tr>
                                                        <th className="text-left">Producto</th>
                                                        <th className="text-right">Cant.</th>
                                                        <th className="text-right">Precio</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-800">
                                                    <tr>
                                                        <td>Producto A</td>
                                                        <td className="text-right">10</td>
                                                        <td className="text-right">$5.000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* Arrow */}
                                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <ArrowRight className="h-12 w-12 text-green-600" />
                                    </div>

                                    {/* Invoice Example */}
                                    <div className="absolute right-0 top-0 w-3/4 bg-white rounded-lg shadow-lg p-4 transform rotate-6">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="text-sm font-medium text-green-600">Factura Electrónica</div>
                                            <div className="text-sm text-gray-500">#123456</div>
                                        </div>
                                        <div className="space-y-2 text-sm">
                                            <div className="grid grid-cols-2 gap-2">
                                                <div className="text-gray-600">Cliente:</div>
                                                <div>Empresa ABC SpA</div>
                                                <div className="text-gray-600">RUT:</div>
                                                <div>76.123.456-7</div>
                                            </div>
                                            <table className="w-full mt-4">
                                                <thead className="text-gray-600">
                                                    <tr>
                                                        <th className="text-left">Producto</th>
                                                        <th className="text-right">Cant.</th>
                                                        <th className="text-right">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-800">
                                                    <tr>
                                                        <td>Producto A</td>
                                                        <td className="text-right">10</td>
                                                        <td className="text-right">$50.000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Features Grid */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <FileSpreadsheet className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Macro adaptada a múltiples formatos de supermercados
                                </h3>
                                <p className="text-gray-600">
                                    Compatible con los formatos de Excel de Jumbo, SMU, Walmart y más.
                                    Sin necesidad de modificar tus archivos.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Zap className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Generación masiva de facturas con un clic
                                </h3>
                                <p className="text-gray-600">
                                    Sube tu archivo de OCs y genera todas las facturas automáticamente.
                                    Sin errores, sin demoras.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <Clock className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Sin cambiar tu facturador del SII
                                </h3>
                                <p className="text-gray-600">
                                    Seguimos usando tu mismo certificado digital. Solo automatizamos el proceso
                                    para que sea más rápido.
                                </p>
                            </div>

                            <div className="bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl">
                                <CheckCircle className="h-12 w-12 text-green-600 mb-6" />
                                <h3 className="text-xl font-bold mb-4">
                                    Menos errores, más tiempo para vender
                                </h3>
                                <p className="text-gray-600">
                                    Validación automática de RUTs, precios y cantidades.
                                    Dedica tu tiempo a hacer crecer tu negocio.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Demo Section */}
                <section className="py-16 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="max-w-5xl mx-auto">
                            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src="https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&w=1200&q=80"
                                    alt="Facturación de supermercados"
                                    className="w-full h-96 object-cover"
                                />
                                <div className="p-8">
                                    <h3 className="text-2xl font-bold mb-4">
                                        ¿Cómo funciona?
                                    </h3>
                                    <div className="space-y-4">
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">1</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Sube tu archivo de OCs</h4>
                                                <p className="text-gray-600">
                                                    Descarga las OCs desde el portal del supermercado y súbelas directamente.
                                                    No necesitas modificar nada.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">2</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Validación automática</h4>
                                                <p className="text-gray-600">
                                                    Verificamos RUTs, precios, cantidades y toda la información necesaria
                                                    para evitar errores.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start space-x-4">
                                            <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-green-600 font-medium">3</span>
                                            </div>
                                            <div>
                                                <h4 className="font-medium mb-2">Emisión masiva</h4>
                                                <p className="text-gray-600">
                                                    Con un solo clic, emitimos todas las facturas directamente en el SII.
                                                    Tú solo tienes que revisar y aprobar.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl mx-auto text-center">
                            <h2 className="text-3xl font-bold mb-6">
                                ¿Listo para facturar más rápido?
                            </h2>
                            <p className="text-xl text-gray-600 mb-8">
                                Prueba Pana gratis y descubre cómo puedes ahorrar horas en tu proceso de facturación.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                <Link
                                    to="/signup"
                                    className="inline-flex items-center justify-center space-x-2 bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition text-lg"
                                >
                                    <span>Empezar prueba gratis</span>
                                    <ArrowRight className="h-5 w-5" />
                                </Link>
                                <a
                                    href="#"
                                    className="inline-flex items-center justify-center space-x-2 border-2 border-green-600 text-green-600 px-8 py-4 rounded-lg hover:bg-green-50 transition text-lg"
                                >
                                    <span>Ver demo</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}