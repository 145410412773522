import React from 'react';

export function ColumnFormat() {
    return (
        <div>
            <h3 className="text-lg font-medium mb-3">Formato de Columnas en Excel</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Columna</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Descripción</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Obligatorio</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Formato</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">RUT Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">RUT del cliente</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">12.345.678-9</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Contacto Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Nombre del contacto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Texto</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Fecha Emisión</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Fecha de emisión del documento</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">AAAA-MM-DD</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Tipo Documento</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Tipo de documento tributario</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">33, 34, 56, 61</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Nombre Producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Nombre del producto o servicio</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Texto</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Código Producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Código interno del producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Texto</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Precio Unitario</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Precio por unidad sin IVA</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Número</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Cantidad</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Cantidad del producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Sí</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Número entero</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Descuento</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Descuento por producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">No</td>
                            <td className="px-4 py-2 text-sm text-gray-500">Número</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}