import React from 'react';
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import { Pricing } from '../components/Pricing';
import { Features } from '../components/Features';
import { HowItWorks } from '../components/HowItWorks';
import { Advantages } from '../components/Advantages';
import { BlogPreview } from '../components/BlogPreview';
import { Contact } from '../components/Contact';
import { Footer } from '../components/Footer';
import { SIILogo } from '../components/SIILogo';
import { ChatWidget } from '../components/chat/ChatWidget';
import { CountryProvider } from '../context/CountryContext';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SuccessStories } from '../components/SuccessStories';

export default function Landing() {
  const location = useLocation();
  const scrollTargetRef = useRef<string | null>(null);

  // Handle scrolling to section when navigating from another page
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scrollTargetRef.current = location.state.scrollTo;
    }
  }, [location]);

  // Scroll to target section after component mounts
  useEffect(() => {
    if (scrollTargetRef.current) {
      const element = document.getElementById(scrollTargetRef.current);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
      scrollTargetRef.current = null;
    }
  }, []);

  return (
    <CountryProvider>
      <div className="min-h-screen bg-white">
        <Header />
        <main>
          <Hero />
          <Features />
          <Pricing />
          <Advantages />
          <SuccessStories />
          <HowItWorks />
          <BlogPreview />
          <SIILogo />
          <Contact />
        </main>
        <Footer />
        <ChatWidget />
      </div>
    </CountryProvider>
  );
}