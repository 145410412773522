import React from 'react';
import { FileUp, Shield, Clock, CheckCircle } from 'lucide-react';
import { SIILogo } from './SIILogo';
import { useCountry } from '../context/CountryContext';

export function Features() {
  const { country, translations } = useCountry();

  const features = [
    {
      icon: FileUp,
      title: 'Facturación Masiva',
      description: country === 'chile'
        ? 'Sube tu archivo Excel con los detalles de las facturas y nosotros nos encargamos del resto.'
        : 'Sube tu archivo Excel con los detalles de las facturas y nosotros nos encargamos del resto.'
    },
    {
      icon: Shield,
      title: country === 'chile' ? 'Conexión Segura con SII' : 'Conexión Segura con AEAT',
      description: country === 'chile'
        ? 'Integración directa y segura con el Servicio de Impuestos Internos.'
        : 'Integración directa y segura con la Agencia Tributaria.',
      showSII: true
    },
    {
      icon: Clock,
      title: 'Ahorra Tiempo',
      description: country === 'chile'
        ? 'Automatiza tu proceso de facturación y dedica tiempo a lo que realmente importa.'
        : 'Automatiza tu proceso de facturación y dedica tiempo a lo que realmente importa.'
    },
    {
      icon: CheckCircle,
      title: 'Validación Automática',
      description: country === 'chile'
        ? 'Verificamos tus datos antes de emitir las facturas para evitar errores.'
        : 'Verificamos tus datos antes de emitir las facturas para evitar errores.'
    }
  ];

  return (
    <section id="funciones" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          {country === 'chile'
            ? 'Todo lo que necesitas para facturar sin complicaciones'
            : 'Todo lo que necesitas para facturar sin complicaciones'}
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div key={index} className="p-6 rounded-lg border border-gray-200 hover:border-green-500 transition">
                <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="h-6 w-6 text-green-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600 mb-4">{feature.description}</p>
                {feature.showSII && <SIILogo country={country} />}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}