import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../../../services/api';
import { useEntity } from '../../../../context/EntityContext';
import { Header } from './components/Header';
import { InvoiceCard } from './components/InvoiceCard';
import { AnimatePresence, motion } from 'framer-motion';
import { SpinningRing } from '../../../../components/SpinningRing';
import { AlertCircle } from 'lucide-react';

interface Reference {
    reference_type: string;
    reference_folio: string;
    reference_date: string;
    reference_reason: string;
}

interface Document {
    id: number;
    folio: string;
    date_issued: string;
    receiver_business_name: string;
    receiver_tax_id: string;
    amount_with_iva: string;
    status: string;
    has_business_activity: boolean;
    has_address: boolean;
    items: Array<{
        item_name: string;
        quantity: number;
        unit_price: number;
        item_total: number;
    }>;
    references: Reference[];
    dte_type_code: number;
    dte_type_description: string;
    document_receiver: {
        business_name: string;
        rut: string;
        business_activity?: string;
        address?: string;
        district?: string;
        city?: string;
    };
    details: Array<any>;
}

interface PdfState {
    data: string | null;
    isLoading: boolean;
    error: string | null;
}

export function BulkInvoiceDraft() {
    const [documentsCreated, setDocumentsCreated] = useState(0);
    const { state: { selectedEntity } } = useEntity();
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(null);
    const [pdfStates, setPdfStates] = useState<Record<number, PdfState>>({});
    const [refreshingPdf, setRefreshingPdf] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [initialDocumentsCreated, setInitialDocumentsCreated] = useState(0);
    const [showIncomplete, setShowIncomplete] = useState(false);

    useEffect(() => {
        if (location.state?.documents) {
            const processedDocuments = Array.isArray(location.state.documents)
                ? location.state.documents.map(doc => ({
                    ...doc,
                    has_business_activity: doc.receiver?.activities?.length > 0,
                    has_address: doc.receiver?.addresses?.length > 0
                }))
                : [];
            setDocuments(processedDocuments);
        }
        setInitialDocumentsCreated(location.state?.documentsCreated || 0);
    }, [location.state]);

    useEffect(() => {
        setDocumentsCreated(initialDocumentsCreated);
    }, [initialDocumentsCreated]);

    const handleInvoiceSelect = async (invoiceId: number) => {
        if (selectedInvoiceId === invoiceId) {
            setSelectedInvoiceId(null);
            return;
        }

        setSelectedInvoiceId(invoiceId);

        const selectedInvoice = documents.find(doc => doc.id === invoiceId);
        if (!selectedInvoice) return;

        // Only fetch PDF if document has both business activity and address
        if (selectedInvoice.has_business_activity && selectedInvoice.has_address) {
            if (pdfStates[invoiceId]?.data) return;

            setPdfStates(prev => ({
                ...prev,
                [invoiceId]: { data: null, isLoading: true, error: null }
            }));

            try {
                if (!selectedEntity?.id) {
                    throw new Error('No hay entidad seleccionada');
                }

                const response = await api.get(`/master-entities/${selectedEntity.id}/documents/${invoiceId}/?pdf_file=true`);
                const data = await response.json();

                if (!data.pdf_base64) {
                    throw new Error('No se pudo obtener el PDF del documento');
                }

                setPdfStates(prev => ({
                    ...prev,
                    [invoiceId]: { data: data.pdf_base64, isLoading: false, error: null }
                }));
            } catch (err) {
                setPdfStates(prev => ({
                    ...prev,
                    [invoiceId]: {
                        data: null,
                        isLoading: false,
                        error: err instanceof Error ? err.message : 'Error al generar la vista previa'
                    }
                }));
            }
        }
    };

    const handleSaveChanges = async (invoiceId: number, data: any) => {
        try {
            if (!selectedEntity?.id) {
                throw new Error('No hay entidad seleccionada');
            }

            const requestBody = {
                folio: data.folio || '',
                receiver_rut: data.document_receiver?.rut || '',
                document_receiver: {
                    rut: data.document_receiver?.rut || '',
                    business_name: data.document_receiver?.business_name || '',
                    business_activity: data.document_receiver?.business_activity || '',
                    contact: data.document_receiver?.contact || '',
                    address: data.document_receiver?.address || '',
                    district: data.document_receiver?.district || '',
                    city: data.document_receiver?.city || ''
                },
                details: data.details || [],
                references: data.references || []
            };

            const response = await api.put(`/master-entities/${selectedEntity.id}/documents_v2/${invoiceId}/`, requestBody);

            if (!response.ok) {
                throw new Error('Error al actualizar el documento');
            }

            const updatedDocuments = documents?.map(doc =>
                doc.id === invoiceId ? {
                    ...doc,
                    document_receiver: requestBody.document_receiver,
                    has_business_activity: doc.receiver?.activities?.length > 0,
                    has_address: doc.receiver?.addresses?.length > 0
                } : doc
            ) || [];

            navigate(location.pathname, {
                state: {
                    ...location.state,
                    documents: updatedDocuments
                },
                replace: true
            });
        } catch (error) {
            console.error('Error saving changes:', error);
            throw error;
        }
    };

    const handleRefreshPdf = async (invoiceId: number) => {
        setRefreshingPdf(invoiceId);
        try {
            if (!selectedEntity?.id) {
                throw new Error('No hay entidad seleccionada');
            }

            const response = await api.get(
                `/master-entities/${selectedEntity.id}/documents/${invoiceId}/?pdf_file=true`
            );

            const data = await response.json();

            if (!data.pdf_base64) {
                throw new Error('No se pudo obtener el PDF actualizado');
            }

            setPdfStates(prev => ({
                ...prev,
                [invoiceId]: { data: data.pdf_base64, isLoading: false, error: null }
            }));
        } catch (error) {
            console.error('Error refreshing PDF:', error);
        } finally {
            setRefreshingPdf(null);
        }
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        setIsSearching(true);
        setTimeout(() => setIsSearching(false), 300);
    };

    const filteredDocuments = documents.filter(doc => {
        const matchesSearch = doc.document_receiver.business_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            doc.document_receiver.rut.toLowerCase().includes(searchTerm.toLowerCase());

        if (showIncomplete) {
            return matchesSearch && (!doc.has_business_activity || !doc.has_address);
        }

        return matchesSearch && doc.has_business_activity && doc.has_address;
    });

    const incompleteCount = documents.filter(doc => !doc.has_business_activity || !doc.has_address).length;
    const completeCount = documents.length - incompleteCount;

    return (
        <div className="max-w-7xl mx-auto">
            <div className="mb-6 flex justify-between items-center">
                <div className="flex space-x-4">
                    <button
                        onClick={() => setShowIncomplete(false)}
                        className={`px-4 py-2 rounded-lg ${!showIncomplete
                            ? 'bg-green-600 text-white'
                            : 'bg-white text-gray-600 hover:bg-gray-50'}`}
                    >
                        Documentos Listos ({completeCount})
                    </button>
                    <button
                        onClick={() => setShowIncomplete(true)}
                        className={`px-4 py-2 rounded-lg ${showIncomplete
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-white text-gray-600 hover:bg-gray-50'}`}
                    >
                        Requieren Atención ({incompleteCount})
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-2xl shadow-sm border border-gray-200 overflow-hidden">
                <Header
                    documentsCount={documents.length}
                    documentsCreated={documentsCreated}
                    searchTerm={searchTerm}
                    onSearchChange={handleSearch}
                />

                {showIncomplete && incompleteCount > 0 && (
                    <div className="p-4 bg-yellow-50 border-b border-yellow-100">
                        <div className="flex items-start space-x-3">
                            <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                            <div>
                                <p className="text-sm text-yellow-800">
                                    Estos documentos requieren actividad económica o dirección para poder ser emitidos.
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="divide-y divide-gray-200 relative">
                    {isSearching && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="absolute inset-0 bg-white/60 backdrop-blur-[2px] z-10 flex items-center justify-center"
                        >
                            <SpinningRing size="lg" />
                        </motion.div>
                    )}

                    <AnimatePresence>
                        {filteredDocuments.map((invoice) => (
                            <InvoiceCard
                                key={invoice.id}
                                invoice={invoice}
                                isSelected={selectedInvoiceId === invoice.id}
                                onSelect={() => handleInvoiceSelect(invoice.id)}
                                onSave={handleSaveChanges}
                                onRefreshPdf={() => handleRefreshPdf(invoice.id)}
                                isRefreshing={refreshingPdf === invoice.id}
                                isSearching={isSearching}
                                pdfState={pdfStates[invoice.id]}
                            />
                        ))}
                    </AnimatePresence>

                    {filteredDocuments.length === 0 && (
                        <div className="p-12 text-center text-gray-500">
                            No se encontraron documentos
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}