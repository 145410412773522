import React, { useState } from 'react';
import { FileText } from 'lucide-react';
import { api } from '../../services/api';

export interface Document {
    id: string;
    folio: string;
    date_issued: string;
    receiver_name: string;
    receiver_tax_id: string;
    amount_with_iva: number;
    status: 'PENDING' | 'COMPLETED';
    dte_type_code: number;
    created_at?: string;
    updated_at?: string;
    has_credit_note?: boolean;
}

export interface DocumentsTableProps {
    documents: Document[];
    selectedDocuments?: string[];
    onSelect?: (id: string) => void;
    isInProcess?: boolean;
}

export function DocumentsTable({
    documents,
    selectedDocuments = [],
    onSelect,
    isInProcess = false
}: DocumentsTableProps) {
    const [downloadingId, setDownloadingId] = useState<string | null>(null);

    const handleDownloadPDF = async (doc: Document) => {
        try {
            setDownloadingId(doc.id);
            const entityId = localStorage.getItem('selectedEntityId');
            if (!entityId) {
                throw new Error('No hay entidad seleccionada');
            }

            // Llamar a la API con el folio específico
            const response = await api.get(`/master-entities/${entityId}/documents/?folio=${doc.folio}`);
            const data = await response.json();

            // Verificar si hay datos y el PDF en base64
            if (!data || !data.results || !data.results[0]?.pdf_base64) {
                console.error('No se pudo obtener el PDF del documento');
                return;
            }

            // Crear el enlace de descarga con el PDF en base64
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${data.results[0].pdf_base64}`;
            link.download = `factura_${doc.folio}_${doc.receiver_name.replace(/\s+/g, '_')}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar PDF:', error);
        } finally {
            setDownloadingId(null);
        }
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {onSelect && (
                            <th className="w-12 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <span className="sr-only">Seleccionar</span>
                            </th>
                        )}
                        {!isInProcess && (
                            <th className="w-22 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Folio
                            </th>
                        )}
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Fecha
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Cliente
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Monto
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Estado
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            PDF
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {documents.map((doc) => (
                        <tr key={doc.id} className="hover:bg-gray-50">
                            {onSelect && (
                                <td className="w-12 px-2 py-4 whitespace-nowrap">
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
                                        checked={selectedDocuments.includes(doc.id)}
                                        onChange={() => onSelect(doc.id)}
                                    />
                                </td>
                            )}
                            {!isInProcess && (
                                <td className="w-22 px-2 py-4 whitespace-nowrap">
                                    <div className="flex flex-col items-center">
                                        <div className="text-sm text-gray-500">{doc.folio}</div>
                                        <div className="mt-1">
                                            {doc.dte_type_code === 33 && (
                                                <span className="px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                                                    AFECTA
                                                </span>
                                            )}
                                            {doc.dte_type_code === 34 && (
                                                <span className="px-2 py-0.5 text-xs font-medium bg-purple-100 text-purple-800 rounded-full">
                                                    EXENTA
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </td>
                            )}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {new Date(doc.date_issued).toLocaleDateString('es-CL')}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div>
                                    <p className="font-medium">{doc.receiver_name}</p>
                                    <p className="text-gray-500 text-xs">{doc.receiver_tax_id}</p>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                ${Math.round(doc.amount_with_iva).toLocaleString('es-CL')}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center">
                                {isInProcess ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                        En Proceso
                                    </span>
                                ) : (
                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${doc.status === 'COMPLETED'
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-yellow-100 text-yellow-800'
                                        }`}>
                                        {doc.status === 'COMPLETED' ? 'Emitida' : 'Pendiente'}
                                    </span>
                                )}
                                {doc.has_credit_note && (
                                    <span className="px-2 py-0.5 text-[10px] leading-4 font-medium bg-red-50 text-red-700 rounded-full border border-red-100/50 whitespace-nowrap">
                                        Nota de Crédito
                                    </span>
                                )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center">
                                <button
                                    onClick={() => handleDownloadPDF(doc)}
                                    disabled={downloadingId === doc.id}
                                    className="p-1.5 rounded-full text-[#FF0000] hover:text-white hover:bg-[#FF0000] transition-all duration-200 disabled:bg-transparent"
                                    title="Descargar PDF"
                                >
                                    {downloadingId === doc.id ? (
                                        <div className="h-5 w-5 border-2 border-[#FF0000] border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <FileText className="h-5 w-5" />
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}