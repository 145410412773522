import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCountry } from '../context/CountryContext';

export function BlogPreview() {
    const { country } = useCountry();

    return (
        <section className="py-16 bg-gradient-to-br from-green-50 to-white">
            <div className="container mx-auto px-4">
                <div className="flex items-center justify-between mb-12">
                    <h2 className="text-3xl md:text-4xl font-bold">
                        {country === 'chile' ? 'Blog de Facturación' : 'Blog de Facturación'}
                    </h2>
                    <Link
                        to="/blog"
                        className="hidden md:flex items-center space-x-2 text-green-600 hover:text-green-700"
                    >
                        <span>{country === 'chile' ? 'Ver todos los artículos' : 'Ver todos los artículos'}</span>
                        <ArrowRight className="h-5 w-5" />
                    </Link>
                </div>

                <div className="max-w-4xl mx-auto">
                    <article className="bg-white rounded-2xl shadow-lg overflow-hidden">
                        <img
                            src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1200&q=80"
                            alt="Facturación Electrónica para PYMEs"
                            className="w-full h-64 object-cover"
                        />

                        <div className="p-8">
                            <div className="flex flex-wrap gap-2 mb-4">
                                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
                                    {country === 'chile' ? 'Facturación' : 'Facturación'}
                                </span>
                                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
                                    PYMEs
                                </span>
                                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
                                    {country === 'chile' ? 'Chile' : 'España'}
                                </span>
                            </div>

                            <h3 className="text-2xl font-bold text-gray-900 mb-4">
                                {country === 'chile'
                                    ? 'Facturación Electrónica para PYMEs en Chile: Simplifica tu Vida con Tu Pana'
                                    : 'Facturación Electrónica para PYMEs en España: Simplifica tu Vida con Tu Pana'}
                            </h3>

                            <div className="flex items-center space-x-4 text-sm text-gray-500 mb-6">
                                <time>{country === 'chile' ? '15 de Marzo, 2024' : '15 de Marzo, 2024'}</time>
                                <span>•</span>
                                <span>{country === 'chile' ? '10 min de lectura' : '10 min de lectura'}</span>
                            </div>

                            <p className="text-gray-600 mb-8">
                                {country === 'chile'
                                    ? 'Si diriges una PYME en Chile, seguro conoces el estrés de emitir facturas. Pero no tiene por qué ser así. Tu Pana llega para revolucionar tu experiencia de facturación.'
                                    : 'Si diriges una PYME en España, seguro conoces el estrés de emitir facturas. Pero no tiene por qué ser así. Tu Pana llega para revolucionar tu experiencia de facturación.'}
                            </p>

                            <Link
                                to="/blog/facturacion-electronica-pymes-chile"
                                className="inline-flex items-center space-x-2 text-green-600 font-medium hover:text-green-700"
                            >
                                <span>{country === 'chile' ? 'Leer más' : 'Leer más'}</span>
                                <ArrowRight className="h-4 w-4" />
                            </Link>
                        </div>
                    </article>
                </div>

                <div className="mt-8 text-center md:hidden">
                    <Link
                        to="/blog"
                        className="inline-flex items-center space-x-2 text-green-600 hover:text-green-700"
                    >
                        <span>{country === 'chile' ? 'Ver todos los artículos' : 'Ver todos los artículos'}</span>
                        <ArrowRight className="h-5 w-5" />
                    </Link>
                </div>
            </div>
        </section>
    );
}