import { api } from '../services/api';

export interface APIProduct {
    item_code: string | null;
    item_name: string;
    item_description?: string;
    count: number;
    last_price?: string;
    last_quantity?: string;
}

export interface Product {
    id: string;
    code: string;
    price: number;
    quantity: number;
    discount?: number;
    item_name: string;
    item_description?: string;
    unit_price: number;
    item_total: number;
    count?: number;
    last_price?: string;
    last_quantity?: string;
}

export const getProductList = async (entityId: string, customer_id?: string): Promise<APIProduct[]> => {
    if (!entityId) {
        throw new Error('No hay entidad seleccionada');
    }

    const url = customer_id
        ? `/master-entities/${entityId}/products/?customer_id=${customer_id}`
        : `/master-entities/${entityId}/products/`;

    const response = await api.get(url);
    if (!response.ok) {
        throw new Error('Error al obtener productos');
    }

    return await response.json();
}; 