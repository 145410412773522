import React from 'react';
import { Check, X } from 'lucide-react';
import { useCountry } from '../context/CountryContext';

export function Advantages() {
    const { country, translations } = useCountry();

    const comparisonData = [
        {
            feature: country === 'chile' ? 'Tiempo de implementación' : 'Tiempo de implementación',
            erp: country === 'chile' ? 'Semanas o meses' : 'Semanas o meses',
            pana: '10 minutos'
        },
        {
            feature: country === 'chile' ? 'Sistema de facturación' : 'Sistema de facturación',
            erp: country === 'chile' ? 'Tienes que cambiar de sistema' : 'Tienes que cambiar de sistema',
            pana: country === 'chile' ? 'Sigues usando el SII' : 'Sigues usando la AEAT'
        },
        {
            feature: country === 'chile' ? 'Soporte técnico' : 'Soporte técnico',
            erp: country === 'chile' ? 'Por ticket, tiempo de espera largo' : 'Por ticket, tiempo de espera largo',
            pana: country === 'chile' ? 'Chat y WhatsApp 24/7' : 'Chat y WhatsApp 24/7'
        },
        {
            feature: country === 'chile' ? 'Capacitación requerida' : 'Formación requerida',
            erp: country === 'chile' ? 'Días o semanas de espera' : 'Días o semanas de espera',
            pana: country === 'chile' ? 'Listo en 10 minutos' : 'Listo en 10 minutos'
        },
        {
            feature: country === 'chile' ? 'Proceso de migración' : 'Proceso de migración',
            erp: country === 'chile' ? 'Migraciones, capacitaciones, bla bla' : 'Migraciones, formaciones, etc.',
            pana: country === 'chile' ? 'Subes el Excel y listo' : 'Subes el Excel y listo'
        },
        {
            feature: country === 'chile' ? 'Complejidad general' : 'Complejidad general',
            erp: country === 'chile' ? 'Costoso, lento, burocrático' : 'Costoso, lento, burocrático',
            pana: country === 'chile' ? 'Simple, rápido y sin vueltas' : 'Sencillo, rápido y sin complicaciones'
        }
    ];

    return (
        <section id="comparacion" className="py-16 bg-white">
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-4xl md:text-5xl font-bold mb-4">
                        ¿Por qué <span className="text-green-600">Tu Pana</span> y no otros?
                    </h2>
                    <p className="text-xl text-gray-600">
                        {country === 'chile'
                            ? 'Compara y descubre por qué somos la mejor opción'
                            : 'Compara y descubre por qué somos la mejor opción'}
                    </p>
                </div>

                <div className="max-w-4xl mx-auto">
                    <table className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-6 text-left text-base font-semibold text-gray-900">
                                    Característica
                                </th>
                                <th className="px-6 py-6 text-left text-base font-semibold text-green-600">
                                    Tu Pana
                                </th>
                                <th className="px-6 py-6 text-left text-base font-semibold text-gray-900">
                                    {country === 'chile'
                                        ? 'Facturadores y ERPs Tradicionales'
                                        : 'Facturadores y ERPs Tradicionales'}
                                </th>
                            </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                            {comparisonData.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50 transition-colors">
                                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                        {item.feature}
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center text-green-600">
                                            <Check className="h-5 w-5 mr-2 flex-shrink-0" />
                                            <span className="text-gray-600">{item.pana}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center text-red-600">
                                            <X className="h-5 w-5 mr-2 flex-shrink-0" />
                                            <span className="text-gray-600">{item.erp}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}