import React, { useState, useEffect, useCallback } from 'react';
import { Building2, ChevronDown, ChevronUp } from 'lucide-react';
import { getSpecificMasterEntity } from '../../../../requests/specific-master-entity';
import { useEntity } from '../../../../context/EntityContext';

interface IssuerData {
    name: string;
    address: string;
    district: string;
    city: string;
    saleType: string;
    email: string;
    phone: string;
    businessLine: string;
    economicActivity: string;
}

interface IssuerInfoProps {
    data: IssuerData;
    onChange: (data: IssuerData) => void;
}

const SALE_TYPES = {
    "1": "Del Giro",
    "2": "Ventas que no son del Giro (Activo Fijo y Otros)",
    "3": "Venta de Bienes inmuebles, 'BBRR'"
} as const;

export function IssuerInfo({ data, onChange }: IssuerInfoProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { state: entityState } = useEntity();
    const [localData, setLocalData] = useState<IssuerData>(data);

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    const handleSaleTypeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const newData = {
            ...localData,
            saleType: e.target.value
        };
        setLocalData(newData);
        onChange(newData);
    }, [localData, onChange]);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        console.log('Entidad seleccionada cambió, recargando datos...');
    }, [entityState.selectedEntity?.id]);

    useEffect(() => {
        let isMounted = true;

        const fetchIssuerInfo = async () => {
            if (!entityState.selectedEntity?.id) {
                setError('No hay entidad seleccionada');
                setIsLoading(false);
                return;
            }

            try {
                console.log('Obteniendo datos del emisor para ID:', entityState.selectedEntity.id);
                const issuerData = await getSpecificMasterEntity(entityState.selectedEntity.id);

                if (isMounted) {
                    console.log('Datos del emisor cargados:', issuerData);

                    const completeData = {
                        name: issuerData.name || '',
                        address: issuerData.address || '',
                        district: issuerData.district || '',
                        city: issuerData.city || '',
                        saleType: issuerData.saleType || '1',
                        email: issuerData.email || '',
                        phone: issuerData.phone || '',
                        businessLine: issuerData.businessLine || '',
                        economicActivity: issuerData.economicActivity || ''
                    };

                    setLocalData(completeData);
                    onChange(completeData);
                }
            } catch (err) {
                console.error('Error fetching issuer info:', err);
                if (isMounted) {
                    setError('Error al cargar los datos del emisor');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        if (isLoading) {
            fetchIssuerInfo();
        }

        return () => {
            isMounted = false;
        };
    }, [entityState.selectedEntity?.id, onChange, isLoading]);

    if (isLoading) {
        return (
            <div className="border rounded-lg bg-gray-50 p-4">
                <div className="flex items-center space-x-2">
                    <Building2 className="h-5 w-5 text-green-600" />
                    <h3 className="text-lg font-medium">Cargando datos del emisor...</h3>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="border rounded-lg bg-red-50 p-4">
                <div className="flex items-center space-x-2">
                    <Building2 className="h-5 w-5 text-red-600" />
                    <h3 className="text-lg font-medium text-red-700">{error}</h3>
                </div>
            </div>
        );
    }

    return (
        <div className="border rounded-lg bg-gray-50">
            <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded)}
                className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-100 rounded-lg transition-colors"
            >
                <div className="flex items-center space-x-2">
                    <Building2 className="h-5 w-5 text-green-600" />
                    <h3 className="text-lg font-medium">Datos del Emisor</h3>
                </div>
                {isExpanded ? (
                    <ChevronUp className="h-5 w-5 text-gray-400" />
                ) : (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                )}
            </button>

            {isExpanded && (
                <div className="p-4 border-t">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Razón Social</label>
                            <input
                                type="text"
                                value={localData.name}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="text"
                                value={localData.email}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div className="md:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Dirección</label>
                            <input
                                type="text"
                                value={localData.address}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Comuna</label>
                            <input
                                type="text"
                                value={localData.district}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Ciudad</label>
                            <input
                                type="text"
                                value={localData.city}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Tipo de Venta</label>
                            <select
                                value={localData.saleType}
                                onChange={handleSaleTypeChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            >
                                {Object.entries(SALE_TYPES).map(([value, label]) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                            <input
                                type="text"
                                value={localData.phone}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div className="md:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Giro</label>
                            <input
                                type="text"
                                value={localData.businessLine}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>

                        <div className="md:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">Act. Económica</label>
                            <input
                                type="text"
                                value={localData.economicActivity}
                                disabled
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
