import React, { useState } from 'react';
import { User, Key, FileText, Eye, EyeOff, Pencil, Calendar } from 'lucide-react';

export function SIICredentials() {
    const [showPasswords, setShowPasswords] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        rut: '',
        password: '',
        certificateKey: ''
    });

    // Simular credenciales activas
    const activeCredentials = {
        rut: '12.345.678-9',
        password: '••••••••',
        certificateKey: '••••••••',
        enrollmentDate: '2024-03-15'
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsEditing(false);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl">
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-2 text-sm text-gray-600">
                    <Calendar className="h-4 w-4" />
                    <span>Enrolado en Pana desde: {activeCredentials.enrollmentDate}</span>
                </div>
                {!isEditing && (
                    <button
                        type="button"
                        onClick={() => setIsEditing(true)}
                        className="flex items-center space-x-2 px-4 py-2 text-green-600 hover:bg-green-50 rounded-md transition-colors"
                    >
                        <Pencil className="h-4 w-4" />
                        <span>Editar Credenciales</span>
                    </button>
                )}
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    RUT persona encargada de facturar
                </label>
                {isEditing ? (
                    <div className="relative">
                        <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type="text"
                            value={formData.rut}
                            onChange={(e) => setFormData({ ...formData, rut: e.target.value })}
                            className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                            placeholder="12.345.678-9"
                        />
                    </div>
                ) : (
                    <div className="px-3 py-2 bg-gray-50 rounded-md text-gray-700">
                        {activeCredentials.rut}
                    </div>
                )}
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Contraseña SII
                </label>
                {isEditing ? (
                    <div className="relative">
                        <Key className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type={showPasswords ? "text" : "password"}
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            className="pl-10 pr-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPasswords(!showPasswords)}
                            className="absolute right-3 top-2.5"
                        >
                            {showPasswords ? (
                                <EyeOff className="h-5 w-5 text-gray-400" />
                            ) : (
                                <Eye className="h-5 w-5 text-gray-400" />
                            )}
                        </button>
                    </div>
                ) : (
                    <div className="px-3 py-2 bg-gray-50 rounded-md text-gray-700">
                        {activeCredentials.password}
                    </div>
                )}
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Clave del Certificado Digital
                </label>
                {isEditing ? (
                    <div className="relative">
                        <FileText className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <textarea
                            value={formData.certificateKey}
                            onChange={(e) => setFormData({ ...formData, certificateKey: e.target.value })}
                            className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                            rows={4}
                        />
                    </div>
                ) : (
                    <div className="px-3 py-2 bg-gray-50 rounded-md text-gray-700">
                        {activeCredentials.certificateKey}
                    </div>
                )}
            </div>

            {isEditing && (
                <div className="flex justify-end space-x-3">
                    <button
                        type="button"
                        onClick={() => setIsEditing(false)}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                    >
                        Guardar Cambios
                    </button>
                </div>
            )}
        </form>
    );
}