interface RefreshResponse {
    access: string;
}

class ApiService {
    private baseUrl = import.meta.env.VITE_API_URL;
    private isRefreshing = false;
    private failedQueue: Array<{
        resolve: (value?: unknown) => void;
        reject: (reason?: unknown) => void;
    }> = [];

    private async refreshToken(): Promise<string | null> {
        const refreshToken = localStorage.getItem('refreshToken');
        console.log('Attempting to refresh token with:', refreshToken);

        if (!refreshToken) {
            console.log('No refresh token found');
            return null;
        }

        try {
            const response = await fetch(`${this.baseUrl}/auth/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            console.log('Refresh token response status:', response.status);

            if (!response.ok) {
                console.log('Failed to refresh token, response:', await response.text());
                throw new Error('Failed to refresh token');
            }

            const data: RefreshResponse = await response.json();
            console.log('New access token received');
            localStorage.setItem('accessToken', data.access);
            return data.access;
        } catch (error) {
            console.error('Error refreshing token:', error);
            return null;
        }
    }

    private processQueue(error: Error | null, token: string | null = null) {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        this.failedQueue = [];
    }

    async fetch(url: string, options: RequestInit = {}): Promise<Response> {
        const accessToken = localStorage.getItem('accessToken');
        console.log('Making request to:', url);
        console.log('Current access token:', accessToken);

        const headers = {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(url, { ...options, headers });
            console.log('Response status:', response.status);

            if (response.ok) return response;

            if (response.status !== 401) {
                console.log('Non-401 error:', response.status);
                const errorData = await response.json();
                throw new Error(errorData.error || `HTTP Error: ${response.status}`);
            }

            console.log('Got 401, attempting token refresh');

            if (this.isRefreshing) {
                console.log('Token refresh already in progress, queueing request');
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    headers['Authorization'] = `Bearer ${token}`;
                    return fetch(url, { ...options, headers });
                });
            }

            this.isRefreshing = true;
            const newToken = await this.refreshToken();
            this.isRefreshing = false;

            if (!newToken) {
                console.log('Token refresh failed, redirecting to login');
                this.processQueue(new Error('Failed to refresh token'));
                localStorage.clear();
                window.location.href = '/login';
                throw new Error('Failed to refresh token');
            }

            headers['Authorization'] = `Bearer ${newToken}`;
            this.processQueue(null, newToken);

            return fetch(url, { ...options, headers });
        } catch (error) {
            console.error('Fetch error:', error);
            if (error instanceof Error && error.message === 'Failed to refresh token') {
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;

        }
    }

    async post(url: string, data: Record<string, unknown> | FormData): Promise<Response> {
        const accessToken = localStorage.getItem('accessToken');
        const fullUrl = `${this.baseUrl}/api${url}`;
        console.log('Making POST request to:', fullUrl);
        console.log('Current access token:', accessToken);

        const headers: Record<string, string> = {
            'Authorization': `Bearer ${accessToken}`,
        };

        // Si no es FormData, asumimos que es JSON
        if (!(data instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
        }

        try {
            const response = await fetch(fullUrl, {
                method: 'POST',
                headers,
                body: data instanceof FormData ? data : JSON.stringify(data)
            });

            console.log('POST response status:', response.status);

            if (response.ok) return response;

            if (response.status !== 401) {
                console.log('Non-401 error:', response.status);
                let errorMessage;
                try {
                    const errorData = await response.json();
                    console.log('Error data:', errorData);
                    errorMessage = errorData.error || `HTTP Error: ${response.status}`;
                } catch {
                    console.log('Error parsing JSON');
                    errorMessage = `HTTP Error: ${response.status} (invalid response)`;
                }
                throw new Error(errorMessage);
            }


            console.log('Got 401 in POST, attempting token refresh');

            if (this.isRefreshing) {
                console.log('Token refresh already in progress, queueing POST request');
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    headers['Authorization'] = `Bearer ${token}`;
                    return fetch(fullUrl, {
                        method: 'POST',
                        headers,
                        body: data instanceof FormData ? data : JSON.stringify(data)
                    });
                });
            }

            this.isRefreshing = true;
            const newToken = await this.refreshToken();
            this.isRefreshing = false;

            if (!newToken) {
                console.log('Token refresh failed in POST, redirecting to login');
                this.processQueue(new Error('Failed to refresh token'));
                localStorage.clear();
                window.location.href = '/login';
                throw new Error('Failed to refresh token');
            }

            headers['Authorization'] = `Bearer ${newToken}`;
            this.processQueue(null, newToken);

            return fetch(fullUrl, {
                method: 'POST',
                headers,
                body: data instanceof FormData ? data : JSON.stringify(data)
            });
        } catch (error) {
            console.error('POST error:', error);
            if (error instanceof Error && error.message === 'Failed to refresh token') {
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;
        }
    }

    async get(url: string): Promise<Response> {
        const accessToken = localStorage.getItem('accessToken');
        const fullUrl = `${this.baseUrl}/api${url}`;
        console.log('Making GET request to:', fullUrl);
        console.log('Current access token:', accessToken);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(fullUrl, {
                method: 'GET',
                headers
            });

            console.log('GET response status:', response.status);

            if (response.ok) return response;

            if (response.status !== 401) {
                console.log('Non-401 error:', response.status);
                throw new Error(`HTTP Error: ${response.status}`);
            }

            console.log('Got 401 in GET, attempting token refresh');

            if (this.isRefreshing) {
                console.log('Token refresh already in progress, queueing GET request');
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    headers['Authorization'] = `Bearer ${token}`;
                    return fetch(fullUrl, {
                        method: 'GET',
                        headers
                    });
                });
            }

            this.isRefreshing = true;
            const newToken = await this.refreshToken();
            this.isRefreshing = false;

            if (!newToken) {
                console.log('Token refresh failed in GET, redirecting to login');
                this.processQueue(new Error('Failed to refresh token'));
                localStorage.clear();
                window.location.href = '/login';
                throw new Error('Failed to refresh token');
            }

            headers['Authorization'] = `Bearer ${newToken}`;
            this.processQueue(null, newToken);

            return fetch(fullUrl, {
                method: 'GET',
                headers
            });
        } catch (error) {
            console.error('GET error:', error);
            if (error instanceof Error && error.message === 'Failed to refresh token') {
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;
        }
    }

    async delete(url: string): Promise<Response> {
        const accessToken = localStorage.getItem('accessToken');
        const fullUrl = `${this.baseUrl}/api${url}`;
        console.log('Making DELETE request to:', fullUrl);
        console.log('Current access token:', accessToken);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(fullUrl, {
                method: 'DELETE',
                headers
            });

            console.log('DELETE response status:', response.status);

            if (response.ok) return response;

            if (response.status !== 401) {
                console.log('Non-401 error:', response.status);
                let errorMessage;
                try {
                    const errorData = await response.json();
                    console.log('Error data:', errorData);
                    errorMessage = errorData.error || `HTTP Error: ${response.status}`;
                } catch {
                    console.log('Error parsing JSON');
                    errorMessage = `HTTP Error: ${response.status} (invalid response)`;
                }
                throw new Error(errorMessage);
            }

            console.log('Got 401 in DELETE, attempting token refresh');

            if (this.isRefreshing) {
                console.log('Token refresh already in progress, queueing DELETE request');
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    headers['Authorization'] = `Bearer ${token}`;
                    return fetch(fullUrl, {
                        method: 'DELETE',
                        headers
                    });
                });
            }

            this.isRefreshing = true;
            const newToken = await this.refreshToken();
            this.isRefreshing = false;

            if (!newToken) {
                console.log('Token refresh failed in DELETE, redirecting to login');
                this.processQueue(new Error('Failed to refresh token'));
                localStorage.clear();
                window.location.href = '/login';
                throw new Error('Failed to refresh token');
            }

            headers['Authorization'] = `Bearer ${newToken}`;
            this.processQueue(null, newToken);

            return fetch(fullUrl, {
                method: 'DELETE',
                headers
            });
        } catch (error) {
            console.error('DELETE error:', error);
            if (error instanceof Error && error.message === 'Failed to refresh token') {
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;
        }
    }

    async put(url: string, data: Record<string, unknown>): Promise<Response> {
        const accessToken = localStorage.getItem('accessToken');
        const fullUrl = `${this.baseUrl}/api${url}`;
        console.log('Making PUT request to:', fullUrl);
        console.log('Current access token:', accessToken);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(fullUrl, {
                method: 'PUT',
                headers,
                body: JSON.stringify(data)
            });

            console.log('PUT response status:', response.status);

            if (response.ok) return response;

            if (response.status !== 401) {
                console.log('Non-401 error:', response.status);
                let errorMessage;
                try {
                    const errorData = await response.json();
                    console.log('Error data:', errorData);
                    errorMessage = errorData.error || `HTTP Error: ${response.status}`;
                } catch {
                    console.log('Error parsing JSON');
                    errorMessage = `HTTP Error: ${response.status} (invalid response)`;
                }
                throw new Error(errorMessage);
            }

            console.log('Got 401 in PUT, attempting token refresh');

            if (this.isRefreshing) {
                console.log('Token refresh already in progress, queueing PUT request');
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    headers['Authorization'] = `Bearer ${token}`;
                    return fetch(fullUrl, {
                        method: 'PUT',
                        headers,
                        body: JSON.stringify(data)
                    });
                });
            }

            this.isRefreshing = true;
            const newToken = await this.refreshToken();
            this.isRefreshing = false;

            if (!newToken) {
                console.log('Token refresh failed in PUT, redirecting to login');
                this.processQueue(new Error('Failed to refresh token'));
                localStorage.clear();
                window.location.href = '/login';
                throw new Error('Failed to refresh token');
            }

            headers['Authorization'] = `Bearer ${newToken}`;
            this.processQueue(null, newToken);

            return fetch(fullUrl, {
                method: 'PUT',
                headers,
                body: JSON.stringify(data)
            });
        } catch (error) {
            console.error('PUT error:', error);
            if (error instanceof Error && error.message === 'Failed to refresh token') {
                localStorage.clear();
                window.location.href = '/login';
            }
            throw error;
        }
    }
}

export const api = new ApiService(); 