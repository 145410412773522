import { api } from '../services/api';

interface IssuerData {
    name: string;
    address: string;
    district: string;
    city: string;
    saleType: string;
    email: string;
    phone: string;
    businessLine: string;
    economicActivity: string;
}

// Estructura actualizada que coincide con la respuesta real de la API
interface ApiResponse {
    id: number;
    tax_id: string;
    name: string;
    email: string | null;
    addresses: Array<{
        id: number;
        address: string;
        district: {
            id: number;
            name: string;
            city: {
                id: number;
                name: string;
            }
        };
        sii_branch_code: string;
        phone: string | null;
    }>;
    activities: Array<{
        id: number;
        code: string;
        name: string;
    }>;
}

export const getSpecificMasterEntity = async (entityId: string): Promise<IssuerData> => {
    if (!entityId) {
        throw new Error('No hay entidad seleccionada');
    }

    const response = await api.get(`/master-entities/${entityId}/`);
    if (!response.ok) {
        throw new Error('Error al obtener datos del emisor');
    }

    const data: ApiResponse = await response.json();
    console.log('Datos recibidos de la API:', data);

    // Obtener la primera dirección si existe
    const firstAddress = data.addresses && data.addresses.length > 0 ? data.addresses[0] : null;
    // Obtener la primera actividad si existe
    const firstActivity = data.activities && data.activities.length > 0 ? data.activities[0] : null;

    // Mapear los datos de la API al formato que espera la interfaz IssuerData
    return {
        name: data.name || '',
        // Usar datos de la primera dirección si existe
        address: firstAddress ? firstAddress.address : '',
        district: firstAddress && firstAddress.district ? firstAddress.district.name : '',
        city: firstAddress && firstAddress.district && firstAddress.district.city ? firstAddress.district.city.name : '',
        saleType: '1', // Valor por defecto
        email: data.email || '',
        phone: firstAddress ? (firstAddress.phone || '') : '',
        // Usar datos de la primera actividad si existe
        businessLine: firstActivity ? firstActivity.name : '',
        economicActivity: firstActivity ? `${firstActivity.code} - ${firstActivity.name}` : ''
    };
}; 