import React, { forwardRef } from 'react';
import { Address } from '../types';

interface AddressSelectorProps {
    selectedAddress: Address | null;
    addresses: Address[] | undefined;
    onAddressSelect: (address: Address) => void;
    showDropdown: boolean;
    setShowDropdown: (show: boolean) => void;
    isManualAddress: boolean;
    setIsManualAddress: (isManual: boolean) => void;
    manualAddress: {
        address: string;
        district: { name: string; city: { name: string } };
    };
    onManualAddressChange: (address: typeof manualAddress) => void;
}

export const AddressSelector = forwardRef<HTMLDivElement, AddressSelectorProps>(({
    selectedAddress,
    addresses,
    onAddressSelect,
    showDropdown,
    setShowDropdown,
    isManualAddress,
    setIsManualAddress,
    manualAddress,
    onManualAddressChange
}, ref) => {
    return (
        <div className="relative" ref={ref}>
            <button
                type="button"
                onClick={() => {
                    setShowDropdown(!showDropdown);
                }}
                className="w-full px-3 py-2 text-left border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 bg-white"
            >
                {selectedAddress ? (
                    <div>
                        <div className="font-medium">{selectedAddress.address}</div>
                        <div className="text-sm text-gray-500">
                            {selectedAddress.district.name}, {selectedAddress.district.city.name}
                        </div>
                    </div>
                ) : isManualAddress ? (
                    <div>
                        <div className="font-medium">{manualAddress.address || 'Ingrese dirección'}</div>
                        <div className="text-sm text-gray-500">
                            {manualAddress.district.name && manualAddress.district.city.name ?
                                `${manualAddress.district.name}, ${manualAddress.district.city.name}` :
                                'Ingrese comuna y ciudad'}
                        </div>
                    </div>
                ) : (
                    <span className="text-gray-500">
                        Seleccionar dirección
                    </span>
                )}
            </button>

            {showDropdown && (
                <div className="absolute z-20 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                    <button
                        type="button"
                        onClick={() => {
                            setIsManualAddress(true);
                            setShowDropdown(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-50 border-b border-gray-200"
                    >
                        <div className="font-medium text-green-600">Ingresar manualmente</div>
                        <div className="text-sm text-gray-500">Agregar nueva dirección</div>
                    </button>
                    {addresses?.map((addr) => (
                        <button
                            key={addr.id}
                            type="button"
                            onClick={() => {
                                onAddressSelect(addr);
                                setShowDropdown(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50"
                        >
                            <div className="font-medium">{addr.address}</div>
                            <div className="text-sm text-gray-500">
                                {addr.district.name}, {addr.district.city.name}
                            </div>
                        </button>
                    ))}
                </div>
            )}

            {isManualAddress && (
                <div className="mt-2 space-y-2">
                    <input
                        type="text"
                        value={manualAddress.address}
                        onChange={(e) => onManualAddressChange({
                            ...manualAddress,
                            address: e.target.value
                        })}
                        placeholder="Dirección"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    />
                    <div className="grid grid-cols-2 gap-2">
                        <input
                            type="text"
                            value={manualAddress.district.name}
                            onChange={(e) => onManualAddressChange({
                                ...manualAddress,
                                district: { ...manualAddress.district, name: e.target.value }
                            })}
                            placeholder="Comuna"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                        <input
                            type="text"
                            value={manualAddress.district.city.name}
                            onChange={(e) => onManualAddressChange({
                                ...manualAddress,
                                district: {
                                    ...manualAddress.district,
                                    city: { name: e.target.value }
                                }
                            })}
                            placeholder="Ciudad"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

AddressSelector.displayName = 'AddressSelector';