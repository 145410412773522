import React from 'react';
import { HandshakeIcon, Sparkles, Mail, Phone, Building2, Key } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const characterVariants = {
    name: {
        initial: { y: 0, rotate: 0 },
        animate: {
            y: [0, -10, 0],
            rotate: [0, -5, 5, -5, 0],
            transition: {
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse"
            }
        }
    },
    email: {
        initial: { scale: 1, rotate: 0 },
        animate: {
            scale: [1, 1.1, 1],
            rotate: [0, -10, 10, -10, 0],
            transition: {
                duration: 3,
                repeat: Infinity
            }
        }
    },
    phone: {
        initial: { x: 0 },
        animate: {
            x: [-5, 5, -5],
            transition: {
                duration: 1.5,
                repeat: Infinity,
                repeatType: "reverse"
            }
        }
    },
    company: {
        initial: { y: 0, scale: 1 },
        animate: {
            y: [-5, 0],
            scale: [1, 1.05, 1],
            transition: {
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse"
            }
        }
    },
    password: {
        initial: { rotate: 0 },
        animate: {
            rotate: [0, -5, 5, -5, 0],
            transition: {
                duration: 2,
                repeat: Infinity
            }
        }
    }
};

const getStepIcon = (step: string) => {
    switch (step) {
        case 'name':
            return HandshakeIcon;
        case 'email':
            return Mail;
        case 'phone':
            return Phone;
        case 'company':
            return Building2;
        case 'password':
            return Key;
        default:
            return HandshakeIcon;
    }
};
interface PanaAssistantProps {
    message: string;
    step: 'name' | 'email' | 'phone' | 'company' | 'password';
    verificationSent?: boolean;
    companyFound?: boolean;
}

export function PanaAssistant({ message, step, verificationSent, companyFound }: PanaAssistantProps) {
    const Icon = getStepIcon(step);

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={step}
                initial={{ opacity: 0, x: 20, scale: 0.95 }}
                animate={{ opacity: 1, x: 0, scale: 1 }}
                exit={{ opacity: 0, x: -20, scale: 0.95 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
                className="w-full bg-white rounded-2xl shadow-lg p-6 border border-gray-100 transform-gpu"
            >
                <div className="flex items-center space-x-3 mb-4">
                    <motion.div
                        variants={characterVariants[step]}
                        initial="initial"
                        animate="animate"
                        className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center"
                    >
                        <Icon className="h-6 w-6 text-green-600" />
                    </motion.div>
                    <div>
                        <h3 className="font-medium text-gray-900">Tu Pana</h3>
                        <p className="text-sm text-gray-500">Te ayudo a facturar 😉</p>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-3 h-3 bg-green-50 transform-gpu rotate-45 z-10" />
                    <div className="bg-green-50 rounded-lg p-4">
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}
                            className="flex items-center space-x-3"
                        >
                            <motion.div
                                variants={characterVariants[step]}
                                initial="initial"
                                animate="animate"
                                className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-sm"
                            >
                                <Icon className="h-4 w-4 text-green-600" />
                            </motion.div>
                            <p className="text-gray-700">{message}</p>
                        </motion.div>
                    </div>
                </div>

                {step === 'name' && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="mt-4 p-4 bg-gradient-to-br from-green-50 to-white rounded-lg border border-green-100"
                    >
                        <div className="flex items-center space-x-2 text-green-600 mb-2">
                            <Sparkles className="h-4 w-4" />
                            <span className="font-medium">¿Sabías que?</span>
                        </div>
                        <p className="text-sm text-gray-600">
                            Con TuPana.ai puedes emitir cientos de facturas en minutos, sin cambiar tu facturador actual del SII.
                        </p>
                    </motion.div>
                )}

                {verificationSent && step === 'email' && (
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100"
                    >
                        <p className="text-sm text-blue-700">
                            ¡Código enviado! Revisa tu correo y no olvides revisar la carpeta de spam 📧
                        </p>
                    </motion.div>
                )}

                {companyFound && step === 'company' && (
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mt-4 p-4 bg-green-50 rounded-lg border border-green-100"
                    >
                        <p className="text-sm text-green-700">
                            ¡Empresa encontrada! Ya casi estamos listos para facturar más rápido 🚀
                        </p>
                    </motion.div>
                )}
            </motion.div>
        </AnimatePresence>
    );
}