import React from 'react';
import { Search, Check, AlertCircle } from 'lucide-react';
import { CustomerListItem } from '../types';
import { formatRutInput } from '../utils/rut';

interface CustomerSearchProps {
    searchTerm: string;
    onSearchChange: (term: string) => void;
    customers: CustomerListItem[];
    selectedCustomerRut: string | null;
    onCustomerSelect: (customer: CustomerListItem) => void;
    showDropdown: boolean;
    setShowDropdown: (show: boolean) => void;
    onNewCustomerRut: (rut: string) => void;
    dropdownRef: React.RefObject<HTMLDivElement>;
}

export function CustomerSearch({
    searchTerm,
    onSearchChange,
    customers,
    selectedCustomerRut,
    onCustomerSelect,
    showDropdown,
    setShowDropdown,
    onNewCustomerRut,
    dropdownRef
}: CustomerSearchProps) {
    const filteredCustomers = customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.tax_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="relative" ref={dropdownRef}>
            <div className="relative">
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <input
                    type="text"
                    placeholder="Buscar cliente por RUT o nombre..."
                    value={searchTerm}
                    onChange={(e) => {
                        onSearchChange(e.target.value);
                        setShowDropdown(true);
                    }}
                    onFocus={() => setShowDropdown(true)}
                    className={`w-full pl-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
            ${!selectedCustomerRut ? 'border-red-300 focus:ring-red-500' : 'border-gray-300 focus:ring-green-500'}`}
                />
            </div>
            {!selectedCustomerRut && (
                <div className="mt-1 flex items-center text-sm text-red-600">
                    <AlertCircle className="h-4 w-4 mr-1" />
                    <span>Selecciona un cliente para continuar</span>
                </div>
            )}

            {showDropdown && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                    <button
                        type="button"
                        onClick={() => {
                            onNewCustomerRut(searchTerm);
                            setShowDropdown(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none border-b border-gray-200"
                    >
                        <div>
                            <span className="font-medium text-green-600">Nuevo Cliente</span>
                            <p className="text-sm text-gray-500">Buscar por RUT</p>
                        </div>
                    </button>
                    {filteredCustomers.map((customer) => (
                        <button
                            key={customer.tax_id}
                            type="button"
                            onClick={() => {
                                onCustomerSelect(customer);
                                setShowDropdown(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none border-b border-gray-200"
                        >
                            <div className="flex justify-between items-center">
                                <div>
                                    <span className="font-medium">{customer.name}</span>
                                    <p className="text-sm text-gray-500">{customer.tax_id}</p>
                                </div>
                                {customer.tax_id === selectedCustomerRut && (
                                    <Check className="h-5 w-5 text-green-600" />
                                )}
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}