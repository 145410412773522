import React, { useState, useEffect } from 'react';
import { Building2, Loader2 } from 'lucide-react';

interface CompanyRutStepProps {
    onComplete: (data: { rut: string; name: string }) => void;
    initialValue: string;
}

export function CompanyRutStep({ onComplete, initialValue }: CompanyRutStepProps) {
    const [rut, setRut] = useState(initialValue);
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLookingUp, setIsLookingUp] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const calculateVerifierDigit = (rutBody: string): string => {
        const reversed = rutBody.split('').reverse();
        let sum = 0;
        let multiplier = 2;

        for (const digit of reversed) {
            sum += parseInt(digit) * multiplier;
            multiplier = multiplier === 7 ? 2 : multiplier + 1;
        }

        const remainder = sum % 11;
        const verifierDigit = 11 - remainder;

        if (verifierDigit === 11) return '0';
        if (verifierDigit === 10) return 'K';
        return verifierDigit.toString();
    };

    const formatRut = (value: string) => {
        const cleaned = value.replace(/[^0-9Kk]/g, '').toUpperCase();
        if (!cleaned) return '';
        if (cleaned.length === 1) return cleaned;

        const body = cleaned.slice(0, -1);
        const dv = cleaned.slice(-1);
        const reversedBody = body.split('').reverse().join('');
        const formattedReversedBody = reversedBody.match(/.{1,3}/g)?.join('.') || '';
        const formattedBody = formattedReversedBody.split('').reverse().join('');

        return formattedBody + '-' + dv;
    };

    const validateRut = (rut: string): boolean => {
        const cleaned = rut.replace(/[.-]/g, '');
        if (cleaned.length < 2) return false;
        const body = cleaned.slice(0, -1);
        const providedDv = cleaned.slice(-1).toUpperCase();
        const calculatedDv = calculateVerifierDigit(body);
        return calculatedDv === providedDv;
    };

    const isRutInValidRange = (rut: string): boolean => {
        const cleaned = rut.replace(/[.-]/g, '');
        const body = parseInt(cleaned.slice(0, -1));
        return body > 5000000 && body < 100000000;
    };

    const lookupCompanyName = async (rut: string) => {
        if (!isRutInValidRange(rut)) {
            setCompanyName('');
            setError('El RUT debe ser mayor a 5 millones y menor a 100 millones');
            return;
        }

        setIsLookingUp(true);
        setCompanyName('');
        setError('');

        try {
            const formattedRut = rut.replace(/\./g, '');

            const response = await fetch(`${import.meta.env.VITE_API_URL}/master-entities/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tax_id: formattedRut
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Error al buscar la empresa');
            }

            setCompanyName(data.name);
            localStorage.setItem('companyName', data.name);
            localStorage.setItem('companyRut', rut);
            localStorage.setItem('temporalMasterEntityId', data.id.toString());
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Error al buscar la empresa. Por favor, verifica el RUT.');
            }
            setCompanyName('');
        } finally {
            setIsLookingUp(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!rut) {
            setError('El RUT es requerido');
            return;
        }
        if (!validateRut(rut)) {
            setError('Por favor ingresa un RUT válido');
            return;
        }
        if (!isRutInValidRange(rut)) {
            setError('El RUT debe ser mayor a 5 millones y menor a 100 millones');
            return;
        }
        if (!companyName) {
            setError('Espera mientras buscamos la información de la empresa');
            return;
        }

        setIsLoading(true);
        try {
            const cleanedRut = rut.replace(/[.-]/g, '');
            const sessionId = localStorage.getItem('onboarding_session_id');

            const response = await fetch(`${import.meta.env.VITE_API_URL}/signup/company/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_tax_id: cleanedRut,
                    company_name: companyName,
                    onboarding_session_id: sessionId
                })
            });

            if (!response.ok) {
                throw new Error('Error al registrar el RUT de la empresa');
            }

            onComplete({ rut, name: companyName });
        } catch (error) {
            setError('Error al registrar el RUT. Por favor, intenta nuevamente.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedRut = formatRut(e.target.value);
        setRut(formattedRut);
        setCompanyName('');
        setError('');

        // Limpiar el timeout anterior si existe
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Si el RUT es válido, iniciar el debounce
        if (validateRut(formattedRut)) {
            setIsLookingUp(true); // Mostrar loader inmediatamente
            const newTimeoutId = setTimeout(() => {
                lookupCompanyName(formattedRut);
            }, 2000);
            setTimeoutId(newTimeoutId);
        } else {
            setIsLookingUp(false);
        }
    };

    // Limpiar el timeout al desmontar el componente
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label htmlFor="rut" className="block text-sm font-medium text-gray-700">
                    RUT de la Empresa
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Building2 className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                        type="text"
                        id="rut"
                        value={rut}
                        onChange={handleChange}
                        className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'
                            } rounded-md focus:outline-none focus:ring-2 focus:ring-green-500`}
                        placeholder="12.345.678-9"
                    />
                </div>

                {isLookingUp && (
                    <div className="mt-2 flex items-center text-sm text-gray-600">
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        <span>Buscando información de la empresa...</span>
                    </div>
                )}

                {companyName && (
                    <div className="mt-2 p-3 bg-green-50 border border-green-100 rounded-md">
                        <p className="text-sm font-medium text-green-800">Empresa encontrada:</p>
                        <p className="text-sm text-green-700">{companyName}</p>
                    </div>
                )}

                {error && (
                    <p className="mt-2 text-sm text-red-600">
                        {error}
                    </p>
                )}
            </div>

            <button
                type="submit"
                disabled={isLoading || !companyName}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-green-400"
            >
                {isLoading ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                    <span>Continuar</span>
                )}
            </button>
        </form>
    );
}