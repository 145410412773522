import React, { ReactNode } from 'react';
import { LucideIcon } from 'lucide-react';
import { Layout } from '../platform/layout/Layout';

interface SettingsLayoutProps {
    title: string;
    description: string;
    icon: LucideIcon;
    children: ReactNode;
}

export function SettingsLayout({ title, description, icon: Icon, children }: SettingsLayoutProps) {
    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <div className="flex items-center space-x-2 mb-8">
                    <Icon className="h-6 w-6 text-green-600" />
                    <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
                </div>

                <section className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-6 border-b border-gray-200">
                        <div className="flex items-center space-x-2">
                            <Icon className="h-5 w-5 text-green-600" />
                            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">
                            {description}
                        </p>
                    </div>
                    <div className="p-6">
                        {children}
                    </div>
                </section>
            </div>
        </Layout>
    );
} 