import React from 'react';

interface CircularLoaderProps {
    size?: 'sm' | 'md' | 'lg';
    className?: string;
}

export function CircularLoader({ size = 'md', className = '' }: CircularLoaderProps) {
    const sizeClasses = {
        sm: 'w-4 h-4 border-2',
        md: 'w-6 h-6 border-2',
        lg: 'w-8 h-8 border-3'
    };

    return (
        <div
            className={`
        ${sizeClasses[size]}
        border-green-600
        border-t-transparent
        rounded-full
        animate-spin
        ${className}
      `}
        />
    );
} 