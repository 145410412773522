import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileSignature, Key, Building2, User, Package, Link as LinkIcon, ChevronDown, ChevronUp, PartyPopper, Download, ArrowRight } from 'lucide-react';
import { useEntity } from '../../../context/EntityContext';

interface InvoiceSignatureProps {
    data: {
        customer: {
            receiver_rut: string;
            receiver_business_name: string;
            receiver_address: string;
            receiver_district: string;
            receiver_city: string;
        };
        date_issued: string;
        details: {
            issueDate: string;
            documentType: string;
        };
        issuer: {
            name: string;
            address: string;
            district: string;
            city: string;
            saleType: string;
            email: string;
            phone: string;
            businessLine: string;
            economicActivity: string;
        };
        items: Array<{
            item_name: string;
            quantity: number;
            unit_price: number;
            item_total: number;
        }>;
        references: Array<{
            reference_type: string;
            reference_folio: string;
            reference_date: string;
            reference_reason: string;
        }>;
    };
    onSubmit: (certificatePassword: string) => Promise<{
        success: boolean;
        pdfUrl?: string;
        pdfContent?: string;
        folio?: string;
        receiverName?: string;
    }>;
    isLoading?: boolean;
}

export function InvoiceSignature({ data, onSubmit, isLoading }: InvoiceSignatureProps) {
    const [certificatePassword, setCertificatePassword] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [pdfContent, setPdfContent] = useState<string | null>(null);
    const [documentFolio, setDocumentFolio] = useState<string | null>(null);
    const [receiverName, setReceiverName] = useState<string | null>(null);
    const { state: entityState } = useEntity();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        try {
            // Siempre usar la entidad del contexto
            if (!entityState.selectedEntity) {
                throw new Error('No hay entidad seleccionada en el contexto');
            }

            const result = await onSubmit(certificatePassword);
            if (result.success) {
                setIsSuccess(true);
                if (result.pdfUrl) {
                    setPdfUrl(result.pdfUrl);
                }
                if (result.pdfContent) {
                    setPdfContent(result.pdfContent);
                }
                if (result.folio) {
                    setDocumentFolio(result.folio);
                }
                if (result.receiverName) {
                    setReceiverName(result.receiverName);
                }
                // Limpiar el estado del formulario
                setCertificatePassword('');
            } else {
                // Si no tuvo éxito, asumimos que hay un error
                setError('Error al procesar el documento');
            }
        } catch (e) {
            setError(e instanceof Error ? e.message : 'Error al procesar la solicitud');
        }
    };

    const handlePdfDownload = () => {
        if (pdfContent) {
            const pdfPrefix = 'data:application/pdf;base64,';
            const pdfData = pdfContent.startsWith(pdfPrefix) ? pdfContent : `${pdfPrefix}${pdfContent}`;

            // Crear un nombre de archivo con el folio y el nombre del receptor
            const fileName = `factura_${documentFolio || 'sin_folio'}_${(receiverName || 'sin_nombre').replace(/\s+/g, '_')}.pdf`;

            // Crear un enlace temporal y simular clic
            const link = document.createElement('a');
            link.href = pdfData;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleViewInvoices = () => {
        // Limpiar localStorage
        localStorage.removeItem('invoiceFormData');
    };

    const calculateTotal = () => {
        if (!data?.items) {
            return { subtotal: 0, iva: 0, total: 0 };
        }
        const subtotal = data.items.reduce((sum, item) => sum + (item.item_total || 0), 0);
        const iva = Math.round(subtotal * 0.19);
        return { subtotal, iva, total: subtotal + iva };
    };

    const totals = calculateTotal();

    if (!data) {
        return (
            <div className="text-center p-8">
                <p className="text-red-600">Error: No hay datos disponibles para firmar</p>
            </div>
        );
    }

    if (isSuccess) {
        return (
            <div className="container mx-auto px-6">
                <div className="max-w-4xl mx-auto bg-gradient-to-br from-green-50 to-white rounded-lg shadow-lg p-12">
                    <div className="space-y-8">
                        {/* Success Icon */}
                        <div className="flex justify-center">
                            <div className="w-24 h-24 bg-green-100 rounded-full flex items-center justify-center animate-bounce">
                                <PartyPopper className="h-12 w-12 text-green-600" />
                            </div>
                        </div>

                        {/* Success Message */}
                        <div className="text-center space-y-3">
                            <h2 className="text-4xl font-bold text-gray-900">
                                ¡Buena Pana!
                            </h2>
                            <p className="text-xl text-gray-600">
                                Emitiste perfectamente tu factura
                            </p>
                        </div>

                        {/* Actions */}
                        <div className="flex flex-col items-center space-y-4">
                            {/* Download Button */}
                            {(pdfUrl || pdfContent) && (
                                <button
                                    onClick={pdfUrl ? undefined : handlePdfDownload}
                                    className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg bg-green-600 px-8 py-4 font-medium text-white transition duration-300 ease-out hover:bg-green-700"
                                    {...(pdfUrl ? { as: 'a', href: pdfUrl, download: true } : {})}
                                >
                                    <span className="absolute right-0 translate-x-full transition-transform duration-300 ease-out group-hover:-translate-x-4">
                                        <Download className="h-6 w-6" />
                                    </span>
                                    <span className="translate-x-0 transition-transform duration-300 ease-out group-hover:-translate-x-4">
                                        Descargar Factura
                                    </span>
                                </button>
                            )}

                            {/* View Invoices Button */}
                            <Link
                                to="/platform/invoices"
                                onClick={handleViewInvoices}
                                className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg bg-white border-2 border-green-600 px-8 py-4 font-medium text-green-600 transition duration-300 ease-out hover:bg-green-50"
                            >
                                <span className="absolute right-0 translate-x-full transition-transform duration-300 ease-out group-hover:-translate-x-4">
                                    <ArrowRight className="h-6 w-6" />
                                </span>
                                <span className="translate-x-0 transition-transform duration-300 ease-out group-hover:-translate-x-4">
                                    Ver Facturas Emitidas
                                </span>
                            </Link>
                        </div>

                        {/* Additional Info */}
                        <p className="text-center text-gray-500 text-sm">
                            Ya puedes empezar a cobrar tus lucas 💰
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto space-y-8">
                {/* Header Section */}
                <div className="text-center space-y-4">
                    <div className="inline-flex items-center justify-center w-16 h-16 bg-green-100 rounded-full">
                        <FileSignature className="h-8 w-8 text-green-600" />
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Firma y Emisión de Documento</h1>
                        <p className="text-gray-600 mt-2">
                            Este es el último paso. Revisa los datos del documento y firma con tu certificado digital para emitir al SII.
                        </p>
                    </div>
                </div>

                {/* Certificate Password Form */}
                <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-sm border">
                    <div className="space-y-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Contraseña del Certificado Digital
                        </label>
                        <div className="relative">
                            <Key className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="password"
                                value={certificatePassword}
                                onChange={(e) => setCertificatePassword(e.target.value)}
                                className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                placeholder="Ingresa la contraseña de tu certificado"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full flex items-center justify-center space-x-2 px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors disabled:bg-green-400"
                        >
                            <FileSignature className="h-5 w-5" />
                            <span>{isLoading ? 'Firmando y emitiendo...' : 'Firmar y Emitir'}</span>
                        </button>
                    </div>
                </form>

                {/* Toggle Details Button */}
                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="w-full flex items-center justify-center space-x-2 px-6 py-3 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                >
                    {showDetails ? (
                        <>
                            <ChevronUp className="h-5 w-5" />
                            <span>Ocultar detalles del documento</span>
                        </>
                    ) : (
                        <>
                            <ChevronDown className="h-5 w-5" />
                            <span>Ver detalles del documento</span>
                        </>
                    )}
                </button>

                {/* Collapsible Document Details */}
                {showDetails && (
                    <div className="bg-white rounded-lg shadow-sm border divide-y">
                        {/* Issuer Section */}
                        <div className="p-6">
                            <h3 className="flex items-center text-lg font-medium mb-4">
                                <Building2 className="h-5 w-5 text-green-600 mr-2" />
                                Datos del Emisor
                            </h3>
                            <div className="grid grid-cols-2 gap-4 text-sm">
                                <div>
                                    <p className="font-medium">Razón Social</p>
                                    <p className="text-gray-600">{data.issuer.name}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Dirección</p>
                                    <p className="text-gray-600">{data.issuer.address}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Comuna</p>
                                    <p className="text-gray-600">{data.issuer.district}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Ciudad</p>
                                    <p className="text-gray-600">{data.issuer.city}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Giro</p>
                                    <p className="text-gray-600">{data.issuer.businessLine}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Email</p>
                                    <p className="text-gray-600">{data.issuer.email}</p>
                                </div>
                            </div>
                        </div>

                        {/* Customer Section */}
                        <div className="p-6">
                            <h3 className="flex items-center text-lg font-medium mb-4">
                                <User className="h-5 w-5 text-green-600 mr-2" />
                                Datos del Receptor
                            </h3>
                            <div className="grid grid-cols-2 gap-4 text-sm">
                                <div>
                                    <p className="font-medium">RUT</p>
                                    <p className="text-gray-600">{data.customer.receiver_rut}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Razón Social</p>
                                    <p className="text-gray-600">{data.customer.receiver_business_name}</p>
                                </div>
                                <div className="col-span-2">
                                    <p className="font-medium">Dirección</p>
                                    <p className="text-gray-600">{data.customer.receiver_address}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Comuna</p>
                                    <p className="text-gray-600">{data.customer.receiver_district}</p>
                                </div>
                                <div>
                                    <p className="font-medium">Ciudad</p>
                                    <p className="text-gray-600">{data.customer.receiver_city}</p>
                                </div>
                            </div>
                        </div>

                        {/* References Section */}
                        {data.references && data.references.length > 0 && (
                            <div className="p-6">
                                <h3 className="flex items-center text-lg font-medium mb-4">
                                    <LinkIcon className="h-5 w-5 text-green-600 mr-2" />
                                    Referencias
                                </h3>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr className="text-left text-xs font-medium text-gray-500 uppercase">
                                                <th className="px-4 py-2">Tipo Documento</th>
                                                <th className="px-4 py-2">Folio</th>
                                                <th className="px-4 py-2">Fecha</th>
                                                <th className="px-4 py-2">Razón</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {data.references.map((ref, index) => (
                                                <tr key={index}>
                                                    <td className="px-4 py-2">{ref.reference_type}</td>
                                                    <td className="px-4 py-2">{ref.reference_folio}</td>
                                                    <td className="px-4 py-2">{ref.reference_date}</td>
                                                    <td className="px-4 py-2">{ref.reference_reason}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {/* Items Section */}
                        <div className="p-6">
                            <h3 className="flex items-center text-lg font-medium mb-4">
                                <Package className="h-5 w-5 text-green-600 mr-2" />
                                Detalle de Productos
                            </h3>
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr className="text-left text-xs font-medium text-gray-500 uppercase">
                                            <th className="px-4 py-2">Descripción</th>
                                            <th className="px-4 py-2 text-right">Cantidad</th>
                                            <th className="px-4 py-2 text-right">Precio Unit.</th>
                                            <th className="px-4 py-2 text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {data.items.map((item, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-2">{item.item_name}</td>
                                                <td className="px-4 py-2 text-right">{item.quantity}</td>
                                                <td className="px-4 py-2 text-right">${item.unit_price.toLocaleString('es-CL')}</td>
                                                <td className="px-4 py-2 text-right">${item.item_total.toLocaleString('es-CL')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className="bg-gray-50">
                                        <tr>
                                            <td colSpan={3} className="px-4 py-2 text-right font-medium">Neto</td>
                                            <td className="px-4 py-2 text-right">${totals.subtotal.toLocaleString('es-CL')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="px-4 py-2 text-right font-medium">IVA (19%)</td>
                                            <td className="px-4 py-2 text-right">${totals.iva.toLocaleString('es-CL')}</td>
                                        </tr>
                                        <tr className="font-bold">
                                            <td colSpan={3} className="px-4 py-2 text-right">Total</td>
                                            <td className="px-4 py-2 text-right">${totals.total.toLocaleString('es-CL')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
