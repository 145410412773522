import React from 'react';
import { motion } from 'framer-motion';

interface TypingAnimationProps {
    text: string;
}

export function TypingAnimation({ text }: TypingAnimationProps) {
    const [displayedText, setDisplayedText] = React.useState('');
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isComplete, setIsComplete] = React.useState(false);

    // Reset state when text changes
    React.useEffect(() => {
        setDisplayedText('');
        setCurrentIndex(0);
        setIsComplete(false);
    }, [text]);

    React.useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                const nextChar = text[currentIndex];
                setDisplayedText(prev => prev + nextChar);
                setCurrentIndex(prev => prev + 1);
            }, /[.,!?]/.test(text[currentIndex]) ? 100 : Math.random() * 15 + 5);

            return () => clearTimeout(timeout);
        } else if (!isComplete) {
            setIsComplete(true);
        }
    }, [currentIndex, text, isComplete]);

    return (
        <div className="whitespace-pre-wrap">
            <span dangerouslySetInnerHTML={{ __html: displayedText }} />
            {!isComplete && (
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 0] }}
                    transition={{ duration: 0.4, repeat: Infinity }}
                    className="ml-0.5 inline-block w-[1px] h-4 bg-current"
                />
            )}
        </div>
    );
}