import React from 'react';

export function ComplexExample() {
    return (
        <div>
            <h3 className="text-lg font-medium mb-3">Ejemplo Complejo</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Campo</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Valor</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">RUT Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">98.765.432-1</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Contacto Receptor</td>
                            <td className="px-4 py-2 text-sm text-gray-500">María González</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Fecha Emisión</td>
                            <td className="px-4 py-2 text-sm text-gray-500">2025-02-15</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Tipo Documento</td>
                            <td className="px-4 py-2 text-sm text-gray-500">33 (Factura Electrónica)</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Detalle Producto</td>
                            <td className="px-4 py-2 text-sm text-gray-500">
                                Nombre: Notebook Gamer Pro<br />
                                Código: NB-001<br />
                                Precio Unitario: $899.990<br />
                                Cantidad: 1<br />
                                Descuento: $50.000<br />
                                Total: $849.990
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900">Referencias</td>
                            <td className="px-4 py-2 text-sm text-gray-500">
                                <div className="space-y-2">
                                    <div>
                                        <strong>Referencia 1:</strong><br />
                                        Tipo: 801<br />
                                        Folio: 2002<br />
                                        Fecha: 2025-02-10<br />
                                        Razón: Orden de Compra
                                    </div>
                                    <div>
                                        <strong>Referencia 2:</strong><br />
                                        Tipo: 33<br />
                                        Folio: 2003<br />
                                        Fecha: 2025-02-11<br />
                                        Razón: Error en facturación
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}