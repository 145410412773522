import React, { useState, useEffect } from 'react';
import { Save, RefreshCw, Search, Plus, Trash2, ChevronDown } from 'lucide-react';
import { SpinningRing } from '../../../../../components/SpinningRing';
import { EditInvoiceModal } from './EditInvoiceModal';

interface Address {
    id: number;
    address: string;
    district: {
        id: number;
        name: string;
        city: {
            id: number;
            name: string;
        }
    };
    sii_branch_code: string;
    phone: string | null;
}

interface Activity {
    id: number;
    code: string;
    name: string;
}

interface QuickEditorProps {
    invoice: any;
    onSave: (id: number, data: any) => Promise<void>;
    onRefreshPdf: () => Promise<void>;
    isRefreshing: boolean;
}

export function QuickEditor({ invoice, onSave, onRefreshPdf, isRefreshing }: QuickEditorProps) {
    const [formData, setFormData] = useState({
        document_receiver: invoice.document_receiver,
        details: invoice.details,
        references: invoice.references || []
    });
    const [showEditModal, setShowEditModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [addressSearch, setAddressSearch] = useState('');
    const [activitySearch, setActivitySearch] = useState('');
    const [showAddressDropdown, setShowAddressDropdown] = useState(false);
    const [showActivityDropdown, setShowActivityDropdown] = useState(false);
    const [selectedTab, setSelectedTab] = useState('customer');
    const [savingStatus, setSavingStatus] = useState<'idle' | 'saving' | 'refreshing'>('idle');

    // Sort addresses and activities alphabetically
    const sortedAddresses = [...(invoice.receiver?.addresses || [])].sort((a: Address, b: Address) =>
        a.address.localeCompare(b.address)
    );

    const sortedActivities = [...(invoice.receiver?.activities || [])].sort((a: Activity, b: Activity) =>
        a.name.localeCompare(b.name)
    );

    // Filter addresses and activities based on search
    const filteredAddresses = sortedAddresses.filter(addr =>
        addr.address.toLowerCase().includes(addressSearch.toLowerCase()) ||
        addr.district.name.toLowerCase().includes(addressSearch.toLowerCase()) ||
        addr.district.city.name.toLowerCase().includes(addressSearch.toLowerCase())
    );

    const filteredActivities = sortedActivities.filter(act =>
        act.name.toLowerCase().includes(activitySearch.toLowerCase()) ||
        act.code.toLowerCase().includes(activitySearch.toLowerCase())
    );

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        setSavingStatus('saving');
        try {
            // Include all required fields in the request
            const requestData = {
                folio: invoice.folio,
                receiver_rut: formData.document_receiver.rut,
                document_receiver: {
                    rut: formData.document_receiver.rut,
                    business_name: formData.document_receiver.business_name,
                    business_activity: formData.document_receiver.business_activity,
                    contact: formData.document_receiver.contact,
                    address: formData.document_receiver.address,
                    district: formData.document_receiver.district,
                    city: formData.document_receiver.city
                },
                details: formData.details.map(detail => ({
                    line_number: detail.line_number,
                    item_name: detail.item_name,
                    item_description: detail.item_description || '',
                    quantity: detail.quantity,
                    unit_price: detail.unit_price,
                    discount_percent: detail.discount_percent,
                    item_total: detail.item_total,
                    item_code: detail.item_code || '',
                    unit: detail.unit || 'UN',
                    other_tax: detail.other_tax
                })),
                references: formData.references
            };

            await onSave(invoice.id, requestData);
            setSavingStatus('refreshing');
            await onRefreshPdf();
            setShowEditModal(false);
        } catch (error) {
            console.error('Error saving changes:', error);
        } finally {
            setIsSaving(false);
            setSavingStatus('idle');
        }
    };

    const handleAddressSelect = (address: Address) => {
        setFormData(prev => ({
            ...prev,
            document_receiver: {
                ...prev.document_receiver,
                address: address.address,
                district: address.district.name,
                city: address.district.city.name
            }
        }));
        setShowAddressDropdown(false);
    };

    const handleActivitySelect = (activity: Activity) => {
        setFormData(prev => ({
            ...prev,
            document_receiver: {
                ...prev.document_receiver,
                business_activity: activity.name
            }
        }));
        setShowActivityDropdown(false);
    };

    const handleAddProduct = () => {
        setFormData(prev => ({
            ...prev,
            details: [
                ...prev.details,
                {
                    line_number: prev.details.length + 1,
                    item_name: '',
                    item_description: '',
                    quantity: '1.00',
                    unit_price: '0.00',
                    discount_percent: '0.00',
                    item_total: '0.00',
                    item_code: '',
                    unit: 'UN',
                    other_tax: null
                }
            ]
        }));
    };

    const handleRemoveProduct = (index: number) => {
        setFormData(prev => ({
            ...prev,
            details: prev.details.filter((_, i) => i !== index)
        }));
    };

    return (
        <>
            <div className="flex justify-end space-x-2 mb-4">
                {savingStatus === 'saving' && (
                    <div className="flex items-center text-gray-600">
                        <SpinningRing size="sm" className="mr-2" />
                        <span>Guardando cambios...</span>
                    </div>
                )}
                {savingStatus === 'refreshing' && (
                    <div className="flex items-center text-gray-600">
                        <SpinningRing size="sm" className="mr-2" />
                        <span>Actualizando PDF...</span>
                    </div>
                )}
                <button
                    onClick={() => setShowEditModal(true)}
                    className="flex items-center space-x-2 px-4 py-2 text-green-600 hover:bg-green-50 rounded-md transition-colors"
                >
                    <span>Editar Datos</span>
                </button>
            </div>

            <EditInvoiceModal
                isOpen={showEditModal}
                onClose={() => setShowEditModal(false)}
                invoice={invoice}
                onSave={async (id, data) => {
                    setSavingStatus('saving');
                    try {
                        await onSave(id, data);
                        setSavingStatus('refreshing');
                        await onRefreshPdf();
                    } catch (error) {
                        console.error('Error en el proceso de guardado:', error);
                    } finally {
                        setSavingStatus('idle');
                    }
                }}
            />
        </>
    );
}